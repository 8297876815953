import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdMore } from "react-icons/io";
import { Layout, Header, Modal, NewPurchaseOrder,EditPurchaseOrder } from '../../../../components/';
import { handleApi, formatCurrency } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';

const Deliveries = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPurchaseOrders();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when searchTerm changes
  }, [searchTerm]);

  const fetchPurchaseOrders = async () => {
    const apiEndpoint = 'purchase/orders';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData, apiMethod, parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;

      if (status === 200) {
        console.log('order from api',response)
        setPurchaseOrders(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearchTerm = useMemo(() => debounce(handleSearchChange, 300), []);

  const filteredPOs= useMemo(() => 
    purchaseOrders.filter(po => 
      po?.partners?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ), [purchaseOrder, searchTerm]
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPOs.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredPOs.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);
  const handleMoreBtnClick = (purchaseOrder) => {
    setPurchaseOrder(purchaseOrder);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };
 console.log('purchase orders at theis point',currentItems)
  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Purchase Deliveries" />
        </div>
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {/* {isModalOpen && <NewPurchaseOrder pi={invoice} onClose={handleCloseModal} />} */}
          {isModalOpen && <EditPurchaseOrder purchaseOrder={purchaseOrder} onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">
              <div className="max-w-lg flex justify-end items-center mb-2 mx-auto">
                <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => paginate(index + 1)}
                      className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                      style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={debouncedSearchTerm}
                    className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                  />
                </div>
              </div>

              <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4 p-3 border-b">
                  <div className="">
                    <span className="text-sm text-slate-400 whitespace-nowrap">PO  #</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Supplier    #</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Purchase   Value</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Delivered</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap"></span>
                  </div>
                </div>

                {currentItems.length > 0 ? (
                  currentItems.map((po, index) => (
                    <div 
                      key={po.id} // Assuming each invoice has a unique ID
                      onClick={() => handleMoreBtnClick(po)} 
                      className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200"
                    >
                      <div className="text-center">
                        <div className="text-sm text-gray-900">{indexOfFirstItem + index + 1}. {po?.reference || 'No reference no'}</div>
                        <div className="text-xs text-gray-700">Created: {po?.date ? new Date(po.date).toLocaleDateString('en-US', 
                                { month: 'short', day: 'numeric', year: 'numeric' }) : ""}</div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-900">{po?.partners?.name || 'N/A'}</div>
                        
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{formatCurrency(po?.totalTaxedPrice) || 'N/A'}</span>
                      </div>
                      
                      <div className="text-center">
                        <span className={`text-sm text-white py-1 px-3 rounded-full  ${po?.delivered === 0 ? 'bg-red-500' : 'bg-green-500'}`}>
                          {po?.delivered === 0 ? 'No' : 'Yes'}
                        </span>
                      </div>
                      <div className="text-right">
                        <button
                          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                          onClick={() => handleMoreBtnClick(po)}
                          title="More detail"
                        >
                          <IoMdMore size={20} color={currentColor} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="p-3 text-center">No purchase Orders available.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Deliveries;
