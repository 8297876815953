import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { IoMdTrash } from "react-icons/io";
import { MdOutlineCancel } from 'react-icons/md';
// import { IoInformationCircle } from "react-icons/io5";
import { BsBank } from "react-icons/bs";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { FaRegBuilding } from "react-icons/fa";
import { Layout, Header, Button, Modal, NewProfile, EditProfile, DeleteProfile, NewBank } from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import { handleCompanyProfileApi,updateCompanyProfile} from '../utils/api';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoPlaceholder from '../../../../images/logos/logo-placeholder.png';
import '../styles/style.css';

const Settings = () => {
  const [settings, setSettings] = useState({
    bankDetail: {} ,
    companyProfile: {},// Initialize with your bank details state
    taxDetail: {}// Initialize with your bank details state
  });
  const [originalProfile, setOriginalProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [displayImg, setDisplayImg] = useState(''); 
  const [imgType, setImgType] = useState('');
  const { userData, companyProfile,currentColor, actionCount, setActionCount } = useContext(AppContext);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate(); 

  useEffect(() => {

    //fetchProfiles();
  }, [actionCount]);

  useEffect(() => {
    setIsLoading(true);
    //setCompanyProfile(companyProfile);
    //setOriginalProfile(companyProfile); // Store the initial companyProfile
    setIsLoading(false);
  }, [companyProfile]);

  
  const getChangedFields = (original, updated) => {
    const changedFields = {};
    for (const key in updated) {
      if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {
        changedFields[key] = updated[key];
      }
    }
    if (original.id) {changedFields.id = original.id; }   // Include id if it exists in original
    return changedFields;
  };

  
  
  const handleAction = (action, companyProfile) => {
    setActionType(action);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); 
  };

  const convertFile = (files) => {
    console.log('conviert images')
  if (files && files.length > 0 && files[0] instanceof File) {
    const fileRef = files[0];
    const fileType = fileRef.type;
    const reader = new FileReader();
    reader.readAsBinaryString(fileRef);
    reader.onload = (ev) => {
      //const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
      //const photo = `data:${fileType};base64,${btoa(ev.target.result)}`;
      //const  photo= {data:btoa(ev.target.result),contentType:fileType};
      //setDisplayImg(btoa(ev.target.result))
     // setImgType(fileType)
      //setCompanyProfile(prevProfile => ({...prevProfile,photo:photo}));
    };
  }
};

  const handleImgChange = (event) => {
    const { name, files } = event.target;
    if (name === "logo") {
 
      convertFile(files);}
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      bankDetails: {
        ...prevSettings.bankDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmitBankDetails = async (e) => {
    e.preventDefault();
    // Perform submission logic for bank details
    console.log('Submitting bank details', settings.bankDetail);
    // Add actual API call and logic as needed
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!companyProfile.name) {toast.error('Please enter company name ');return;  }
    if (!companyProfile.phone_number) {toast.error('Please enter phone number  ');return;  }

    const changedFields = getChangedFields(originalProfile, companyProfile);
    // Conditional API call based on presence of companyProfile.id
    
    console.log('changedFields',changedFields);
    const apiMethod = companyProfile.id ? 'PUT' :'POST'
    setIsLoading(true);
    //apiCall
    handleCompanyProfileApi('company_companyProfile', changedFields, userData, apiMethod)
    .then(async (data) => {
      console.log('data from company companyProfile api',data.data.statusText)
      if (data.data.status === 200) {
        toast.success('Company companyProfile updated  Successfully.',data.data.statusText );  
        setActionCount(actionCount+1); // to trigger reload of products list
        //setUserData(userInfo);
      }else if (data.data.status === 409) {
        toast.error('company companyProfile already exist',data.data.statusText); // Display the error message to the use
        return
      } else { 
        toast.error('Error saving company companyProfile.',data.data.statusText,);  
        return
      }
    })
    .catch(error => {
      toast.error(error.message); // Display the error message to the user
      // navigate('/dashboard');
    })
    .finally(() => {
      setActionCount(actionCount+1); // to trigger reload of products list
      setIsLoading(false); });
}


  const photo= (displayImg)
    ? `data:${imgType};base64,${displayImg}`: companyProfile.photoUrl;
    const logo = (photo)? photo: companyProfile.photoUrl;
  
 console.log('companyProfile logo sir ',logo)
  const tabs = [
    { title: "Profile", 
    content: (<div className="w-full">
    <form className="rounded px-8  mb-2">
          <div className="mb-4 pb-4 border-b-1 border-slate-500">
            <div className="shrink-0 px-4 mx-4">
              <img id='logo' 
                className="h-16 w-16 rounded-full" 
                src={logo || LogoPlaceholder} 
                alt="Logo" />
              <img/>
            </div>
            
            {/* <label className="block"> */}
              <span className="sr-only">Choose Logo</span>
              <input type="file" 
                onChange={(event) => handleImgChange(event)}
                accept="image/*"
                name='logo'
                className="block w-full text-sm text-slate-500 
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-violet-50 file:text-violet-700
                  hover:file:bg-violet-100"
              />
            {/* </label> */}
          
        </div>

  

        <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="name"
                onChange={(event) => handleChange(event)}
                value={companyProfile?.name}
            />
            <label htmlFor="company_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Company Name
            </label>
        </div>

        
        <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="phone_number"
                onChange={(event) => handleChange(event)}
                value={companyProfile?.phone_number}
            />
            <label htmlFor="phone" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Telephone
            </label>
        </div>

        <div className="relative z-0 mb-8 group">
            <input
                type="text"
                name="email"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(event) => handleChange(event)}
                value={companyProfile?.email}
            />
                <label
                htmlFor="email"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Email
                </label>
            </div>

            <div className="relative z-0 mb-8 group">
                <input
                type="text"
                name="country"
                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(event) => handleChange(event)}
                value={companyProfile?.country}
                />
                <label
                htmlFor="country"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Country 
                </label>
            </div>

            <div className="relative z-0 mb-8  group">
              <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="city"
                onChange={(event) => handleChange(event)}
                value={companyProfile?.city} 
              />
              <label htmlFor="city" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                  transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                  peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                  peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">City
              </label>
            </div>

            <div className="relative z-0 mb-8 group">
                <input
                  type="text"
                  name="postal_address"
                  className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  onChange={(event) => handleChange(event)}
                  value={companyProfile?.postal_address}
                />
          
                <label
                htmlFor="postal_address"
                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                Post Ofice Box
                </label>
            </div>

            <div className="relative z-0 mb-8  group">
              <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="physical_address"
                onChange={(event) => handleChange(event)}
                value={companyProfile?.physical_address}
              />
              <label htmlFor="physical_address" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Street Address
              </label>
            </div>

            <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="gps_link"
                onChange={(event) => handleChange(event)}
                value={companyProfile?.gps_link}
            />
            <label htmlFor="gps_link" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">GPS Link
            </label>
            </div>

        <div className="text-center">
            <button
                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              onClick={handleSubmit}
            >
                Save
            </button>
        </div>
    </form>
</div>), 
    icon: FaRegBuilding},
    { title: "Taxes", content: "Content for Tax", icon: HiOutlineReceiptTax },
    // { title: "Bank", content: "Content for Ban", icon: BsBank }
    { title: "Bank", content: <NewBank bankDetails={settings.bankDetail} onChange={handleChange} onSubmit={handleSubmitBankDetails} />, icon: BsBank },

    

    
  ];

  return (
    <Layout>
      <div className="m-2 md:m-10 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-2 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose} 
            >
              <MdOutlineCancel size={24} color={currentColor}/>
            </button>
          </div>
          <Header category="" title="Company Settings" color={currentColor}/>
        </div>
        
        {/* <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewProfile onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditProfile rowData={companyProfile} onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'delete' && <DeleteProfile rowData={companyProfile} onClose={handleCloseModal} />}
        </Modal> */}

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
         
            <main
              className="
                relative
                max-w-xl
                mx-auto
                mt-2
                mb-20
                bg-white
                py-2
                px-10
                rounded-md
                // shadow-md
              "
            > 
            <menu className="absolute top-10 -left-40 bg-slate-400 w-40 rounded-l-2xl"
            style={{ background:currentColor  }}>
              <ul>
                {tabs.map((tab, index) => (
                  <li
                    key={index}
                    className={`group text-white ${selected === index ? 'text-current' : ''}`}
                  >
                    <button
                      className={`block w-full p-1 flex items-center group-hover:text-current ${
                        selected === index ? 'text-current' : 'text-white'
                      }`}
                      onClick={() => setSelected(index)}
                      style={{ color: selected === index ? 'currentColor' : 'inherit' }}
                    >
                      <span className="flex items-center">
                      <tab.icon size={20} className="m-2 text-white" />
                        <span className="text-sm text-white">{tab.title}</span>
                      </span>
                    </button>
                    {index < tabs.length - 1 && (
                      <hr className="mx-8" style={{ borderColor: 'currentColor' }} />
                    )}
                  </li>
                ))}
              </ul>
            </menu>

              <article>
                <h1 className="text-l text-slate-400 font-bold text-center">{tabs[selected]?.title}</h1>
                <hr className="my-4" />
                <p className="mb-2 leading-relaxed">{tabs[selected]?.content}</p>
              </article>
            </main>
         
        )}
      </div>
    </Layout>
  );
};

export default Settings;
