import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppContext from '../../../../contexts/AppContext';
import { updateRole, deleteRole} from '../utils/api';

const DeleteRole = ({rowData,onClose }) => {
    const { userData,actionCount,setActionCount } = useContext(AppContext);
    const [role, setRole] = useState({});
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');
    const [base64Data, setBase64Data] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const reasons = [
        { value: 'no longer required ', label: 'No longer Required' },
        { value: 'error_correction', label: 'Error Correction' },
    ];

    useEffect(() => {
        setIsLoading(true)
        const updatedRole = {...rowData, delete_reason: ''};
        setRole(updatedRole);
        setIsLoading(false);
      }, [rowData]);
    
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setRole(prevRole => {
            let updatedRole = { ...prevRole, [name]: value };
            if (name === 'delete_reason') {
                if (value.toLowerCase() !== 'error correction') { updatedRole.active = 0; } 
                updatedRole[name] = value;
            } else { updatedRole[name] = value;}
            return updatedRole;
        });
    };
    
    

    const handleSubmit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      
        if (!role.delete_reason) {
          toast.error('Please enter reason for deleting ');
          return;
        }
      
        if (role.delete_reason.toLowerCase() === 'error correction') {
          // Perform delete operation
          deleteRole('roles', userData, role, )
            .then(async (data) => {
              console.log('data from role api',data.data.statusText)
              if (data.data.status === 200) {
                toast.success('Role  deleted Successfully.',data.data.statusText );  
                setActionCount(actionCount+1); // to trigger reload of products list
                //setUserData(userInfo);
              }else if (data.data.status === 409) {
                toast.error('Role already exist',data.data.statusText); // Display the error message to the use
                return
              } else { 
                toast.error('Error deleting role.',data.data.statusText,);  
                return
              }
            })
            .catch(error => {
              toast.error(error.message); // Display the error message to the user
              // navigate('/dashboard');
            })
            .finally(() => {
              setActionCount(actionCount + 1); // to trigger reload of products list
              setIsLoading(false);
            });
      
        } else {
          // Perform update operation
          role.active = 0;
          updateRole('roles', userData, role)
            .then(async (data) => {
              console.log('data from role api',data.data.statusText)
          if (data.data.status === 200) {
            toast.success('Role  deleted Successfully.',data.data.statusText );  
            setActionCount(actionCount+1); // to trigger reload of products list
            //setUserData(userInfo);
          }else if (data.data.status === 409) {
            toast.error('Role already exist',data.data.statusText); // Display the error message to the use
            return
          } else { 
            toast.error('Error deleting  role.',data.data.statusText,);  
            return
          }
            })
            .catch(error => {
              toast.error(error.message); // Display the error message to the user
              // navigate('/dashboard');
            })
            .finally(() => {
              setActionCount(actionCount + 1); // to trigger reload of products list
              setIsLoading(false);
            });
        }
      };
      
    
        console.log('parent role',role)
       
       
       
    return (
        <div className="">
          <div className="flex justify-between">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Delete Role</h2>
                
               
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                {/* purchaserole preamble info */}
                <div className="m-2 md:m-1  p-2 md:p-2 bg-gray-50 rounded-3xl">
                    <div className="flex flex-wrap "> 
                        
                        
                        <div className="w-full sm:w-auto sm:flex-1  px-4 py-2">
                            <h3 className="whitespace-nowrap uppercase font-bold mb-4" >
                            {role?.name} 
                            </h3>
                            <p className="whitespace-nowrap text-slate-400 text-xs">Description </p>
                            <p className="whitespace-nowrap font-semibold text-xs mb-2" dangerouslySetInnerHTML={{ __html: role.description }}></p>
                        </div>
                    </div>
                </div>
                {/* end role preamble info */}
                
                 {/* separation form */}
                <div className="m-2 md:m-1  p-2 md:p-2 bg-white rounded-3xl">
                <div className="shadow-md rounded px-8 pt-4 mb-2"> 
                        <div className="relative z-0 mb-8  group">
                        <input
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                name="delete_reason"
                                onChange={(event) => handleTextChange(event)}
                                value={role?.delete_reason}
                                list="reasonsList" // Link the input to the correct datalist
                            />

                            <datalist id="reasonsList">
                                {reasons.map((reason, index) => (
                                    <option key={index} value={reason.label} />
                                ))}
                            </datalist>
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Reason for deleting
                            </label>
                        </div>
                        

                    <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                {/* separation form*/}
      
                <div className="w-full">
                    
                </div>
            </div>
        </div>
    );
};

export default DeleteRole;
