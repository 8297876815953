
    // UserService.js
    import axios from 'axios';
    import API_BASE_URL from '../../../../config';

    // get all 
export const getEmployees = async (page,userData) => {

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { }, // query parameters ,  with_photos:1
  };
  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
    console.table('API Response:', data); // Log the data

      return {
        data: response.data 
      };
  } catch (error) {
      console.error('Error fetching data:', error);
      throw new Error('Failed to fetch data. Please try again later.');
  }
};


    export const authenticateUser = async (page, { username, password,pos }) => {
      try {
        const response = await fetch(`${API_BASE_URL}/${page}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          //   'Authorization': `Bearer ${userData.data.token}`
          },
          body: JSON.stringify({
              username: username,
              password :password,
          })
        });
          if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
          const data = await response.json();
       
          return {
            data
            // count: data.length,
            // result: data,
            // error: null, // Include this line to ensure consistency
          };
  
      } catch (err) {
          console.error('Error loging you in :', err);
          throw new Error('Failed to authenticate'); // Rethrow a custom error message to be handled by the calling code
      
        }
  };

  export const addUser = async (page, user, userData) => {
    console.log('User in api ',user)  
    try {
      
   
      const response = await fetch(`${API_BASE_URL}/${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.data.token}`
        },
        body: JSON.stringify({
          username:user.login_name,
          password:user.password,
          role_id:user.role,
          employee_id:user.employee_id,
          //session_id:1
        })
      });
        if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
        const data = await response.json();
     
        return {
          data
          // count: data.length,
          // result: data,
          // error: null, // Include this line to ensure consistency
        };

    } catch (err) {
        console.error('Error adding order:', err);
        throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
    }
};
export const updateUser = async (page, user, userData) => {

    console.log('updateing sir ',user)
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id: user.id }, // Send productId as a query parameter
    };
  
    try {
      const response = await axios.put(`${API_BASE_URL}/${page}`, {
        username:user.username,
        role_id:user.role_id,
        employee_id:user.employee_id,
        session_id:1
      }, config);
      console.log('from api response sir ',response)
      return {
        data: response
      };
    } catch (err) {
      console.error('Error user product:', err);
      throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
    }
  };
  


export const deleteUser = async (page, user, userData) => {
    console.log('deleteing sir ')
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
  };

  try {
    const response = await axios.delete(`${API_BASE_URL}/${page}`, {
        id:user.id,
        session_id:1
      }, config);
    // Check if the response indicates success (you may adjust this based on your API's response structure)
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.data; // Optionally return data if needed
  } catch (err) {
    console.error('Error deleting customer:', err);
    throw new Error('Failed to delete customer');
  }
};
