import React, { useEffect,useState, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../contexts/AppContext';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { GoDotFill } from 'react-icons/go';
import { IoMdMore } from "react-icons/io";
import { MdOutlineFactCheck } from "react-icons/md";
import { GiExpense } from "react-icons/gi";
import { SlPresent } from "react-icons/sl";
import { FcSalesPerformance } from "react-icons/fc";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Stacked, Pie, Button, LineChart, SparkLine, Layout } from '../components';
import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';
// import product9 from '../data/product9.jpg';
import {handleApi,formatCurrency,getFirstAndLastDateOfMonth,getFirstAndLastDayOfWeek,
   formatDateToMonth,normalizeDate} from '../utils/utils'; 
import {Api} from '../'; 


const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent id="time" fields={{ text: 'Time', value: 'Id' }} style={{ border: 'none', color: (currentMode === 'Dark') && 'white' }} value="1" dataSource={dropdownData} popupHeight="220px" popupWidth="120px" />
  </div>
 );

const Dashboard = () => {
  const {userData, currentColor, currentMode} = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [billSummaries, setBillSummaries] = useState([]);
  const [totalBillAmount, setTotalBillAmount] = useState(0);
  const [totalReceivedAmount, setTotalReceivedAmount] = useState(0);
  const [todayBillAmount, setTodayBillAmount] = useState(0);
  const [todayReceivedAmount, setTodayReceivedAmount] = useState(0);
  const [orderedPercentage, setOrderedPercentage] = useState(0);
  const [receivedPercentage, setReceivedPercentage] = useState(0);

  const [chartData, setChartData] = useState([]);
  const [isMonthlyView, setIsMonthlyView] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchBillReport();
 

  }, [userData]);
  
  const fetchBillReport = async () => {
    const apiEndpoint = 'pos/reports';
    const apiMethod = 'GET';
    setIsLoading(true);
    // Get the current year
    const currentYear = new Date().getFullYear();
    
    // Set start and end dates for the entire current year
    const startDate = `${currentYear}-01-01`; // January 1 of the current year
    const endDate = `${currentYear}-12-31`;   // December 31 of the current year
    const parameters= {startDate:startDate,endDate:endDate,type:'bills'};
    const cleanedData= {};
    //  const { startDate, endDate } = getFirstAndLastDateOfMonth();
    try {
      const response = await handleApi(apiEndpoint,cleanedData , userData, apiMethod,parameters);
      if (response.error) {
        toast.error('Error fection report ', response.errorMessage);// Handle specific API error
        return; // Exit function to avoid further processing
      }
      
      const { status, statusText } = response;
      if (status === 200) {
        const fetchedBills = response.data; // Assuming the data is an array of bills
        const fetchedBillSummaries = fetchedBills.billSummaries; // Assuming the data is an array of bills
       
    
       
        setBills(fetchedBills);
        setBillSummaries(fetchedBillSummaries)
        // Automatically set chart data after fetching
        const defaultPeriod = 'month'; // Default to monthly view on load
        prepareChartData(fetchedBillSummaries, defaultPeriod); 
        //setChartData(stackedChartData)
      } else { toast.error(`Error ${status}: ${statusText}`); } // Handle non-200 responses
     
    } catch (error){ toast.error(error.message || 'An unexpected error occurred.');// Handle unexpected errors
    } finally {setIsLoading(false);}
  };

  
  
  const filterBillSummariesByYear = (billsSummaries) => {
    const groupedData = {};
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const currentDate = new Date().getDate(); // Get the current day of the month (1-31)
    const currentMonth = new Date().getMonth(); // Get the current month index (0-11)
  
    billsSummaries.forEach((bill) => {
  
      const date = new Date(bill.date);
  
      if (isNaN(date.getTime())) {
        console.error('Invalid date:', bill.date); 
        return; 
      }
  
      const month = months[date.getMonth()]; 
  
      const taxedPrice = parseFloat(bill.totalBillTaxedPrice);
      const paidAmount = parseFloat(bill.totalPaidAmount);
  
      if (isNaN(taxedPrice) || isNaN(paidAmount)) {
   
        return; 
      }
      if (date.getDate() === currentDate) {
        // Update daily totals (if not already defined)
        if (!groupedData.daily) {
          groupedData.daily = {
            totalBillTaxedPrice: 0,
            totalPaidAmount: 0,
          };
        }
        groupedData.daily.totalBillTaxedPrice += taxedPrice;
        groupedData.daily.totalPaidAmount += paidAmount;
      }
  
      if (!groupedData[month]) {
        groupedData[month] = {
          totalBillTaxedPrice: 0,
          totalPaidAmount: 0,
        };
      }
  
      groupedData[month].totalBillTaxedPrice += taxedPrice;
      groupedData[month].totalPaidAmount += paidAmount;
    });
  
    const taxedPriceData = [];
    const paidAmountData = [];
  
    months.forEach((month) => {
      taxedPriceData.push({
        x: month,
        y: groupedData[month]?.totalBillTaxedPrice || 0,
      });
      paidAmountData.push({
        x: month,
        y: groupedData[month]?.totalPaidAmount || 0,
      });
    });
    const ChartData = [taxedPriceData, paidAmountData];
    setChartData(ChartData)
    setTotalBillAmount(groupedData[months[currentMonth]]?.totalBillTaxedPrice || 0,)
    setTotalReceivedAmount(groupedData.daily?.totalPaidAmount || 0);
    setTodayBillAmount(groupedData.daily?.totalBillTaxedPrice || 0);
    return ChartData;

    
  };
  
  
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const filterBillSummariesByWeek = (billSummaries) => {
    const { firstDayOfWeek, lastDayOfWeek } = getFirstAndLastDayOfWeek('sunday'); // Get first and last day of the week
  
    // Filter billSummaries to include only those whose date falls within the current week
    const filteredSummaries = billSummaries.filter(summary => {
      const billDate = normalizeDate(summary.date);  // Normalize bill date to midnight
      return billDate >= firstDayOfWeek && billDate <= lastDayOfWeek; // Check if bill date is between the dates of the week (inclusive)
    });

    // Initialize variables for weekly totals
    let totalWeekTaxedPrice = 0;
    let totalWeekPaidAmount = 0;
    // Initialize variables for current day  totals
    let totalCurrentDayTaxedPrice = 0;
    let totalCurrentDayPaidAmount = 0;
  
    // Initialize an object to group data by each weekday
    const groupedData = {
      Sun: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Mon: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Tue: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Wed: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Thu: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Fri: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
      Sat: { totalBillTaxedPrice: 0, totalPaidAmount: 0 },
    };
  
    // Group and sum data by weekday
    filteredSummaries.forEach(summary => {
      const billDate = new Date(summary.date);
      const weekday = weekdays[billDate.getDay()]; // Get weekday name (e.g., 'Sun', 'Mon')
  
    // Convert the values to numbers before summing
    const totalBillTaxedPrice = Number(summary.totalBillTaxedPrice.replace(/,/g, '')) || 0;
    const totalPaidAmount = Number(summary.totalPaidAmount.replace(/,/g, '')) || 0;

    // Sum the values for each day and update weekly totals
    groupedData[weekday].totalBillTaxedPrice += totalBillTaxedPrice;
    groupedData[weekday].totalPaidAmount += totalPaidAmount;

    // Check if the bill date matches the current day
    const currentDate = new Date();
    if (billDate.toDateString() === currentDate.toDateString()) {
      totalCurrentDayTaxedPrice += totalBillTaxedPrice;
      totalCurrentDayPaidAmount += totalPaidAmount;
    }


    // Update weekly totals
    totalWeekTaxedPrice += totalBillTaxedPrice;
    totalWeekPaidAmount += totalPaidAmount;
    });

    // Calculate percentages for today's values against weekly totals
    const totalCurrentDayTaxedPricePercent = Math.round((totalCurrentDayTaxedPrice / totalWeekTaxedPrice) * 100 )|| 0;
    const totalCurrentDayPaidAmountPercent = ((totalCurrentDayPaidAmount / totalWeekPaidAmount) * 100).toFixed(2) || 0;

  
    // Create taxedPriceData and paidAmountData arrays
    const taxedPriceData = [];
    const paidAmountData = [];
  
    weekdays.forEach(weekday => {
      taxedPriceData.push({
        x: weekday,
        y: groupedData[weekday].totalBillTaxedPrice, // Use the totalBillTaxedPrice for that day
      });
      paidAmountData.push({
        x: weekday,
        y: groupedData[weekday].totalPaidAmount, // Use the totalPaidAmount for that day
      });
    });
    const ChartData = [taxedPriceData, paidAmountData];

    setChartData(ChartData);
 
    setTotalBillAmount(totalWeekTaxedPrice);
    setTotalReceivedAmount(totalWeekPaidAmount);
    setTodayBillAmount(totalCurrentDayTaxedPrice)
    setTodayReceivedAmount(totalCurrentDayPaidAmount,)
    setOrderedPercentage((totalCurrentDayTaxedPricePercent).toFixed(1))
    setReceivedPercentage(((totalCurrentDayPaidAmount / totalCurrentDayTaxedPrice) * 100).toFixed(1) || 0);

 
 
   
    return ChartData;
  };
  
  
  const prepareChartData = (fetchedBillSummaries,period) => {
    const stackedChartData = period==='month' 
      ? filterBillSummariesByYear(fetchedBillSummaries) 
      : filterBillSummariesByWeek(fetchedBillSummaries);
     
  };

  const toggleView = (period) => {
    if(period==='month'){setIsMonthlyView(true);}else{setIsMonthlyView(false)}
    prepareChartData(billSummaries,period); // Update chart data here
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };


    return (
    <Layout>
      <div className="mt-12">
        <div>
          {/* Your dashboard UI components */}
          {isLoading && <p>Loading...</p>}
          <ToastContainer />
        </div>
       
        
        {/* start cards */}
        <div className="flex flex-wrap lg:flex-nowrap justify-center mx-8 flex-col md:flex-row">
          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 pb-1 rounded-2xl relative mx-4 w-full">
            <div className="flex justify-end mb-4">
              <button type="button" className="text-xl font-semibold text-gray-500" onClick={toggleDropdown}>
                <IoMdMore />
              </button>
            </div>
            {showDropdown && (
              <div className="absolute right-0  w-38 bg-white border border-gray-300 shadow-lg rounded-lg z-10">
                <ul className="">
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView('month');
                      setShowDropdown(false);
                    }}
                  >
                    Monthly
                  </li>
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView('week');
                      setShowDropdown(false);
                    }}
                  >
                    Weekly
                  </li>
                </ul>
              </div>
            )}
            <div className="flex items-center flex-shrink">
              <div className="mr-2">
              <p className="font-bold text-gray-400">Sales</p>
               
                <p className="font-bold text-1xl">{formatCurrency(totalBillAmount)}</p>
                <p className="text-gray-400 mt-2">{isMonthlyView ? "This month" : "This week"}</p>
              </div>
      
              <button
                type="button"
                style={{ backgroundColor: 'rgb(144, 238, 144)' }}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl bg-green-900 rounded-full p-3"
              >
                <FcSalesPerformance  color='white'/>
           
              </button>
            </div>
            
            <div className="mt-1">
              <Button color="white" bgColor={currentColor} text="Download" borderRadius="10px" />
            </div>
          </div>


          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 mx-4 pb-1 rounded-2xl relative w-full">
            <div className="flex justify-end mb-4">
              <button type="button" className="text-xl font-semibold text-gray-500" >
                <IoMdMore />
              </button>
            </div>
            {/* {showDropdown && (
              <div className="absolute right-0  w-38 bg-white border border-gray-300 shadow-lg rounded-lg z-10">
                <ul className="">
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView();
                      setShowDropdown(false);
                    }}
                  >
                    Monthly
                  </li>
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView();
                      setShowDropdown(false);
                    }}
                  >
                    Weekly
                  </li>
                </ul>
              </div>
            )} */}
            <div className="flex items-center flex-shrink">
              <div className="mr-2">
              <p className="font-bold text-gray-400">Expenses</p>
               
                <p className="font-bold text-1xl">0</p>
                <p className="text-gray-400 mt-2"></p>
              </div>
      
              <button
                type="button"
                style={{ backgroundColor: 'rgb(250, 128, 114)' }}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full p-3"
              >
                <GiExpense />
              </button>
            </div>
            
            <div className="mt-1">
              <Button color="white" bgColor={currentColor} text="Download" borderRadius="10px" />
            </div>
          </div>

          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 mx-4 pb-1 rounded-2xl relative w-full">
            <div className="flex justify-end mb-4">
              <button type="button" className="text-xl font-semibold text-gray-500" >
                <IoMdMore />
              </button>
            </div>
            {/* {showDropdown && (
              <div className="absolute right-0  w-38 bg-white border border-gray-300 shadow-lg rounded-lg z-10">
                <ul className="">
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView();
                      setShowDropdown(false);
                    }}
                  >
                    Monthly
                  </li>
                  <li
                    className="px-4 py-1 text-gray-700 hover:bg-gray-100 cursor-pointer transition-colors duration-200"
                    onClick={() => {
                      toggleView();
                      setShowDropdown(false);
                    }}
                  >
                    Weekly
                  </li>
                </ul>
              </div>
            )} */}
            <div className="flex items-center flex-shrink">
              <div className="mr-2">
              <p className="font-bold text-gray-400">Stock</p>
               
                <p className="font-bold text-1xl">0</p>
                <p className="text-gray-400 mt-2"></p>
              </div>
      
              <button
                type="button"
                style={{ backgroundColor: 'rgb(228, 208, 10)'}}
                className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full p-3"
              >
                <SlPresent />
              </button>
            </div>
            
            <div className="mt-1">
              <Button color="white" bgColor={currentColor} text="Download" borderRadius="10px" />
            </div>
          </div>
        </div>

        {/* end cards  */}

        {/* start graphs  */}
        <div className="flex flex-wrap lg:flex-nowrap justify-center mt-4 mx-8 flex-col md:flex-row">
          {/* start daily stats */}
          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 pb-1 rounded-2xl relative mx-4 w-full">
            <div className="flex justify-between">
              <p className="font-bold text-lg text-gray-400">Today's Sales</p>
            </div> 
            <div className="">
              <div>
                <p>
                  <span className="text-2xl font-semibold">{formatCurrency(todayBillAmount)}</span>
                  <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-600 ml-3 text-xs">
                    {orderedPercentage}%
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Ordered</p>
              </div>

              <div className='mt-8'>
                <p>
                  <span className="text-2xl font-semibold">{formatCurrency(todayReceivedAmount)}</span>
                  <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-600 ml-3 text-xs">
                    {receivedPercentage}%
                   
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Paid</p>
              </div>

             
              {/* <div className="mt-5">
                <SparkLine currentColor={currentColor} id="line-sparkLine" type="Line" height="80px" width="250px" data={SparklineAreaData} color={currentColor} />
              </div> */}
              <div className="mt-10">
                <Button color="white" bgColor={currentColor} text="Download Report" borderRadius="10px" />
              </div>
            </div>
          </div>
          {/* end daily stats  */}

        {/* start stacked graph */}
<div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 pb-1 rounded-2xl relative mx-4 w-full">
  <p className="text-gray-400 text-lg font-bold mt-2 text-center">{isMonthlyView ? "Monthly Sales" : "Weekly Sales"}</p>
  <div className="mt-4 gap-8 flex flex-col items-center">
    
    <div>
      <Stacked currentMode={currentMode} width="360px" height="360px" stackedChartData={chartData} isMonthlyView={isMonthlyView} />
    </div>
  </div>
</div>
{/* end stacked graph  */}
          {/* end stacked graph  */}
        </div>
        {/* end graphs  */}

        {/* start Categories  */}
        <div className="flex flex-wrap lg:flex-nowrap justify-center mt-4 mx-8 flex-col md:flex-row">
          {/* start product categories */}
          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 pb-1 rounded-2xl relative mx-4 w-full">
          <div>
            <div className="rounded-2xl md:w-400 p-4 m-3" style={{ backgroundColor: currentColor }}>
              <div className="flex justify-between items-center">
                <p className="font-semibold text-white text-2xl">Earnings</p>
                <div>
                  <p className="text-2xl text-white font-semibold mt-8">{formatCurrency(bills?.totalBillsTaxedPrice)}</p>
                  <p className="text-gray-200">Monthly Revenue</p>
                </div>
              </div>
            
              <div className="mt-4">
                <LineChart width="320px" height="360px" />
              </div>
            </div>
          </div>
            
          </div>
          {/* end product categories */}

          {/* start weekly stats  */}
          <div className="bg-white border border-gray-200 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-50 px-6 pt-3 pb-1 rounded-2xl relative mx-4 w-full">
            <div className="md:w-400 bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
              <div className="flex justify-between">
                <p className="text-xl font-semibold">Weekly Stats</p>
                <button type="button" className="text-xl font-semibold text-gray-500">
                  <IoMdMore />
                </button>
              </div>
              <div className="mt-10 ">
                
            		{weeklyStats && weeklyStats.map((item, index) => (
              			<div key={item.id || index} className="flex justify-between mt-4 w-full">
                			<div className="flex gap-4">
			                  <button
			                    type="button"
			                    className="text-2xl hover:drop-shadow-xl text-white rounded-full p-3"
			                  >
			                    {item.icon}
			                  </button>
                  			  <div>
			                    <p className="text-md font-semibold">{item.title}</p>
			                    <p className="text-sm text-gray-400">{item.desc}</p>
			                  </div>
			                </div>

                			<p className={`text-${item.pcColor}`}>{item.amount}</p>
              			</div>
            		))} 
		            <div className="mt-4">
		              <SparkLine currentColor={currentColor} id="area-sparkLine" height="160px" type="Area" data={SparklineAreaData} width="320" color="rgb(242, 252, 253)" />
		            </div>
          		</div>
            </div>
            {/* end weekly starts  */}
          </div>
          {/* end categories */}
        </div>
      </div>
    </Layout>
  );
 }
export default Dashboard
