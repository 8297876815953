// CategoryService.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';




export const handleCategoryApi = async (page, changedFields, userData, apiMethod) => {
  console.log('api in handleCategory api ', changedFields.id);
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
  };
  
  try {
    let response;
    switch (apiMethod) {
      case 'PUT':
        response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);
        break;
      case 'POST':
        response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config);
        break;
      case 'DELETE':
        response = await axios.delete(`${API_BASE_URL}/${page}`, config);
        break;
      default:
        throw new Error('Unsupported method');
    }
    console.log('from api response sir ', response);
    return { data: response }; // Return response data
  } catch (err) {
    console.error(`Error handling category API:`, err.response ? err.response.data : err.message);
    return { 
      error: {
        message: err.response ? err.response.data.message : err.message,
        status: err.response ? err.response.status : null
      }
    }; // Return error details including status code
  }
};



export const addCategory = async (page,userData,category) => {
  console.log('category in apaifffdfdfdfdf sir ',category)
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        name: category.name,
        photo_file_name:category.filename,
        photo: {data: category.displayImg !== null ? category.displayImg : null,
        contentType: category.imgType !== null ? category.imgType : null}
      })
    });

    console.log('from api response sir ',response)
    return {
      data: response 
    };
   

    } catch (err) {
        console.error('Error adding product category:', err);
        // if (data.data.status === 406) {
        //   toast.error('Category has products . Delete  products under this category first',data.data.statusText); // Display the error message to the use
        //   return
        // }
        throw new Error('Failed to add product category'); // Rethrow a custom error message to be handled by the calling code
    }
};

export const updateCategory = async (page, userData,category ) => {
  console.log('category in api',category)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: category.id }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:category.id,
      name: category.name,

     // photo_file_name:category.filename,
      photo: {data: category.displayImg !== null ? category.displayImg : null,
      contentType: category.imgType !== null ? category.imgType : null}
    }, config);
   
    console.log('from api response sir ',response.data)
    return {
      data: response.data 
    };

  } catch (err) {
        console.error('Error adding product:', err);
       // throw new Error('Failed to add product category'); // Rethrow a custom error message to be handled by the calling code
    }
};


export const deleteCategory= async (page, userData, category) => {
  console.log('deleteing ',userData.data.token)
  const config = {
  headers: { Authorization: `Bearer ${userData.data.token}` },
  params: { id: category.id }, // Send productId as a query parameter
};

try {
  const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
  // Check if the response indicates success (you may adjust this based on your API's response structure)
  console.log('from api response sir ',response)
    return { data: response};
} catch (err) {
  console.error('Error deleting product:', err);
  throw new Error('Failed to delete product');
}
};
