//Layout.jsx
import React,  { useEffect,useContext } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { GoDotFill } from 'react-icons/go';

import { IoIosMore } from 'react-icons/io';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import DashComponent from './DashComponent';

import { earningData, medicalproBranding, recentTransactions, weeklyStats, dropdownData, SparklineAreaData, ecomPieChartData } from '../data/dummy';


import AppContext from '../contexts/AppContext';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Sidebar} from '../components';
import { Products, Purchases, Pos,Orders, Calendar, Employees, Stacked, Pyramid, Customers, Kanban,Line, Area, Bar, Pie, Financial, ColorPicker, ColorMapping,  Editor,
} from './';

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
<DropDownListComponent id="time" fields={{ text: 'Time', value: 'Id' }} style={{ border: 'none', color: (currentMode === 'Dark') && 'white' }} value="1" dataSource={dropdownData} popupHeight="220px" popupWidth="120px" />
   </div>
 );

// const Props={children}
const Layout = (props:Props) => {
    const {isLoggedIn, currentColor,themeSettings, setThemeSettings,activeMenu,currentMode} = useContext(AppContext);
   
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
  
    
        <div className={` ${currentMode === 'Dark' ? 'dark' : ''}`}>

           <div className="flex relative dark:bg-main-dark-bg"> 
            
            <div className={activeMenu ? 'w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white' : 'w-0 dark:bg-secondary-dark-bg'}>
            <Sidebar />
            </div>
            <div className={activeMenu ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  ' : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '}>
             
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                  <Navbar />
                </div>
             
              
              {props.children}
             </div> 
          </div> 
      </div>
  
  
    )
}

export default Layout
