import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoSearchSharp } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { IoMdTrash } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { Layout, Header, Button, Modal, NewCustomer, EditCustomer} from '../../../../components';
import fetchData from '../../../../contexts/Api';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount, setActionCount } = useContext(AppContext);
  const navigate = useNavigate(); // Get navigate function from React Router
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter customers based on search term
  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    fetchCustomers();
  }, [actionCount]);

  const fetchCustomers = async () => {
    await fetchData('customers', userData, setCustomers);
    setIsLoading(false);
  };

  const handleAction = (action, customer) => {
    setActionType(action);
    setCustomer(customer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };
  console.log('customer',customers)

  return (
    <Layout>
      <div className="m-1 md:m-2 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 flex flex-row justify-end">
            <button
              className="bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose} // Call handleClose on click
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header customer="" title="Customers" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewCustomer data={customers} onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditCustomer data={customer}  onClose={handleCloseModal} />}
          {/* {isModalOpen && actionType === 'delete' && <DeleteCustomer rowData={customer} onClose={handleCloseModal} />} */}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-lg p-8 mx-auto">
              <div className="max-w-lg mx-auto">
                <button
                  className="rounded-lg mb-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
                  style={{ backgroundColor: currentColor }}
                  onClick={() => handleAction('new', {})}
                  type="button"
                >
                  Add Customer
                </button>
              </div>
              {/* Search Input */}
              {/* <div className="max-w-lg mx-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div> */}
              <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search customer..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                    
                    />
                    
                    <button
                        className="absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                        //onClick={handleSearchChange}
                    >
                        <IoSearchSharp size={20} color={currentColor} />
                    </button>
                </div>
              </div>

              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: Math.ceil(filteredCustomers.length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>

              <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg">
                {/* Column Header */}
                <div className="flex justify-between p-3 border-b">
                  <div className="flex items-center">
                    <span className="text-sm text-slate-400"></span>
                    <span className="text-sm text-slate-400 ml-4">Customer</span>
                  </div>
                  <span className="text-sm text-slate-400"> City</span>
                  <span className="text-sm text-slate-400"> Telephone</span>
                  <span className="text-sm text-slate-400">Action</span>
                </div>

                {/* List of Items */}
                {currentItems.map((customer, index) => (
                  <div key={customer.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                    <div className="flex items-center">
                      <span className="text-sm text-gray-900">{indexOfFirstItem + index + 1}</span>
                      <div className="ml-2 flex flex-col">
                        <div className="leading-snug text-sm text-gray-900 ">{customer?.name}</div>
                        <div className="leading-snug text-xs text-slate-400">
                          {customer?.is_company === 1 ? 'Company' : 'Individual'}
                        </div>
                      </div>
                    </div>
                    <div className="leading-snug text-sm text-gray-900 ">{customer?.districts?.name}</div>
                    <div className="leading-snug text-sm text-gray-900 ">{customer?.phone_number}</div>

                    <div className="flex items-center">
                      <button
                        className="h-6 px-2 text-sm rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('edit', customer)}
                        title='View product customer'
                      >
                        <GrView size={20} color={currentColor} />
                      </button>
                      {/* <button
                        className="h-6 px-2 ml-2 text-sm rounded-full hover:bg-blue-100"
                        onClick={() => handleAction('delete', customer)}
                        title='Delete'
                      >
                        <IoMdTrash size={20} color={currentColor} />
                      </button> */}
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: Math.ceil(filteredCustomers.length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Customers;
