
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { CiEdit } from 'react-icons/ci';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { getChangedFields,formatCurrency,handleApi, printQuotation, inputDate,formatMediumDate, deepEqual} from '../../../../utils/utils'; 
import { Pie } from '../../../../components';

const EditInvoice = ({ pi, actionType,onClose }) => {
   // const [invoice, setinvoice] = useState(pi);
    const [originalInvoice, setOriginalInvoice] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]); // All products in the database for autocomplete fields
    const [lineTotals, setLineTotals]=useState([]);
    const [grandTotal, setGrandTotal]=useState(0);
    const [names, setNames] = useState([]);
    const [price, setPrice]=useState(0);
    const [newProductLine, setNewProductLine] = useState(false); // for tracking new product line
    const [hasProductListChanged, setHasProductListChanged] = useState(false); // for tracking new product line
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceDueDate, setInvoiceDueDate] = useState('');
    // const [state, setState] = useState('');
    const [invoiceMode, setInvoiceMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false); // state to manage edit mode
    const [enableInvoiceInput,setEnableInvoiceInput] = useState(false); // state to manage edit mode
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
      // Initialize names based on transactionLines in originalInvoice
      if (Array.isArray(pi.transactionLines)) {
        const initialNames = pi.transactionLines.map(line => line.products.name || '');
        setNames(initialNames);
      }
      const { id,invoice_no, date, due_date ,state} = pi; // Destructure the fields
      setOriginalInvoice({
        id,
        invoice_no,            // Set new key for invoice number
        date: inputDate(date),   // Specify key for formatted invoice date
        due_date: inputDate(due_date), // Specify key for formatted due date
        state,                 // Set new key for state
    });
      setInvoiceNo(pi?.invoice_no || '');
      setInvoiceDate(pi?.date|| '');
      setInvoiceDueDate(pi?.due_date|| '');
      fetchCustomers();
      fetchProducts();
      // Check actionType and perform actions accordingly
      if (actionType === 'cancel') {handleCancelBtnClick();
      } else if (actionType === 'approve') {handleApproveBtnClick();
      } else if (actionType === 'edit') { setEnableInvoiceInput(true);
      } else {setEnableInvoiceInput(false);  }// Optional: handle other cases
     
  }, [pi]);

    useEffect(() => {
      // Check if transactionLines is defined and is an array before mapping
      if (Array.isArray(pi.transactionLines)) {
          const newLineTotals = pi.transactionLines.map(line => {
              const price = parseFloat(line.unit_price) || 0;
              const qty = parseFloat(line.quantity) || 1;
              const discount_percentage = parseFloat(line.discount_percentage) || 0;
              const discount_amount = (price * discount_percentage) / 100;
              return (price * qty) - discount_amount;
          });

          setLineTotals(newLineTotals);

          // Calculate grandTotal
          const total = newLineTotals.reduce((total, lineTotal) => total + lineTotal, 0);
          setGrandTotal(total);
      }
  }, [pi.transactionLines]);

    const fetchCustomers = async () => {
        await fetchData('customers', userData, setCustomers);
        setIsLoading(false);
    };

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {
            console.error('userData or token is undefined');
        }
    };

    
  
const handleInvoiceTextChange = async (event) => {
  const { name, value } = event.target;
  if (name === 'invoiceNo') {
    const supplierId = pi?.partners?.id || 0;
    const parameters = { partner_id: supplierId, invoice_no: invoiceNo };
    const apiMethod = 'GET';
    const cleanedInvoice = {}; // Make sure this is populated if needed
    const apiEndpoint = "purchase/invoices";
    console.log('Checking if invoice number exists...');
    try {
      // Send API request to check if the invoice number already exists
      const response = await handleApi(apiEndpoint, cleanedInvoice, userData, apiMethod, parameters);

      if (response.exists) {
        toast.error('This invoice number already exists. Please enter a unique invoice number.');
        return; // Prevent further execution if the invoice number already exists
      }
      setInvoiceNo(value);  // If the invoice number doesn't exist, proceed to set the invoice number
    } catch (error) {
      console.error('Error checking invoice number:', error);
      alert('An error occurred while checking the invoice number. Please try again.');
    }
  }else if (name === 'invoiceDate') {
    if (new Date(value) < new Date(pi.order?.date)) {
      // If the invoiceDate is later than the invoiceDueDate, handle the error
      toast.error('The invoice date cannot be earlier than order date. Please enter a valid date.');
      return; // Prevent further execution if the invoice number already exists
    } 
    setInvoiceDate(value);
  }else if (name === 'invoiceDueDate') {
    console.log('invoice due date',value,invoiceDate,invoiceDate)
    if (new Date(value) < new Date(invoiceDate)) {
      // If the invoiceDate is later than the invoiceDueDate, handle the error
      toast.error('Due date cannot be earlier than invoice date. Please enter a valid date.');
      return; // Prevent further execution if the invoice number already exists
    } 
    setInvoiceDueDate(value); 
  } else {return}
};



    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your invoice');
            formData.append('text', 'Please find the attached invoice.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'invoiceST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };

   

    
    const handlePrint = () => {
        const updatedinvoice = { ...pi, companyProfile };
        printQuotation(updatedinvoice);
    };

    const handleDownload = async () => {
        // Generate the PDF document
        const blob = await pdf(<MyDocument invoice={pi} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'invoice.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument invoice={pi} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'invoice.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };

    const handleApproveBtnClick = () => {
    //   const updatedinvoice = { ...pi, state: 'approved' };      // Create the updated invoice object
      const apiMethod = 'PUT';
      handleSubmitInvoice( apiMethod,'approved');  // Pass the updated invoice
  };
  
    const handleCancelBtnClick = async () => {
      const updatedinvoice = { ...pi};      // Create the updated invoice object
      const apiMethod = 'PUT';
      const response = await handleSubmitInvoice(apiMethod,'cancelled');  // pass apiMethod and state parameters
     // if (response && response.status === 200) {  onClose();}  // If the response is successful, close the modal
  };

  

// const handleInvoiceBtnClick = () => {
//   setEnableInvoiceInput(!enableInvoiceInput);

// };

const handleSubmitInvoice= async(apiMethod,state) => {

  const invoice ={  
  id:pi.id,
    invoice_no: invoiceNo,
    date:inputDate(invoiceDate),
    due_date:inputDate(invoiceDueDate),
    state: state ? state : 'draft' // Default to 'draft' if state is empty
  }
  console.log('invoice',invoice);
 
  //debugger;
  console.log('original invoice ',originalInvoice);

  const cleanedInvoice=getChangedFields(originalInvoice,invoice)
  console.log('cleanedInvoice',cleanedInvoice);
 
  const apiEndpoint='purchase/invoices'
  
  const parameters={id:pi.id};

  
//debugger
  try {
    const response = await handleApi(apiEndpoint, cleanedInvoice, userData, apiMethod, parameters);
     if (response.status === 200) {
  
      toast.success('invoice updated successfully.');
        
        
      // onClose();  // If the response is successful, close the modal
     } else {toast.error('Error saving invoice.', response.statusText);}
     return response; // Return the response for checking

  } catch (error) {
      toast.error('Error saving invoice: ' + error.message);
      return null; // Return null in case of error
  } finally {
      setActionCount(actionCount + 1);
      setIsLoading(false);
  }

};

  const handleCancel = () => {
       // resetForm()
   };

    const handleClose = () => {
        navigate(-1);
    };


    // const total = (invoice.transactionLines?.reduce((total, product) => total + product.total, 0) || 0).toFixed(2);
   // const total = grandTotal || 0;
    const shouldShowProductLinesHeader = pi?.date && pi?.customer_id;
    const userRole = userData?.data?.user_roles?.name?.toLowerCase();
    const userDepartment = (userData?.data?.employees?.departments?.name || '').toLowerCase();
    
    const allowedRoles = ['officer', 'admin', 'executive', 'supervisor', 'manager']; // roles allowed to edit 
    const allowedDepartments = ['procurement']; // departments allowed to edit 
    
    const isRoleAllowed = userRole && allowedRoles.includes(userRole);
    const isDepartmentAllowed = userDepartment && allowedDepartments.includes(userDepartment);
    
    // Updated isUserAllowed logic
    const isUserAllowed = (isRoleAllowed && isDepartmentAllowed) || ['admin', 'executive'].includes(userRole);
    const isOfficerAllowed= (isDepartmentAllowed && userRole.toLowerCase()  ==='officer'&& pi?.state.toLowerCase() ==='draft');
  
    //  console.log('po sir ',pi);
      // Calculate grandTotal dynamically
    //   const grandTotal = invoice.transactionLines.reduce((total, line) => {
    //     return total + (parseFloat(line.lineTotal) || 0);
    // }, 0);
  
    return (
      <>
        <div className="">
          <div className="flex">
            <h2 className="text-blue-700  text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Purchase Invoice</h2>
          </div>
          <div>
          {isLoading ? (
            <div className="flex items-center flex-col">
              <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
              <p className="mt-2">Processing data. Please wait...</p>
            </div>
          ) : (
            <>
              {/* meneu icon */}
              <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
              {(isUserAllowed || isOfficerAllowed) && !invoiceMode && pi.state!=='invoiced'&& pi.state!=='cancelled'&& actionType!=='cancel'&&( // show to office if order is in draft
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Cancel"
                    onClick={handleCancelBtnClick}
                  >
                    <FcCancel size={20} color={currentColor} />
                  </button>
              )}
             
                {pi?.transactionLines && grandTotal > 0 && pi.state ==='draft' && actionType!=='cancel'&&(
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Approve"
                  onClick={handleApproveBtnClick}
                >
                  <FcApproval size={20} color={currentColor} />
                </button>
                   )}

                {/* {pi.state ==='approved' && !invoiceMode &&(
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Generate Invoice"
                    onClick={handleI}
                  >
                    <LiaFileInvoiceDollarSolid size={20} color={currentColor} />
                  </button>
                )} */}
             
                {/* {isUserAllowed &&  pi.state!=='invoiced'&& !enableInvoiceInput && pi.state!=='cancelled'&&(
                        
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Edit"
                  onClick={() => handleInvoiceBtnClick()}
                >
                  <CiEdit size={20} color={currentColor} />
                </button>
              
                )}
                 */}

              

                {invoiceNo && invoiceDate &&invoiceDueDate && enableInvoiceInput &&(
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Submit Invoice"
                    onClick={() => handleSubmitInvoice('PUT','')} // pass apiMethod and state value
                  >
                    <BsFillSendArrowUpFill size={20} color="blue" />
                  </button>
                )}


               
              </div>
              {/* end menu icons */}
              {/* Purchase Order  preamble info */}
              <p className="whitespace-nowrap mb-1 text-gray-800  text-sm text-semi-bold text-center uppercase">Order Info</p>
              <div className="mb-2 mx-2 md:mx-1  border border-gray-100 py-2 md:py-2 bg-gray-50 rounded-3xl"> 
                <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col ">
                  <div className="flex flex-wrap justify-between gap-4 mb-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Purchase Order #.</p>
                      <p className="whitespace-nowrap text-slate-500 font-semibold text-xs uppercase">
                        {pi?.reference|| 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Date</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {pi?.date ? formatMediumDate(pi.date) : 'NA'} {/* Check if pi.date is valid */}
                      </p>
                    </div>

                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Status</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {pi?.state || 'NA'}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Customer</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {pi?.partners?.name || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Telephone</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                          {pi?.partners?.phone_number || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Contact Person</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                          {pi?.partners?.contact_person || 'NA'}
                      </p>
                    </div>
                  </div>  
                </div>
              </div>
              {/* end Purchase Order adjust preamble info */}

              {/* Invoice Inputs */}
      
              <p className="whitespace-nowrap mb-1 text-sm font-semi-bold uppercase text-gray-800 text-center">Invoice Info</p>
           
              <div className="mb-2 mx-2 md:mx-1  border border-gray-100 py-2 md:py-2 bg-gray-50 rounded-3xl"> 
                <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col ">
                <div className="flex space-x-4"> {/* Add space between inputs */}
                <div className="flex">
  <div className="flex-none max-w-[150px] mr-2"> {/* Set max width to 100px and add right margin */}
    <p className="whitespace-nowrap text-blue-500 text-xs">Invoice #.</p>
    
    <input
      name='invoiceNo'
      type="text"
      value={invoiceNo}
      onChange={handleInvoiceTextChange}
      className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full" // Set width to 100% of its container
      placeholder="Invoice No"
      disabled={!enableInvoiceInput}
    />
  </div>

  <div className="flex-1 min-w-[150px]">
    <p className="whitespace-nowrap text-blue-500 text-xs">Invoice Date</p>
    
    <input
      name='invoiceDate'
      type="date"
      value={invoiceDate ? inputDate(invoiceDate) : ''}
      onChange={handleInvoiceTextChange}
      className="px-4 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      placeholder="Invoice Date"
      disabled={!enableInvoiceInput}
    />
  </div>
</div>

                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Due Date</p>
                      <input
                    name='invoiceDueDate'
                    type="date"
                    value={invoiceDueDate? inputDate(invoiceDueDate) : ''}
                    onChange={handleInvoiceTextChange}
                    className="px-4 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                    placeholder="invoice Date"
                    disabled={!enableInvoiceInput}
                   
                  />
                    </div>
                  </div>
                  
                </div>
              </div>
              {/* end Invoice Inputs */}

              {/* Products lines  */}
        
          <>
          
              <p className="whitespace-nowrap mb-1 text-sm font-semi-bold uppercase text-gray-800 text-center">Product Lines</p>
        
            <div className="mx-2 md:mx-1 py-0 md:py-0 border border-blue-500 bg-blue-400 rounded-3xl">
              <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                {/* Table Header */}
                <div className="flex items-center font-semibold mb-2 text-white">
                  <div className="w-1/12 text-center"></div>
                    <div className="w-1/3">Product Name</div>
                      <div className="w-1/6 text-right mr-4 ">Price</div>
                        <div className="w-1/6 mx-2">Quantity</div>
                        <div className="w-1/6 mx-2">Discount</div>
                        <div className="w-1/6 text-right">Total</div>
                        <div className="w-1/9 text-right"></div>
                      </div>

                      <div className="space-y-2">
                      
{/* Ensure `invoice` and `invoice.products` are defined and `invoice.products` is an array */}
{Array.isArray(pi.transactionLines) && pi.transactionLines.length > 0 ? (
  pi.transactionLines.map((product, index) => (
    <div key={index} className="flex items-center mb-2  text-sm text-white">
      <div className="w-1/12 text-center font-semibold">{index + 1}</div>
     
      <p
        className="px-4 py-1 w-1/3 text-right  text-gray-900 font-semibold bg-slate-300 rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      >
      {names[index] || ''}
      </p>
      

      <p
        className="px-4 mx-2 py-1 w-1/6 text-right  text-gray-900 font-semibold bg-slate-300 rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      >
       {product.unit_price || 0}
      </p>
      
      <p
        className="px-4 mx-2 py-1 w-1/6 text-right  text-gray-900 font-semibold bg-slate-300 rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      >
        {product.quantity || 0}
      </p>

      <p
        className="px-4 py-1 w-1/6 text-right  text-gray-900 font-semibold bg-slate-300 rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      >
        {product.discount_percentage            || 0}
      </p>
      
      <div className="w-1/6 text-right font-semibold">
        MK {lineTotals[index] ? lineTotals[index] : '0.00'}
      </div>

    </div>
  ))
) : (
  <div className="flex justify-center items-center h-32 bg-white rounded-lg">
    <p className="text-slate-500 text-lg">No products available</p>
  </div>
)}

                        </div>
                        {/* Grand Total */}
                        <div className="flex items-center font-semibold mt-4 text-white">
                          <div className="w-1/12 text-center"></div>
                          <div className="w-1/3"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6 text-right">Total:</div>
                          <div className="w-1/6 text-right font-semibold">
                               {formatCurrency(grandTotal)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isEditable && (
          
                      <div className="flex justify-end p-2">
                        <button
                          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                          disabled={!isEditable} // Conditionally disable input
                          // onClick={handleSaveBtnClick}
                          title='Submit'
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </>
                
                {/* end products line */}
              </>
            )}
          </div>
        </div>
      <ToastContainer />
    </>
  );
};

export default EditInvoice;
