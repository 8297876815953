import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdTrash } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdAdd } from 'react-icons/io';
import { IoMdMore } from "react-icons/io";
import { Layout, Header, Button, Modal, NewQtyAdjustment, EditQtyAdjustment , DeleteProductCategory} from '../../../../components';
import { handleApi,  } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow } from 'date-fns';

const Quantities = () => {
  const [quantityAdjustments, setQuantityAdjustments] = useState([]);
  const [quantityAdjustment, setQuantityAdjustment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount, setActionCount } = useContext(AppContext);
  const navigate = useNavigate(); // Get navigate function from React Router
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter categories based on search term
  const filteredQuantityAdjustments = quantityAdjustments.filter(qty =>
    (qty?.users?.username.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredQuantityAdjustments.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
   // setIsLoading(true);
    fetchQuantityAdjustments();
  }, [actionCount]);

  

  const fetchQuantityAdjustments = async () => {
    const apiEndpoint = 'quantity_adjustments';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
      if (status === 200) {
        setQuantityAdjustments(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = (action,qty) => {
    setActionType(action);
    setQuantityAdjustment(qty);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
};


  // Convert server date to local date
  const convertToLocalTime = (dateStr) => {const date = new Date(dateStr);return date; };
  
  // Custom format function to remove "about"
  const formatFriendlyDate = (dateStr) => {
    const formattedDate = formatDistanceToNow(convertToLocalTime(dateStr), { addSuffix: true });
    // Remove "about" if present
    return formattedDate.replace(/^about\s/, '');
  };
  
console.log('product setQuantityAdjustment',quantityAdjustments)
  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="QUANTITY ADJUSTMENTS" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewQtyAdjustment data={filteredQuantityAdjustments} onClose={handleCloseModal} showCloseButton={true}/>}
          {isModalOpen && actionType === 'edit' && <EditQtyAdjustment rowData={quantityAdjustment}  onClose={handleCloseModal} showCloseButton={true}/>}
           </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl  mx-auto">
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">
            <div className="max-w-lg flex justify-end items-center mb-2 mx-auto">
              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: Math.ceil(filteredQuantityAdjustments.length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
      {/* Search Input */}
    <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
      />
    </div>

     {/* New Quotation Button */}
     <button
      className="ml-4 px-3 py-2 text-xs font-medium rounded-lg bg-white focus:outline-none flex items-center space-x-1"
      style={{ border: `1px solid ${currentColor}`, color: currentColor }}
      onClick={() => handleAction('new', {})}
    >
      <IoMdAdd />
      <span className="ml-1">New Quotation</span>
    </button>
  </div>
   {/*end  New Quotation Button */}


              </div>
              

              {/* Quotations List */}
              <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4 p-3 border-b">
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">ID</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Adjusted by</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Status</span>
                  </div>
                
                
                  <div className="text-right">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Actions</span>
                  </div>
                </div>

                {currentItems.length > 0 ? (
                  currentItems.map((qty,index ) => (
                   
                    <div key={qty.id}   onClick={() => handleAction('edit', qty)}  className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200">
                      <div className="text-center">
                        <span className="text-sm text-gray-900">{indexOfFirstItem + index + 1}</span>
                      </div>
                      <div className="text-center">
                        <span className="text-sm text-gray-900">
                          {qty?.users?.username ? qty?.users?.username : ''}
                          {qty?.adjustment_date
                          ? ` (${formatFriendlyDate(qty?.adjustment_date)})`
                          : ' (No Date Available)'}
                        </span>

                      </div>
                      <div className="text-center">
                        <span className={`text-sm text-white py-1 px-3 rounded-full ${qty?.approved  ? 'bg-red-500' : 'bg-green-500'}`}>
                        {qty?.approved ? 'Approved' : 'Draft'}
                        </span>
                        </div>
                      <div className="text-center">
                      <button
                        className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                        onClick={() => handleAction('edit', qty)}
                        title="More detail"
                      >
                        <IoMdMore size={20} color={currentColor} />
                      </button>
                      </div>
                      
                      
                    </div>
                  ))
                ) : (
                  <p className="p-3 text-center">No quotations available.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Quantities;
