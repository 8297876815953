import React, { useState, useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { getEmployees, addUser } from '../utils/api';



const NewUser = ({ users, onClose }) => {
    const { userData } = useContext(AppContext);
    const [user, setUser] = useState({
        // first_name: '',
        // last_name: '',
        // phone_number: '',
        // department: '',
        // hire_date: '',
        // photo_url: '' // Ensure all fields are initialized in the state
    });
    const [employees, setEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [actionCount, setActionCount] = useState(0);

    useEffect(() => {
        console.log('users from parent',users)
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        setIsLoading(true);
        try {
            const employeesData = await getEmployees('employees', userData);
            console.log('employees data',employeesData.data)
            const updatedEmployees = employeesData.data.map(employee => ({
                ...employee,
                full_name: `${employee.first_name} ${employee.last_name}`
            }));
            setEmployees(updatedEmployees);
        } catch (error) {
            console.error('Error fetching employees:', error);
            toast.error('Failed to fetch employees');
        } finally {
            setIsLoading(false);
        }
    };

    





    const handleTextChange = (event) => {
        const { name, value } = event.target;
        
        if (name === 'full_name') {
            const selectedEmployee = employees.find(employee => employee.full_name === value);
            if (selectedEmployee) {
                setUser(prevUser => ({
                    ...prevUser,
                    full_name: selectedEmployee.full_name, // Update full_name directly here
                    employee_id: selectedEmployee.id,
                    photo_url: selectedEmployee.photo_url
                }));
            } else {
                setUser(prevUser => ({
                    ...prevUser,
                    full_name: value, // Handle case when user manually types
                    employee_id: null,
                    photo_url: null
                }));
            }
        } else {
            setUser(prevUser => ({ ...prevUser, [name]: value }));
        }
    };
    

    const handleSubmit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Check if passwords match
        if (user.password !== user.confirm_password) {
            toast.error('Passwords do not match.');
        return;
        }

        // Check if user.employee_id is already in users
        if (users.some(u => u.employee_id === user.employee_id)) {
            toast.error('User already exists.');
            return;
        }

        if (!user.full_name) { toast.error('Please enter employees '); return; }
        if (!user.role) { toast.error('Please enter role'); return; }
        if (!user.login_name) { toast.error('Please enter login name '); return; }
        if (!user.password) { toast.error('Please enter password '); return; }


        try {
            const response = await addUser('users', user, userData);
            console.log('User added successfully:', response);
            setUser({
                login_name: '',
                role: '',
                password: '',
                confirm_password: '',
                photo_url: ''
            });
            toast.success('User added successfully');
        } catch (err) {
            console.error('Error adding user:', err);
            toast.error('Error submitting form');
        }
    };

    console.log('new employees at this point', employees);

    return (
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New User</h2>
            </div>
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <div className="relative z-0 mb-8 group">
                            <div className="flex">
                            <input
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="employeesList"
                                name="full_name"
                                onChange={(event) => handleTextChange(event)}
                                value={user?.full_name}
                            />
                            {user.photo_url && (
                                <img
                                    className="rounded-full h-24 w-24"
                                    src={user.photo_url} alt="employee"
                                />
                            )}
                            </div>
                            <datalist id="employeesList">
                                {employees.map((employee) => (
                                    <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`} />
                                ))}
                            </datalist>
                            <label
                                htmlFor="full_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Select Employee
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="role"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={user.role}
                            />
                            <label
                                htmlFor="role"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Role
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="login_name"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={user.login_name}
                            />
                            <label
                                htmlFor="login_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Login Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="password"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={user.password}
                            />
                            <label
                                htmlFor="password"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Password
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="confirm_password"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={user.confirm_password}
                            />
                            <label
                                htmlFor="confirm_password"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Retype password
                            </label>
                        </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewUser;
