import React from 'react';

const Header = ({ category, title, color }) => (
  <div className="flex display-center mb-1 min-w-min">
    <p className="text-lg text-gray-400">{category}</p>
    <div className="mb-8 mx-auto "> {/* Update with Tailwind classes */}
      <p
        className="text-2xl  font-extrabold tracking-tight relative uppercase"
        style={{ color }}
      >
        {title}
        {/* <span className="absolute inset-x-0 bottom-0 h-px bg-gray-300"></span> */}
      </p>
    </div>
  </div>
);

export default Header;
