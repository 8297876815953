// CategoryService.js
import axios from 'axios';
import API_BASE_URL from '../config';

export const getCountries = async (page,userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
    };
    try {
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
       console.table('API Response:', data); // Log the data
      return {
        count: data.length,
        result: data,
        error: null, // Include this line to ensure consistency
      };
    } catch (error) {
      console.error('Error fetching data:', error);
  
      // Ensure that the returned object has the expected structure
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch data. Please try again later.',
      };
    }
  };

  export const getRegions = async (page, countryId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { country_id:countryId}, // query parameters ,  with_photos:1
    };
    try {
      //console.log(page, productId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
      //console.log('API Response sir :', data); // Log the data
      console.table('API Response:', data); // Log the data
      return {
        count: data ? 1 : 0, // Assuming data is the product details
        result: data ? [data] : null,
        error: null,
      };
    } catch (error) {
      console.error('Error fetching products :', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch product details. Please try again later.',
      };
    }
  };

  export const getCities = async (page, regionId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { region_id:regionId}, // query parameters ,  with_photos:1
    };
    try {
      //console.log(page, productId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      const data = response.data;
      //console.log('API Response sir :', data); // Log the data
      console.table('API Response:', data); // Log the data
      return {
        count: data ? 1 : 0, // Assuming data is the product details
        result: data ? [data] : null,
        error: null,
      };
    } catch (error) {
      console.error('Error fetching products :', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch product details. Please try again later.',
      };
    }
  };
  
export const addLocation = async (id, name, page,userData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/${page}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userData.data.token}`
            },
            body: JSON.stringify({
                id: id,
                name: name
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
    } catch (err) {
        console.error('Error adding product:', err);
        throw new Error('Failed to add product category'); // Rethrow a custom error message to be handled by the calling code
    }
};
