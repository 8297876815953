
    // RoleService.js
    import axios from 'axios';
    import API_BASE_URL from '../../../../config';

    export const addRole = async (page, role, userData) => {
      console.log('Role in api ',role)  
      try {
        const response = await fetch(`${API_BASE_URL}/${page}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json','Authorization': `Bearer ${userData.data.token}`},
          body: JSON.stringify({
            name:role.name,
            description:role.description,
            session_id:1
          })
        });
        console.log('from api response sir ',response)
        return { data: response};

      } catch (err) {
        console.error('Error adding role:', err);
        throw new Error('Failed to add Role'); // Rethrow a custom error message to be handled by the calling code
      }
  };
export const updateRole = async (page, role, userData) => {

    console.log('updateing sir ',role)
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id: role.id }, // Send productId as a query parameter
    };
  
    try {
      const response = await axios.put(`${API_BASE_URL}/${page}`, {
        id:role.id,
        name:role.name,
        description:role.description,
        //active:role.active,
        // photo: {data:role.displayImg,contentType:role.photoType},
        session_id:1
      }, config);
      console.log('from api response sir ',response)
      return { data: response};

      // const data = await response.json();
    } catch (err) {
      console.error('Error updating product:', err);
      throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
    }
  };
  



  export const deleteRole= async (page, userData, role) => {
    console.log('deleteing ',userData.data.token)
    const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: role.id }, // Send productId as a query parameter
  };
  
  try {
    const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
    // Check if the response indicates success (you may adjust this based on your API's response structure)
    console.log('from api response sir ',response)
      return { data: response};
  } catch (err) {
    console.error('Error deleting product:', err);
    throw new Error('Failed to delete product');
  }
  };
