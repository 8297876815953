import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';

const Footer = () => {
  const { companyProfile } = useContext(AppContext); // Hook usage should be inside the function body

  return (
    <div className="my-8">
      <p className="dark:text-gray-200  text-gray-400 text-center align-center text-xs mx-auto">
        © 2023 All rights reserved by {companyProfile.email}
      </p>
    </div>
  );
};

export default Footer;
