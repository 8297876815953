//App.js
import React, { useEffect,useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';
import {
  Login, Dashboard, Settings, Departments,Categories, Products, Quantities, Prices, AllProductsPrices, CategoriesPrices, Quotations, Pos,Orders, Deliveries,Invoices,Payments, Calendar, Employees, Users,Roles, Stacked, Pyramid, 
  Customers, Kanban,Line, Area, Bar, Pie, Financial, ColorPicker, 
  ColorMapping,  Editor,Suppliers, SelectedProductsPrices
} from './pages';
import './App.css';
import AppContextProvider from './contexts/AppContextProvider';
// import AppContext from './contexts/AppContext';

const App= () => {
// const isLoggedIn=false;
 
  // useEffect(() => {
   
  //   const currentThemeColor = localStorage.getItem('colorMode');
  //   const currentThemeMode = localStorage.getItem('themeMode');
  //   if (currentThemeColor && currentThemeMode) {
  //     setCurrentColor(currentThemeColor);
  //    setCurrentMode(currentThemeMode);
  //   }
  //  }, []);
  

  return (
  <AppContextProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/kanban" element={<Kanban />} />
                {/* pages  */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} /> 
            <Route path="/departments" element={<Departments />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/products" element={<Products />} />
            {/* <Route path="/purchases" element={<Purchases />} /> */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/deliveries" element={<Deliveries />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/quantities" element={<Quantities />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/prices/adjust-all-products-prices" element={<AllProductsPrices />} />
            <Route path="/prices/adjust-product-categories-prices" element={<CategoriesPrices />} />
            <Route path="/prices/adjust-selected-products-prices" element={<SelectedProductsPrices />} />
            {/* <Route path="/example" element={<Example />} /> */} 
            <Route path="/pos" element={<Pos />} />
            <Route path="/quotations" element={<Quotations />} />
            {/* <Route path="/orders" element={<Orders />} /> */}
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/users" element={<Users />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/kanban" element={<Kanban />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/color-picker" element={<ColorPicker />} />
            <Route path="/line" element={<Line />} />
            <Route path="/area" element={<Area />} />
            <Route path="/bar" element={<Bar />} />
            <Route path="/pie" element={<Pie />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/color-mapping" element={<ColorMapping />} />
            <Route path="/pyramid" element={<Pyramid />} />
            <Route path="/stacked" element={<Stacked />} />
            {/* ... other routes */}
            {/* apps  */}
            <Route path="/kanban" element={<Kanban />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/color-picker" element={<ColorPicker />} />

            {/* charts  */}
            <Route path="/line" element={<Line />} />
                <Route path="/area" element={<Area />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/financial" element={<Financial />} />
                <Route path="/color-mapping" element={<ColorMapping />} />
                <Route path="/pyramid" element={<Pyramid />} />
                <Route path="/stacked" element={<Stacked />} />

              </Routes>
           
            <Footer />
      </BrowserRouter>
 

  </AppContextProvider>
  );
};


export default App;
