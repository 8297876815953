// links.js
import { AiOutlineDashboard, AiOutlineUser, AiOutlineShop, AiOutlineFile } from 'react-icons/ai';
import { AiTwotoneDashboard } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoSettingsOutline,IoPricetagsOutline } from "react-icons/io5";
import { FcDepartment } from "react-icons/fc";
import { MdOutlineInventory, MdOutlineCategory, MdOutlineLocalGroceryStore} from "react-icons/md";
import { GiRolledCloth } from "react-icons/gi";
import { FaListOl,FaSackDollar,FaRegUser ,FaPeopleCarryBox} from "react-icons/fa6";
import { TbTruckDelivery } from "react-icons/tb";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FcSalesPerformance } from "react-icons/fc";
import { MdPointOfSale } from "react-icons/md";
import { BsPersonWalking } from "react-icons/bs";
import { SlPeople } from "react-icons/sl";
import { FaPeopleArrows } from "react-icons/fa";




export const links = [
  {
    title: 'Dashboard',
    icon: <AiTwotoneDashboard />, // Add an icon for the Dashboard section
    links: [
      {
        name: 'dashboard',
        icon: <AiTwotoneDashboard />,
      },
    ],
  },
  {
    title: 'Company',
    icon: <HiOutlineOfficeBuilding />, // Add an icon for the Company section
    role: ['manager', 'executive', 'admin'],
    department: ['Human Resources', 'ICT'],
    links: [
      {
        name: 'Settings',
        icon: <IoSettingsOutline />,
      },
      {
        name: 'Departments',
        icon: <FcDepartment />,
      },
    ],
  },
  {
    title: 'Partners',
    icon: <FaPeopleArrows />, // Add an icon for the Company section
    // role: ['executive', 'admin'],
    // department: ['Sales', 'Purchases'],
    links: [
      {
        role: ['admin','executive'], // Only admin  can access Dashboard
        department: ['sales', 'purchases'],
        name: 'Suppliers',
        icon: <TbTruckDelivery />,
      },

      {
        role: ['admin','executive'], // Only admin  can access Dashboard
        department: ['sales', 'purchases'],
        name: 'Customers',
        icon: <BsPersonWalking />,
      },

    ],
  },
  {
    title: 'Sales',
    icon: <FcSalesPerformance />, // Add an icon for the Sales section
    links: [
      {
        role: ['executive','admin'], // Both admin and manager can access Dashboard
        name: 'POS',
        icon: <MdPointOfSale />,
      },

      
      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Quotations',
        icon: <MdOutlineLocalGroceryStore />,
      },
     

      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Invoice',
        icon: <LiaFileInvoiceDollarSolid />,
      },
      {
        role: ['admin'], // Only admin  can access Dashboard
        name: 'Payment',
        icon: <FaSackDollar />,
      },
    ],
  },
  // other sections...


  {
    title: 'Inventory',
    icon: <MdOutlineInventory />, // Add an icon for the Dashboard section
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['purchasing'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'categories',
        icon: <MdOutlineCategory />,
      },
      {
        name: 'Products',
        icon: <GiRolledCloth />,
      },
      {
        name: 'Quantities',
        icon: <FaListOl/>,
      },
      {
        name: 'Prices',
        icon: <IoPricetagsOutline />,
      },

 
    ],
  },




  {
    title: 'Purchases',
    icon: <MdOutlineLocalGroceryStore />, // Add an icon for the Dashboard section
    
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['Purchasing'], // Only users in HR department can access Company settings
  
    links: [
    
      {
        name: 'Orders',
        icon: <MdOutlineLocalGroceryStore />,
      },

      {
        name: 'Deliveries',
        icon: <TbTruckDelivery />,
      },

      {
        name: 'Invoices',
        icon: <LiaFileInvoiceDollarSolid />,
      },
      {
        name: 'Payments',
        icon: <FaSackDollar />,
      },
     
    ],
  },

 
  {
    title: 'Employees',
    icon: <SlPeople />, // Add an icon for the Dashboard section
    
    role: ['admin', 'manager','executive'], // Both admin and manager can access Dashboard
    department: ['Human Resources'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'Employees',
        icon: <SlPeople />,
      },
    ],
  },
 
  {
    title: 'Users',
    icon: <FaRegUser />, // Add an icon for the Dashboard section
    role: ['admin'], // Both admin and manager can access Dashboard
    department: ['ICT'], // Only users in HR department can access Company settings
  
    links: [
      {
        name: 'Users',
        icon: <FaRegUser />,
      },
      {
        name: 'Roles',
        icon: <FaPeopleCarryBox />,
      },

    
 
    ],
  },
  
  {
    title: 'Reports',
    role: ['admin'], // Both admin and manager can access Dashboard
    department: ['purchasing'], // Only users in HR department can access Company settings
  
    links: [
    
      {
        name: 'color-mapping',
        icon: <AiTwotoneDashboard />,
      },
      {
        name: 'calendar',
        icon: <AiTwotoneDashboard />,
      },
   
    ],
  },
];
