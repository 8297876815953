import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { FcCancel } from "react-icons/fc";
import { format } from 'date-fns';
import { Audio } from 'react-loader-spinner';
import CreatableSelect from "react-select/creatable";
import { BsFillSendFill } from "react-icons/bs";
import { FaTrashAlt } from 'react-icons/fa';
import { IoSearchSharp } from "react-icons/io5";
import { FcApproval } from "react-icons/fc";
import { IoPlayForwardOutline } from "react-icons/io5";
import { IoPlayBackOutline } from "react-icons/io5";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, collapseToast, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Layout, Header } from '../../../../components';
import fetchData from '../../../../contexts/Api';
import { getReasons, newQtyAdjustment, handleQtyAdjustmentApi } from '../utils/api';

const EditQtyAdjustment = ({ rowData, onClose }) => {
    const [qtyAdjustment, setQtyAdjustment] = useState({});
    const [originalQtyAdjustment, setOriginalQtyAdjustment] = useState({});
    const [reasons, setReasons] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [adjustment_lines, setAdjustment_lines] = useState(rowData ? rowData.quantity_adjustment_lines : []);
    const [original_adjustment_lines, setOriginalAdjustment_lines] = useState(rowData ? rowData.quantity_adjustment_lines : []);
    const [selectedProducts, setSelectedProducts] = useState(new Set());
    const { isLoggedIn, userData, currentColor, actionCount, setActionCount } = useContext(AppContext);
    const navigate = useNavigate();

    const filteredProducts = products.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (adjustment_lines || []).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        setQtyAdjustment(rowData);
        setOriginalQtyAdjustment(rowData);
        fetchReasons();
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {console.error('userData or token is undefined');}
    };

    const fetchReasons = async () => {
        if (userData) {
            const Data = await getReasons('quantity_adjustment_reasons', userData);
            const reasonsData = Data?.data?.data;
            const formattedReasons = reasonsData?.map(reason => ({
                value: reason.id,
                label: reason.name,
            }));
            setReasons(formattedReasons);
            setIsLoading(false);
        }
    };

    const handleReasonChange = (newValue, actionMeta, item) => {
        item.reason_id = newValue ? newValue.value : '';
    };

    const handleSearch = () => {
        const searchedProducts = filteredProducts
            .filter(product => !adjustment_lines.some(p => p.product_id === product.id))
            .map(product => ({
                product_id: product.id,
                name: product.name,
                product_category: product.product_categories.name,
                old_quantity: product.quantity_instock,
                new_quantity: '',
                uoms: product?.prices?.buy_uom_name,
                reason_id: reasons.find(reason => reason.label === 'Error Correction').value
            }));

        setAdjustment_lines(prevAdjustment_lines => [...prevAdjustment_lines, ...searchedProducts]);
        setSearchTerm('');
    };

    const handleRemoveProduct = (id) => {
        setAdjustment_lines(prevList => prevList.filter(product => product.product_id !== id));
    };

    const handleCheckboxChange = (id) => {
        setSelectedProducts(prevSelectedProducts => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            if (newSelectedProducts.has(id)) {
                newSelectedProducts.delete(id);
            } else {
                newSelectedProducts.add(id);
            }
            return newSelectedProducts;
        });
    };

    const handleClose = () => {
        navigate(-1);
    };

    const handleChange = (productId, field, value) => {
        setAdjustment_lines(prevAdjustment_lines =>
            prevAdjustment_lines.map(product =>
                product.product_id === productId ? { ...product, [field]: value } : product
            )
        );
    };

    const getChangedFields = (original, updated) => {
        const changedFields = {};
            for (const key in updated) {
              if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {changedFields[key] = updated[key];}
            }
            if (original.id) {changedFields.id = original.id; }   // Include id if it exists in original
            return changedFields;
          };


    
  
    const handleApproveQtyAdjustment = () => {
        if (adjustment_lines && adjustment_lines.length > 0) {
          setQtyAdjustment(prevQtyAdjustment => ({...prevQtyAdjustment,approved: 1}));
          const qtyAdjustmentToApi = {approved:1,id: qtyAdjustment.id};
          console.log('qtyAdjustmentToApi',qtyAdjustmentToApi)
          const apiMethod = 'PUT';
          handleQtyAdjustmentApi('quantity_adjustments', qtyAdjustmentToApi, userData, apiMethod)
            .then(async (data) => {
              console.log('data from approved quantity adjustment api', data.data.statusText);
              if (data.data.status === 200) {
                toast.success('Quantity adjustment approved successfully.', data.data.statusText);
                setActionCount(actionCount + 1);
              } else if (data.data.status === 409) {toast.error('Product already approved', data.data.statusText);
              } else {toast.error('Error approving quantity adjustment.', data.data.statusText);}
            })
            .catch(error => {
              toast.error(error.message);
            })
            .finally(() => {
              setActionCount(actionCount + 1);
              setIsLoading(false);
            });
        } else {
          // Optional: Handle the case where quantity_lines is empty (e.g., display an error message)
          toast.error('Quantity adjustment cannot be approved: quantity lines are empty');
        }
      };
      

    const handleDeleteQtyAdjustment = () => {
        if (!toast.isActive("delete-toast")) {
          const confirmDelete = () => {
            console.log('Quantity adjustment', qtyAdjustment.id);
            // Proceed with deletion logic here
            const apiMethod = 'DELETE'
            handleQtyAdjustmentApi('quantity_adjustments', qtyAdjustment, userData, apiMethod)
            //newQtyAdjustment('quantity_adjustments', userData, qtyAdjustment)
            .then(async (data) => {
                console.log('data from product api', data.data.statusText);
                if (data.data.status === 200) {
                    toast.success('Quantity adjustment deleted Successfully.', data.data.statusText);
                    setActionCount(actionCount + 1);
                    if (onClose) onClose(); // Close the component and return to parent
               
                } else if (data.data.status === 409) {toast.error('Product already exists', data.data.statusText);
                } else {toast.error('Error deleting quantity adjustment.', data.data.statusText);}
            })
            .catch(error => {toast.error(error.message);
            })
            .finally(() => {
                setActionCount(actionCount + 1);
                setIsLoading(false);
            });
            toast.dismiss("delete-toast"); // Optionally dismiss the toast
          };
      
          const cancelDelete = () => {
            console.log('Deletion canceled');
            toast.dismiss("delete-toast"); // Dismiss the toast
          };
      
          toast(
            ({ closeToast }) => (
              <div className="flex flex-col items-center space-y-2">
                <p className="text-center ">Are you sure you want to delete this quantity adjustment?</p>
                <div className="flex space-x-4">
                  <button
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                    onClick={confirmDelete}
                  >
                    Yes
                  </button>
                  <button
                    className="bg-gray-300 text-black px-3 py-1 rounded hover:bg-gray-400"
                    onClick={cancelDelete}
                  >
                    No
                  </button>
                </div>
              </div>
            ),
            { autoClose: false, closeOnClick: false, toastId: "delete-toast" }
          );
          
        }
      };
      
    

    const handleSubmit = () => {
        // Check if all products have reason and new_quantity fields updated
        const allFieldsValid = adjustment_lines.every(product => (
            product.reason_id && product.new_quantity !== ''
        ));

        if (!allFieldsValid) {
            alert('Please ensure all products have both reason and new_quantity fields updated.');
            return;
        }

        // Get changed lines
        const cleanedAdjustedQtyLines = adjustment_lines.map(({ product_id, reason_id,old_quantity,new_quantity,...rest }) => {
            return { product_id, reason_id,old_quantity,new_quantity };
        });

        const cleanedOriginalAdjustedQtyLines = original_adjustment_lines.map(({ product_id, reason_id,old_quantity,new_quantity,...rest }) => {
            return { product_id, reason_id,old_quantity,new_quantity };
        });
        
        const adjustedQty =getChangedFields(cleanedOriginalAdjustedQtyLines, cleanedAdjustedQtyLines)

        const qtyAdjustmentToApi = {quantity_adjustment_lines:Object.values(adjustedQty),id: qtyAdjustment.id};

        // Convert to array format
        //const quantityAdjustLinesArray = Object.values(qtyAdjustmentToApi);
        //console.log('qtyAdjustment to api',quantityAdjustLinesArray)
      
        const apiMethod = 'PUT'
        //const qtyAdjustment = { quantity_adjustment_lines: adjustedQty };
        handleQtyAdjustmentApi('quantity_adjustments', qtyAdjustmentToApi, userData, apiMethod)
        //newQtyAdjustment('quantity_adjustments', userData, qtyAdjustment)
        .then(async (data) => {
            console.log('data from product api', data.data.statusText);
            if (data.data.status === 200) {
                toast.success('Product updated Successfully.', data.data.statusText);
                setActionCount(actionCount + 1);
            } else if (data.data.status === 409) {
                toast.error('Product already exists', data.data.statusText);
            } else {
                toast.error('Error saving product.', data.data.statusText);
            }
        })
        .catch(error => {
            toast.error(error.message);
        })
        .finally(() => {
            setActionCount(actionCount + 1);
            setIsLoading(false);
        });
    };
    console.log('products',products)

    return (
        <>
            <div className="">
                <div className="flex">
                    <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Quantity Adjustment</h2>
                </div>
                <div>
                    {isLoading ? (
                        <div className="flex items-center flex-col">
                            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                            <p className="mt-2">Processing data. Please wait...</p>
                        </div>
                    ) : (
                        <>
                            {/* meneu icon */}
                            <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
                                {adjustment_lines.length > 0 && (
                                    <button
                                        className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                        title="Cancel"
                                        onClick={handleDeleteQtyAdjustment}
                                    >
                                        <FcCancel size={20} color={currentColor} />
                                    </button>
                                )}
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Approve"
                                    onClick={handleApproveQtyAdjustment}
                                >
                                    <FcApproval size={20} color={currentColor} />
                                </button>
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Submit"
                                    onClick={handleSubmit}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            </div>
                            {/* end menu icons */}
                            {/* Quantity adjustment  preamble info */}
                            <div className="mb-2 mx-2 md:mx-1  py-2 md:py-2 bg-gray-50 rounded-3xl"> 
                                    <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                                        <div className="flex justify-between">
                                            <p className="whitespace-nowrap text-slate-500 text-xs flex-shrink-0 w-1/3">Adjusted by</p>
                                            <p className="whitespace-nowrap text-slate-500 text-xs flex-1">{qtyAdjustment?.users?.username}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="whitespace-nowrap text-slate-500 text-xs flex-shrink-0 w-1/3">On</p>
                                            <p className="whitespace-nowrap text-slate-500 text-xs flex-1">
                                                {new Date(qtyAdjustment?.adjustment_date).toLocaleDateString('en-GB', {day: 'numeric',
                                                    month: 'short',year: 'numeric',hour: 'numeric',minute: 'numeric',hour12: true})
                                                }
                                            </p>
                                        </div>
                                        <div className="flex">
                                            <p className="whitespace-nowrap text-slate-500 text-xs flex-shrink-0 w-1/3">Approved?</p>
                                            <div
                                                className={`text-xs text-white px-1 rounded-full whitespace-nowrap ${
                                                    qtyAdjustment?.approved ? 'bg-green-500' : 'bg-red-500'
                                                }`}
                                                >
                                                {qtyAdjustment?.approved ? 'Yes' : 'No'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end quantity adjust preamble info */}
                            
                            <div className="mx-2 md:mx-1 py-2 md:py-2 bg-gray-50 rounded-3xl">
                                <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col"> 
                                    <div className="flex justify-between items-center">
                                        <p className="whitespace-nowrap mb-1  text-blue-800">Adjustment Lines</p>
                                        
                                        
                                        
                                        
                                        <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl w-full">
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    placeholder="Search product..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    className="mx-8 mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                    list="allProductsList"
                                                />
                                                <datalist id="allProductsList"
                                                className='px-8'>
                                                    {products.map((product, index) => (
                                                    <option key={index} value={product.name} />
                                                    ))}
                                                </datalist>
                                                <button
                                                    className="ml-8 absolute right-3 top-3 text-blue-500 hover:text-blue-700"
                                                    onClick={handleSearch}
                                                >
                                                    <IoSearchSharp size={20} color={currentColor} />
                                                </button>
                                            </div>
                                        </div>




                                    </div>

                                    <table className="table-auto w-full text-sm rounded-lg">
    <thead>
        <tr className="bg-gray-100 text-sm">
            <th className="p-1"></th>
            <th className="p-1">Product</th>
            <th className="p-1">Package</th>
            <th className="p-1">Old Qty</th>
            <th className="p-1">New Qty</th>
            <th className="p-1">Reason</th>
            <th className="p-1"></th>
        </tr>
    </thead>
    <tbody>
        {currentItems.map((item) => (
            <tr
                key={item.product_id}
                className="hover:bg-gray-300" // Add hover effect class
            >
                <td className="p-2">
                    <input
                        type="checkbox"
                        checked={selectedProducts.has(item.product_id)}
                        onChange={() => handleCheckboxChange(item.product_id)}
                    />
                </td>
                <td className="p-2"> 
                    {item.products && item.products.name ? item.products.name : item.name}
                </td>
                <td className="p-2">
                    {item.products?.prices?.sell_uom_name || item.prices?.sell_uom_name || 'N/A'}
                </td> 
                <td className="p-2 text-center">
                    {item.old_quantity !== null ? item.old_quantity : 0}
                </td>
                <td className="p-2">
                    <input
                        type="number"
                        value={item.new_quantity}
                        onChange={(e) => handleChange(item.product_id, 'new_quantity', e.target.value)}
                        className="w-16 text-center rounded-lg"
                    />
                </td>
                <td className="p-2">
                    <CreatableSelect
                        options={reasons}
                        onChange={(newValue, actionMeta) => handleReasonChange(newValue, actionMeta, item)}
                        value={reasons.find(reason => reason.value === item.reason_id)}
                        placeholder="Select a reason"
                    />
                </td>
                <td className="p-2">
                    <button
                        onClick={() => handleRemoveProduct(item.product_id)}
                        className=""
                    >
                        <FaTrashAlt color="red"/>
                    </button>
                </td>
            </tr>
        ))}

        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
                <div className="my-2 justify-end">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
                        style={{ backgroundColor: currentColor }}
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</table>

                                   
                                    {/* <div className="flex justify-between py-2">
                                        <button
                                            onClick={() => paginate(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className="bg-blue-500 text-white px-4 py-2 rounded"
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={() => paginate(currentPage + 1)}
                                            disabled={indexOfLastItem >= adjustment_lines.length}
                                            className="bg-blue-500 text-white px-4 py-2 rounded"
                                        >
                                            Next
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default EditQtyAdjustment;
