import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdAdd, IoMdMore } from "react-icons/io";
import { FaEdit, FaTrash, FaCheck, FaMoneyBillWave } from 'react-icons/fa';
import { Layout, Header, Modal, EditInvoice, NewPayment } from '../../../../components/';
import { handleApi, formatCurrency, formatFriendlyDate } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // For payment modal
  const [invoiceForPayment, setInvoiceForPayment] = useState(null); // Invoice for payment
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const [menuOpenForInvoiceId, setMenuOpenForInvoiceId] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPurchaseOrders();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const fetchPurchaseOrders = async () => {
    const apiEndpoint = 'purchase/invoices';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData, apiMethod, parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
      if (status === 200) {
        setInvoices(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredInvoices = invoices.filter(po => {
    const partnerName = po?.partners?.name;
    return partnerName && partnerName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInvoices.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleMoreBtnClick = (invoiceId) => {
    setMenuOpenForInvoiceId(menuOpenForInvoiceId === invoiceId ? null : invoiceId);
  };

  const handleMenuItemClick = (action, inv) => {
    setMenuOpenForInvoiceId(null);
    setActionType(action);

    //setInvoice(inv);
     if (action === 'pay') {
      setInvoiceForPayment(inv); // Set the invoice for the payment modal
      setIsPaymentModalOpen(true); // Open the payment modal
     } else {
      setIsModalOpen(true);
      setInvoice(inv);
     
    }
  };

  const handleCloseModal = () => { setIsModalOpen(false); };
  const handleClosePaymentModal = () => { setIsPaymentModalOpen(false); };
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Purchase Invoices" />
        </div>

        {/* Modal for editing an invoice */}
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen  && <EditInvoice pi={invoice} actionType={actionType}onClose={handleCloseModal} />}
        </Modal>

        {/* Modal for making a payment */}
        <Modal open={isPaymentModalOpen} onClose={handleClosePaymentModal} minWidth="90%">
          {isPaymentModalOpen && <NewPayment pi={invoiceForPayment} onClose={handleClosePaymentModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">
              <div className="max-w-lg flex justify-end items-center mb-2 mx-auto">
                <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => paginate(index + 1)}
                      className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                      style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>

                <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                  />
                </div>
              </div>
            </div>

            <div className="w-full bg-white rounded-lg shadow-lg">
              <div className="grid grid-cols-6 gap-4 p-3 border-b">
                <div><span className="text-sm text-slate-400">Reference #</span></div>
                <div><span className="text-sm text-slate-400">Invoice #</span></div>
                <div className="text-center"><span className="text-sm text-slate-400">Invoice Value</span></div>
                <div className="text-center"><span className="text-sm text-slate-400">Customer</span></div>
                <div className="text-center"><span className="text-sm text-slate-400">Status</span></div>
              </div>

              {currentItems.length > 0 ? (
                currentItems.map((inv, index) => (
                  <div key={inv.id} className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200 relative">
                    <div className="text-center">
                      <div className="text-sm text-gray-900">{indexOfFirstItem + index + 1}. {inv?.reference || 'No reference no'}</div>
                      <div className="text-xs text-gray-700">Created: {inv?.date ? new Date(inv.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : ""}</div>
                    </div>
                    <div className="">
                      <div className="text-sm text-gray-900">{inv?.invoice_no || 'No invoice no'}</div>
                      <div className="text-xs text-gray-700">Due on: {inv?.due_date ? new Date(inv.due_date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : ""}</div>
                    </div>
                    <div className="text-center">
                      <span className="text-sm text-gray-900">{formatCurrency(inv?.totalTaxedPrice) || 'N/A'}</span>
                    </div>
                    <div className="text-center">
                      <span className="text-sm text-gray-900">{inv?.partners?.name || 'N/A'}</span>
                    </div>
                    <div className="text-center">
                      <span className={`text-sm text-white py-1 px-3 rounded-full uppercase ${inv?.state === 'draft' ? 'bg-red-500' : 'bg-green-500'}`}>
                        {inv?.state}
                      </span>
                    </div>

                    <div className="text-right relative">
                      <button
                        className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                        onClick={() => handleMoreBtnClick(inv.id)}
                        title="More detail"
                      >
                        <IoMdMore size={20} color={currentColor} />
                      </button>

                      {menuOpenForInvoiceId === inv.id && (
                        <div className="absolute right-0 top-full mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg z-10">
                        <ul className="py-1">
                          <li>
                            <button onClick={() => handleMenuItemClick('edit', inv)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                              <FaEdit className="mr-2" /> Edit
                            </button>
                          </li>
                          <li>
                            <button onClick={() => handleMenuItemClick('cancel', inv)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                              <FaTrash className="mr-2" /> Cancel
                            </button>
                          </li>

                          {/* Show Approve button if the invoice status is not approved */}
                          {inv.state !== 'approved' && (
                            <li>
                              <button onClick={() => handleMenuItemClick('approve', inv)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                <FaCheck className="mr-2" /> Approve
                              </button>
                            </li>
                          )}

                          {/* Show Pay button if the invoice status is approved */}
                          {inv.state === 'approved' && (
                            <li>
                              <button onClick={() => handleMenuItemClick('pay', inv)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                <FaMoneyBillWave className="mr-2" /> Pay
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
            <div className="p-4 text-center text-gray-500">No invoices found</div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Invoices;
