
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CreatableSelect from "react-select/creatable";
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { CiEdit } from 'react-icons/ci';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { getChangedFields,formatCurrency,handleApi, printQuotation, inputDate,formatMediumDate,cleanObject} from '../../../../utils/utils'; 
import { Pie } from '../../../../components';

const NewPayment = ({ pi, onClose }) => {
  console.log('invoice sir ',pi)
  const [payment, setPayment] = useState({
    date: new Date().toISOString().split('T')[0], // Initialize with today's date in YYYY-MM-DD format
    invoice_id :pi.id,
    account_no: '',
    account_name: '',
    bank_name: '',
    bank_branch: '',
    amount: '',
    reference: '',
    method_id: 1,
    state:'draft'
  });

		
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]); // All products in the database for autocomplete fields
    const [lineTotals, setLineTotals]=useState([]);
    const [grandTotal, setGrandTotal]=useState(0);
    const [date, setPaymentDate] = useState(new Date()); // Initializes with current date
    // const [paymentMethods, setPaymentMethods]=useState([]);
    const [account_no, setAccountNo]=useState('');
    const [account_name, setAccountName]=useState('');
    const [bankName, setBankName]=useState('');
    const [bankBranch, setBankBranch]=useState('');
    const [reference, setReference]=useState('');
    const [amount, setAmount]=useState('');
    const [names, setNames] = useState([]);
    const [price, setPrice]=useState(0);
    const [newProductLine, setNewProductLine] = useState(false); // for tracking new product line
    const [hasProductListChanged, setHasProductListChanged] = useState(false); // for tracking new product line
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceDueDate, setInvoiceDueDate] = useState('');
    // const [state, setState] = useState('');
    const [invoiceMode, setInvoiceMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false); // state to manage edit mode
    const [enableInvoiceInput,setEnableInvoiceInput] = useState(false); // state to manage edit mode
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
      // Initialize names based on transactionLines in originalInvoice
      if (Array.isArray(pi.transactionLines)) {
        const initialNames = pi.transactionLines.map(line => line.products.name || '');
        setNames(initialNames);
      }
      const { id,invoice_no, date, due_date ,state} = pi; // Destructure the fields
      
      setInvoiceNo(pi?.invoice_no || '');
      setInvoiceDate(pi?.date|| '');
      setInvoiceDueDate(pi?.due_date|| '');
      //  fetchCustomers();
      //  fetchProducts();
  }, [pi]);

    useEffect(() => {
      // Check if transactionLines is defined and is an array before mapping
      if (Array.isArray(pi.transactionLines)) {
          const newLineTotals = pi.transactionLines.map(line => {
              const price = parseFloat(line.unit_price) || 0;
              const qty = parseFloat(line.quantity) || 1;
              const discount_percentage = parseFloat(line.discount_percentage) || 0;
              const discount_amount = (price * discount_percentage) / 100;
              return (price * qty) - discount_amount;
          });

          setLineTotals(newLineTotals);

          // Calculate grandTotal
          const total = newLineTotals.reduce((total, lineTotal) => total + lineTotal, 0);
          setGrandTotal(total);
      }
  }, [pi.transactionLines]);

  const paymentMethods = [
    { value: 0, label: "..." },
    { value: "check", label: "Cheque" },
    { value: "bank_transfer", label: "Bank Transfer" },
    { value: "airtel_money", label: "Airtel Money" },
    { value: "tnm_mpamba", label: "TNM Mpamba" },
  ];

  // const fetchPaymentMethods = async () => {
  //   const apiEndpoint = 'payments/methods';
  //   const apiMethod = 'GET';
  //   const cleanedData = {};
  //   const parameters = {};
   
  //   try {
  //     const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
  //     if (response.error) {
  //       toast.error(`Error fetching report: ${response.errorMessage}`);
  //       return;
  //     }

  //     const { status, statusText, data } = response;
     
  //     if (status === 200) {
  //       setPaymentMethods(data);
  //     } else {
  //       toast.error(`Error ${status}: ${statusText}`);
  //     }
  //   } catch (error) {
  //     toast.error(error.message || 'An unexpected error occurred.');
  //   } finally {

  //   }
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value, // Update the specific field in the payment object
    }));
  };

  // const handlePayMethodChange = (selectedOption) => {
  //   setPayment((prevPayment) => ({
  //     ...prevPayment,
  //     paymentMethod: selectedOption, // Update the payment method
  //   }));
  // };

  
    
  

  const handleSubmit = async () => {
    const apiEndpoint = 'purchase/payments';
    const parameters = {};
    const apiMethod = 'POST';
  
    // Define required fields
    const requiredFields = [
      'date',
      'method_id',
      'amount',
    ];

   	 	
  
    // Check if all required fields are filled
    for (const field of requiredFields) {
      if (!payment[field]) {
        toast.error(`Please fill in the required field: ${field}`);
        return; // Exit the function if a required field is missing
      }
    }

    const cleanedPayment = cleanObject(payment);
  console.log('cleaned payment',cleanedPayment)
    try {
      const response = await handleApi(apiEndpoint, cleanedPayment, userData, apiMethod, parameters);
      if (response.status === 200) {
        toast.success('Invoice updated successfully.');
        // onClose(); // Close the modal if the response is successful
      } else {
        toast.error('Error saving invoice.', response.statusText);
      }
      return response; // Return the response for checking
    } catch (error) {
      toast.error('Error saving invoice: ' + error.message);
      return null; // Return null in case of error
    } finally {
      setActionCount(actionCount + 1);
      setIsLoading(false);
    }
  };
  




    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your invoice');
            formData.append('text', 'Please find the attached invoice.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'invoiceST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };

   

    
    const handlePrint = () => {
        const updatedinvoice = { ...pi, companyProfile };
        printQuotation(updatedinvoice);
    };

    const handleDownload = async () => {
        // Generate the PDF document
        const blob = await pdf(<MyDocument invoice={pi} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'invoice.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument invoice={pi} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'invoice.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };

   
  

  const handleCancel = () => {
       // resetForm()
   };

    const handleClose = () => {
        navigate(-1);
    };


    // const total = (invoice.transactionLines?.reduce((total, product) => total + product.total, 0) || 0).toFixed(2);
   // const total = grandTotal || 0;
    const shouldShowProductLinesHeader = pi?.date && pi?.customer_id;
    const userRole = userData?.data?.user_roles?.name?.toLowerCase();
    const userDepartment = (userData?.data?.employees?.departments?.name || '').toLowerCase();
    
    const allowedRoles = ['officer', 'admin', 'executive', 'supervisor', 'manager']; // roles allowed to edit 
    const allowedDepartments = ['procurement']; // departments allowed to edit 
    
    const isRoleAllowed = userRole && allowedRoles.includes(userRole);
    const isDepartmentAllowed = userDepartment && allowedDepartments.includes(userDepartment);
    
    // Updated isUserAllowed logic
    const isUserAllowed = (isRoleAllowed && isDepartmentAllowed) || ['admin', 'executive'].includes(userRole);
    const isOfficerAllowed= (isDepartmentAllowed && userRole.toLowerCase()  ==='officer'&& pi?.state.toLowerCase() ==='draft');
  
    return (
      <>
        <div className="px-4">
          <div className="flex flex-col items-center">
            <h2 className="text-blue-700 text-xl font-bold border-b-2 border-blue-200 pb-2">New Payment</h2>
          </div>
          <div>
            {isLoading ? (
              <div className="flex items-center flex-col">
                <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                <p className="mt-2">Processing data. Please wait...</p>
              </div>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-center py-2">
                  {(isUserAllowed || isOfficerAllowed) && !invoiceMode && pi.state !== 'invoiced' && pi.state !== 'cancelled' && (
                    <button
                      className="bg-transparent hover:bg-red-400 hover:text-white py-1 px-2 rounded transition-all duration-300 mb-2 sm:mb-0"
                      title="Cancel"
                    >
                      <FcCancel size={20} color={currentColor} />
                    </button>
                  )}
    
                  {pi?.transactionLines && grandTotal > 0 && pi.state === 'draft' && (
                    <button
                      className="bg-transparent hover:bg-red-400 hover:text-white text-red-700 py-1 px-2 rounded transition-all duration-300 mb-2 sm:mb-0"
                      title="Approve"
                    >
                      <FcApproval size={20} color={currentColor} />
                    </button>
                  )}
    
                  <button
                    className="bg-transparent hover:bg-red-400 hover:text-white text-red-700 py-1 px-2 rounded transition-all duration-300 mb-2 sm:mb-0"
                    title="Submit Invoice"
                  >
                    <BsFillSendArrowUpFill size={20} color="blue" />
                  </button>
                </div>
    
                <div className="flex flex-col md:flex-row mb-4">
                <div id="invoice-preamble" className="mb-4 border border-gray-100 py-2 bg-gray-50 rounded-3xl md:w-1/3">
                <p className="text-gray-800 text-sm mb-2 font-semibold text-center uppercase">Supplier Info</p>
                  <div className="flex flex-col gap-2 px-4">
                    {[
                      { label: "Name", value: pi?.partners?.name || 'NA' },
                      { label: "Telephone", value: pi?.partners?.phone_number || 'NA' },
                      { label: "Contact Person", value: pi?.partners?.contact_person || 'NA' },
                    ].map((item, index) => (
                      <div key={index} className="flex-1 min-w-[150px] mb-2">
                        <p className="text-blue-500 text-xs">{item.label}</p>
                        <p className="text-slate-500 text-xs font-semibold uppercase">{item.value}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div id="invoice-info" className="md:w-2/3 border border-gray-100 py-2 bg-gray-50 rounded-3xl md:ml-4">
                  <p className="text-gray-800 text-sm font-semibold text-center uppercase">Invoice Info</p>
                  <div className="px-4 flex flex-col">
                    {[
                      { label: "Invoice #.", value: pi?.invoice_no || 'NA' },
                      { label: "Date #.", value: formatMediumDate(pi?.date) || 'NA' },
                      { label: "Internal Reference #.", value: pi?.reference || 'NA' },
                      { label: "Total Bill #", value: pi?.totalUntaxedPrice || 'NA' },
                      { label: "Discount", value: pi?.totalDiscount || 'NA' },
                      { label: "Tax", value: pi?.totalTax || 'NA' },
                      { label: "Total", value: formatCurrency(pi?.totalTaxedPrice) || 'NA' },
                    ].map((item, index) => (
                      <div key={index} className="flex justify-between mb-2">
                        <p className="text-blue-500 text-xs">{item.label}</p>
                        <p className="text-slate-500 text-xs font-semibold uppercase">{item.value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row mb-4 mx-2 items-start">
                <div id="payment-info" className="md:w-full border border-gray-100 py-2 bg-gray-50 rounded-3xl">
                  <p className="text-gray-800 text-sm font-semibold text-center uppercase">Payment Info</p>
                  <div className="flex flex-col">
                    {[
                      { label: "Date.", type: "date", name: "date", value: inputDate(payment?.date) },
                      { label: "Payment Method", type: "select", name: "paymentMethod", options: paymentMethods },
                      { label: "Account No..", type: "text", name: "account_no", value: payment.account_no },
                      { label: "Account Name", type: "text", name: "account_name", value: payment.account_name },
                      { label: "Bank.", type: "text", name: "bank_name", value: payment.bank_name },
                      { label: "Branch #", type: "text", name: "bank_branch", value: payment.bank_branch },
                      { label: "Amount.", type: "text", name: "amount", value: payment.amount },
                      { label: "Reference", type: "text", name: "reference", value: payment.reference },
                    ].map((field, index) => (
                      <div key={index} className="flex justify-between mb-2 mx-2">
                        <div className="flex-1 min-w-[150px]">
                          <p className="text-blue-500 text-xs">{field.label}</p>
                          {field.type === "select" ? (
                            <CreatableSelect
                                options={field.options}
                                name={field.name}
                                // onChange={handlePayMethodChange}
                                styles={{
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: 'rgba(0, 0, 0, 0.5)',
                                  }),
                                }}
                              />
                            ) : (
                              <input
                                name={field.name}
                                type={field.type}
                                value={field.value}
                                onChange={handleChange}
                                className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-end mt-4">
                      <button className="bg-blue-500 text-white py-2 px-4 rounded-xl flex items-center" onClick={handleSubmit}>
                        <span className="mr-2">Pay Now</span>
                        <svg viewBox="0 0 16 16" className="bi bi-cart2" fill="currentColor" height="16" width="16">
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </>
    );
};

export default NewPayment;
