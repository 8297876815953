import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdMore } from "react-icons/io";
import { FaEdit, FaTrash, FaCheck, FaMoneyBillWave } from 'react-icons/fa';
import { Layout, Header, Modal, EditPayment } from '../../../../components/';
import { handleApi, formatCurrency } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [menuOpen, setMenuOpen] = useState(null);
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPayments();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when searchTerm changes
  }, [searchTerm]);

  const fetchPayments = async () => {
    const apiEndpoint = 'purchase/payments';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData, apiMethod, parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;

      if (status === 200) {
        console.log('payments from api', data);
        setPayments(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearchTerm = useMemo(() => debounce(handleSearchChange, 300), []);

  const filteredPayments = useMemo(() => {
    if (!searchTerm) {
      return payments; // Return original payments if searchTerm is empty
    }
    return payments.filter(pay =>
      pay?.partners?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [payments, searchTerm]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPayments.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredPayments.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleMenuToggle = (id) => {
    setMenuOpen(menuOpen === id ? null : id); // Toggle menu visibility
  };

  const handleMenuItemClick = (action, pay) => {
    setPayment(pay);
    setActionType(action);
    setIsModalOpen(true);
    setMenuOpen(null); // Close the menu after selecting an action
  };

  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Payments" />
        </div>
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && <EditPayment pay={payment} actionType={actionType} onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">
              <div className="max-w-lg flex justify-end items-center mb-2 mx-auto">
                <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => paginate(index + 1)}
                      className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                      style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
                <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={debouncedSearchTerm}
                    className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                  />
                </div>
              </div>

              <div className="w-full bg-white rounded-lg shadow-lg">
                <div className="grid grid-cols-6 gap-4 p-3 border-b">
                  <div className="">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Payment No. #</span>
                  </div>
                  <div className="">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Invoice #</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Amount</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Customer</span>
                  </div>
                  <div className="text-center">
                    <span className="text-sm text-slate-400 whitespace-nowrap">Status</span>
                  </div>
                </div>

                {currentItems.length > 0 ? (
                  currentItems.map((pay, index) => (
                    <div 
                      key={pay.id}
                      className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200"
                    >
                      <div className="">
                        <div className="text-sm text-gray-900">{indexOfFirstItem + index + 1}. {pay?.reference || 'N/A'}</div>
                        <div className="text-xs text-gray-700">Created: {pay?.date ? new Date(pay.date).toLocaleDateString('en-US', 
                            { month: 'short', day: 'numeric', year: 'numeric' }) : ""}</div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-900">{pay?.p_invoices?.reference || 'N/A'}</div>
                      </div>
                      <div className="">
                        <span className="text-sm text-right text-gray-900">{formatCurrency(pay?.amount) || 'N/A'}</span>
                      </div>
                      <div className="">
                        <span className="text-sm text-gray-900">{pay?.partners?.name || 'N/A'}</span>
                      </div>
                      <div className="">
                        <span className={`text-sm text-white py-1 px-3 rounded-full uppercase ${pay?.state === 'draft' ? 'bg-red-500' : 'bg-green-500'}`}>
                          {pay?.state}
                        </span>
                      </div>
                      <div className="relative text-right">
                        <button
                          onClick={() => handleMenuToggle(pay.id)}
                          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap ml-2"
                          title="Actions"
                        >
                          <IoMdMore size={20} color={currentColor} />
                        </button>
                        {menuOpen === pay.id && ( // Show menu only for the open item
                          <div className="absolute right-0 top-full mt-2 w-32 bg-white border border-gray-200 rounded shadow-lg z-10">
                            <ul className="py-1">
                              <li>
                                <button onClick={() => handleMenuItemClick('edit', pay)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                  <FaEdit className="mr-2" /> Edit
                                </button>
                              </li>
                              <li>
                                <button onClick={() => handleMenuItemClick('cancel', pay)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                  <FaTrash className="mr-2" /> Cancel
                                </button>
                              </li>
                              {pay.state !== 'approved' && (
                                <li>
                                  <button onClick={() => handleMenuItemClick('approve', pay)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                    <FaCheck className="mr-2" /> Approve
                                  </button>
                                </li>
                              )}
                              {pay.state === 'approved' && (
                                <li>
                                  <button onClick={() => handleMenuItemClick('pay', pay)} className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center">
                                    <FaMoneyBillWave className="mr-2" /> Pay
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="p-3 text-center">No purchase Invoices available.</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Payments;
