import React, { useEffect } from 'react';

function TabBar({ activeTab, setActiveTab, tabNames }) {
  // If tabNames prop is not provided, use default values
  const defaultTabs = [
    { name: 'CREATE', key: 'create' },
    { name: 'LIST', key: 'list' },
  ];

  // Use tabNames prop if provided, otherwise use default tabs
  const tabs = tabNames || defaultTabs;

  // Set the active tab to the first tab key when the component mounts
  useEffect(() => {
    setActiveTab(tabs[0].key);
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="mb-2 w-full">
      <div className="flex justify-start"> {/* Align tabs to the left */}
        {tabs.map(tab => (
          <div
            key={tab.key}
            className={`py-2 px-4 cursor-pointer ${activeTab === tab.key ? 'text-blue-500 border-b-2 border-blue-500 font-extrabold' : 'text-gray-700 font-semibold'}`} // Increased font weight for both active and inactive tabs
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TabBar;
