import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { getRegions, getCities} from '../../../../data/LocationService';
import { updateSupplier} from '../utils/api';

const EditSupplier = ({rowData, onClose }) => {
    const { userData } = useContext(AppContext);
    const [supplier, setSupplier] = useState({});
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [isCompany, setIsCompany] = useState(false); // State to track company/individual
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSupplier(rowData)
        fetchCountries();
        setIsCompany(rowData?.is_company === 1); // Initialize isCompany based on supplier's is_company field
    }, [rowData]);
    
   

    const fetchCountries = async () => {
        await fetchData('countries', userData, setCountries);
        setIsLoading(false);
    };

    const fetchRegions = (id) => {
        getRegions('regions', id,userData) // Fetch PO from api
        .then(data => {
          // Flatten the array of arrays into a single array
          const flattenedRegions = data.result.flat();
          console.log('Fetched regions:', flattenedRegions);
          setRegions(flattenedRegions);
        })
        .catch(error => {
            //setError('Failed to fetch data. Please try again later.');
        })
          .finally(() => {setIsLoading(false);});
    }

    const fetchDistricts = (id) => {
        //const id = Id <= 0 ? 133 : countryId;
        getCities('districts', id,userData) // Fetch PO from api
        .then(data => {   
          // Flatten the array of arrays into a single array
          const flattenedDistricts = data.result.flat();
          console.log('Fetched regions:', flattenedDistricts);
          setDistricts(flattenedDistricts);
        })
        .catch(error => {
            // setError('Failed to fetch data. Please try again later.');
        })
        .finally(() => {setIsLoading(false);});
      }

      const handleToggle = () => {
        setIsCompany(prevIsCompany => !prevIsCompany);
        // Update supplier's is_company field based on toggle state
        setSupplier(prevSupplier => ({
            ...prevSupplier,
            is_company: isCompany ? 0 : 1, // Assuming 1 for company, 0 for individual
        }));
    };


      const handleChange = (event) => {
        const { name, value } = event.target;
        setSupplier(prevSupplier => {const updatedSupplier = { ...prevSupplier };
          if (name === "country") {
            updatedSupplier.countries.name = value;
            const selectedCountry = countries.find((country) => country.name === value);
            if (selectedCountry) {
              updatedSupplier.country_id= selectedCountry.id;
              fetchRegions(selectedCountry.id);
            } 
          } else if (name === "region") {
            updatedSupplier.regions.name = value;
            const selectedRegion = regions.find((region) => region.name === value);
            if (selectedRegion ){
              updatedSupplier.region_id= selectedRegion.id;
              fetchDistricts(selectedRegion.id);
            } 
          } else if (name === "district") {
            updatedSupplier.districts.name = value;
            const selectedDistrict = districts.find((district) => district.name === value);
            if (selectedDistrict ){
              updatedSupplier.district_id= selectedDistrict.id;
            } 
          } else {updatedSupplier[name] = value;} 
          return updatedSupplier;
        });
      };
    
      
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!supplier.name) {toast.error('Please enter first name ');return;  }
        if (!supplier.phone_number) {toast.error('Please enter telephone number ');return;  }
        if (!supplier.email) {toast.error('Please enter telephone number ');return;  }
        try {
            const response = await ( updateSupplier('supplier', userData,supplier))
            toast.success('supplier  Updated  Successfully.');
          } catch (err) {toast.error('Error submitting form'); }
    };
console.log('parent supplier',supplier)
    return (
        <div className="">
          <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center bsupplier-b-2 bsupplier-blue-200 pb-2">Edit Supplier</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="min-w-400 mx-auto">
       
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="">
                <div className="flex justify-end">
                 <label htmlFor="Toggle1" className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-800">
                            <span className="text-gray-500">Individual</span>
                            <span className={`relative ${isCompany ? 'peer-checked:right-0 peer-checked:left-auto' : ''}`}>
                                <input
                                    id="Toggle1"
                                    type="checkbox"
                                    className="hidden peer"
                                    onChange={handleToggle}
                                    checked={isCompany}
                                />
                                <div className="w-10 h-6 rounded-full shadow-inner bg-gray-600 peer-checked:bg-violet-600 dark:bg-gray-600 dark:peer-checked:dark:bg-violet-600"></div>
                                <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-gray-100 dark:bg-gray-100"></div>
                            </span>
                            <span className="text-gray-500">Company</span>
                        </label>
                        </div>
                    <form className="shadow-md rounded px-8 pt-4 mb-2">
                        
                           <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none
                                dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none 
                                focus:ring-0 focus:bsupplier-blue-600 peer" 
                                placeholder=" "  
                                name="name"
                                onChange={(event) => handleChange(event)}
                                value={supplier?.name}
                            />
                            
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Supplier Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none
                                dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none 
                                focus:ring-0 focus:bsupplier-blue-600 peer" 
                                placeholder=" "  
                                name="contact_person"
                                onChange={(event) => handleChange(event)}
                                value={supplier?.contact_person}
                            />
                            
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contact Person
                            </label>
                        </div>

                        

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="phone_number"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleChange(event)}
                                value={supplier?.phone_number}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="phone_number"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Telephone
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="email"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleChange(event)}
                                value={supplier?.email}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email
                            </label>
                        </div>

              

              
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="country"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                list="countryList" // Link the input to the datalist
                                onChange={(event) => handleChange(event)}
                                value={supplier?.countries?.name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <datalist id="countryList">{countries && countries.map((country) => (<option value={country.name} />))}</datalist> 
                            <label
                                htmlFor="country"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Country
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="region"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                list="regionList" // Link the input to the datalist
                                onChange={(event) => handleChange(event)}
                                value={supplier?.regions?.name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <datalist id="regionList">{regions && regions.map((region) => (<option value={region.name} />))}</datalist> 
                            <label
                                htmlFor="region"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Region
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="district"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                list="districtList" // Link the input to the datalist
                                onChange={(event) => handleChange(event)}
                                value={supplier?.districts?.name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <datalist id="districtList">{districts && districts.map((district) => (<option value={district.name} />))}</datalist> 
                            <label
                                htmlFor="district"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                City
                            </label>
                        </div>

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none
                                dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none 
                                focus:ring-0 focus:bsupplier-blue-600 peer" 
                                placeholder=" "  
                                name="suburb"
                                onChange={(event) => handleChange(event)}
                                value={supplier?.suburb}
                            />
                            
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Suburb
                            </label>
                        </div>

                        
              

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none
                                dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none 
                                focus:ring-0 focus:bsupplier-blue-600 peer" 
                                placeholder=" "  
                                name="street_name"
                                onChange={(event) => handleChange(event)}
                                value={supplier?.street_name}
                            />
                            
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Street Address
                            </label>
                        </div>

                        
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none
                                dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none 
                                focus:ring-0 focus:bsupplier-blue-600 peer" 
                                placeholder=" "  
                                name="plot_number"
                                onChange={(event) => handleChange(event)}
                                value={supplier?.plot_number}
                            />
                            
                            <label htmlFor="plot_number" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Plot number
                            </label>
                        </div>
              
              
              
              
              


                        

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="create_date"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleChange(event)}
                                key={supplier?.id} // Assuming 'id' is a unique identifier
                                value={supplier?.create_date}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="create_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Supplier Since
                            </label>
                        </div>

                        

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="gps_link"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent bsupplier-0 bsupplier-b-2 bsupplier-gray-300 appearance-none dark:text-gray-500 dark:bsupplier-gray-600 dark:focus:bsupplier-blue-500 focus:outline-none focus:ring-0 focus:bsupplier-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleChange(event)}
                      
                                value={supplier?.gps_link}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="create_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                GPS link
                            </label>
                        </div>


                       
                     

                    <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditSupplier;
