// utils.js

import axios from 'axios';
import API_BASE_URL from '../config';
import { formatDistanceToNow } from 'date-fns';

export const formatShortDateTime = (date) => { // format dte to dd-mm-yyyy:hh-mm
  const now = new Date(date); // Ensure the date is a Date object
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${day}-${month}-${year}:${hours}:${minutes}`;
};

export const formatMediumDate = (isoDateString) => {
  // Check if the input is a valid string and not empty
  if (typeof isoDateString !== 'string' || isoDateString.trim() === '') {
    console.warn('Invalid date string: must be a non-empty string');
    return ''; // or handle it however you prefer
  }

  const date = new Date(isoDateString);

  // Validate the Date object
  if (isNaN(date.getTime())) {
    console.warn('Invalid Date object for input:', isoDateString);
    return ''; // or handle it however you prefer
  }

  // Define the date format options
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short', // Sep
    day: 'numeric', // 12
    year: 'numeric', // 2024
  });

  return dateFormatter.format(date);
};



export const formatLongDateTime = (isoDateString) => {// Convert ISO date string like  Sep 8, 10:44 PM
  const date = new Date(isoDateString);

  // Validate the Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {throw new Error('Invalid Date object');}

  // Define the date and time format options
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return dateFormatter.format(date);
};
export const inputDate = (timestamp) => { // format date for input
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const formatFriendlyDate = dateStr => {
  const formattedDate = formatDistanceToNow(new Date(dateStr), { addSuffix: true });
  return formattedDate.replace(/^about\s/, ''); // Remove "about" if present
};

export const formatCurrency=(amount, currencyCode = 'MKW') =>{ //Formats a number as a currency string.
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount);
}
export const formatNumber = (value) => { // convert to number
  const number = parseFloat(value);
  return isNaN(number) ? 0 : number;
};

export  const  formatToTwoDecimalPlaces = (value) => {  // Adjust number of decimal places
  if (typeof value === 'number' && !isNaN(value)) {
    return value.toFixed(2); // Adjust the number of decimal places as needed
  }
  return 'N/A'; 
};

export const getFirstAndLastDateOfMonth = () => { // first and last date of the current month
  const now = new Date(); // Get the current date
  const firstDate = new Date(now.getFullYear(), now.getMonth(), 1); // First date of the current month
  const lastDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last date of the current month
  // Format the dates as 'YYYY-MM-DD'
  const startDate = firstDate.toISOString().split('T')[0];
  const endDate = lastDate.toISOString().split('T')[0];
  return { startDate, endDate };
};

export const normalizeDate = (date) => { // takes care of server / client time zone differences
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0); // Set time to midnight to ignore time during comparison
  return normalized;
};

 export const  getFirstAndLastDayOfWeek=(weekStart = 'sunday')=> { // first and last date of current week
  const today = new Date();
  const dayOfWeek = today.getDay();

  // Adjust for week start on Sunday (index 0)
  const offset = (dayOfWeek + 7 - (weekStart === 'sunday' ? 0 : 1)) % 7;

  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - offset);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
 


  return { 
    firstDayOfWeek: normalizeDate(firstDayOfWeek), 
    lastDayOfWeek: normalizeDate(lastDayOfWeek) 
  };
 
}

export const  formatDateToMonth = (dateString) => { // return array of short months
  const date = new Date(dateString);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[date.getMonth()];
}


export const cleanObject = (obj) => { // clean object b4 sending to api. Make sure it does not contain empty items
    if (obj === null || obj === undefined) {return null;}
    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        return obj
          .map(cleanObject)
          .filter(item => item !== null && item !== undefined && item !== '');
      } else {
        const cleanedObj = Object.fromEntries(
          Object.entries(obj)
              .map(([key, value]) => [key, cleanObject(value)])
              .filter(([key, value]) => value !== null && value !== undefined && value !== '')
        );
        return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
      }
    }
    return obj;
};

export const getChangedFields = (original, updated) => { // get only the fields that have changed
  console.log('original',original)
  debugger;
  const changedFields = {};
      for (const key in updated) {
        if (updated.hasOwnProperty(key) && original[key] !== updated[key]) {changedFields[key] = updated[key];}
      }
      if (original.id) {changedFields.id = original.id; }   // Include id if it exists in original
      return changedFields;
    };

export const deepEqual = (a, b) => { // check if original object is the same as current object
  if (typeof a !== typeof b) return false;
  if (a === b) return true;
  if (typeof a !== 'object' || a === null || b === null) return false;
  if (Array.isArray(a) !== Array.isArray(b)) return false;

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  
  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!deepEqual(a[key], b[key])) return false;
  }

  return true;
};

export const createOrder = (employee, cart,immediatePayment,billId) => { //create order  using items from the cart just before submitting the order 
  if (!employee) {return console.error('No employee selected');}
  const now = new Date();
  const shortName = `${employee.first_name.charAt(0)}${employee.last_name}-${formatShortDateTime(now)}`; // Concatenates the first letter of the first name with the last name
  const updatedOrder = {
    bill_id:billId,
    employee_id: employee.id,
    name: shortName,
    state: 'confirmed',
    transactionLines: cart.map(({ id, quantity }) => ({product_id: id,quantity: quantity,})),
    immediatePayment:immediatePayment,
  };
  return cleanObject(updatedOrder);
};
export const generateReceiptHTML = (data, companyProfile,status) => {

  if (!data || !data.billOrders || data.billOrders.length === 0) {
    return '<html><head><title>No Data Available</title></head><body><h1>No Receipt Data Available</h1><p>Please check back later.</p></body></html>';
  }

  let html = '<html><head><title>Print Bill</title>';
  html += '<style>';
  html += 'body{font-family: Courier, monospace; font-size: 10px; width: 100%; margin: 0; padding: 0; text-align: center; position: relative;}';
  html += 'h1{font-size: 14px;}';
  html += 'h2{font-size: 12px; margin: 5px 0;}';
  html += 'h3{font-size: 11px; margin: 0;}';
  html += 'table{border-collapse: collapse; padding: 4px; width: auto; margin: 0 auto; position: relative;}';
  html += 'th, td{border: 0.5px dashed #000; padding: 4px; text-align: left; font-size: 10px; position: relative;}';
  html += 'th{text-align: center; font-weight: bold;}';
  html += 'tr:nth-child(even){background: #f0f0f0;}';
  html += 'p{font-size: 10px; margin: 5px 0;}';
  html += '.divider{border-top: 0.5px dashed #000; margin: 10px 0;}';
  html += '.status{background-color: #f8d7da; color: #721c24; font-weight: bold; padding: 10px; margin: 10px 0;}';
  html += '.watermark-container{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; pointer-events: none; display: flex; align-items: center; justify-content: center;}';
  html += '.watermark{font-size: 50px; color: rgba(255, 0, 0, 0.2); transform: rotate(-45deg);}';
  html += 'td.product{max-width: 20em; overflow: hidden; text-overflow: ellipsis;}';
  html += '</style>';
  html += '</head><body>';

  // Add Company Info
  html += '<h1>' + (companyProfile?.name || '') + '</h1>'; // Company name
  html += '<p>' + (companyProfile?.physical_address || '') + '<br>' + (companyProfile?.city || '') + ', ' + (companyProfile?.country || '') + '<br>Phone: ' + (companyProfile?.phone_number || '') + '</p>'; // Replace with actual address

  // Add Bill Header
  html += '<h2>' + (status ? 'Customer Receipt' : 'Customer Bill') + '</h2>';

  html += '<p>Sales Person: ' + (data.employees?.first_name || '') + ' ' + (data.employees?.last_name || '') + '<br>' +
          'Bill #: ' + (data.reference || '') + '<br>' +
          'Date: ' + new Date(data.date).toLocaleString() + '<br>' +
          'Amount Due: ' + formatCurrency(data.billOrders.reduce((acc, order) => acc + order.totalTaxedPrice, 0)) + '</p>';

  // Initialize aggregates
  let totalUntaxedPrice = 0;
  let totalTax = 0;
  let totalTaxedPrice = 0;


  // Loop through each order
  data.billOrders.forEach((order, orderIndex) => {
      let orderTotal = 0; // Initialize order total

      // Add Order Header
      html += '<h3>Order ' + (orderIndex + 1) + '</h3>';
      html += '<table>'; // Start order table

      // Add Watermark container inside the table
      html += '<div class="watermark-container">';
      if (!data.paid) {
          html += '<div class="watermark">UNPAID</div>'; // Watermark for unpaid bills
      } else {
          html += '<div class="watermark">PAID</div>'; // Watermark for paid bills
      }
      html += '</div>'; 

      html += '<thead>';
      html += '<tr><th>Product</th><th>Qty</th><th>Total</th></tr>'; // Remove Unit Price column
      html += '</thead>';
      html += '<tbody>';

      // Check if transactionLines exists
      if (order.transactionLines) {
          // Add Order Details
          order.transactionLines.forEach(line => {
              const unitPrice = parseFloat(line.unit_price); // Convert to number
              const total = parseFloat(line.line_total_taxed); // Convert to number
              
              html += '<tr>';
              html += '<td class="product">' + (line.products?.name || 'Unknown Product') + ' @ ' + formatCurrency(unitPrice) + '</td>';
              html += '<td style="text-align: center;">' + parseFloat(line.quantity).toFixed(2) + '</td>';
              html += '<td style="text-align: right;">' + formatCurrency(total) + '</td>';
              html += '</tr>';

              orderTotal += total; // Add to order total
          });
      } else {
          html += '<tr><td colspan="3">No transaction lines available</td></tr>';
      }

      html += '</tbody>';
      html += '</table>';

      // Print Order Total as part of the order section
      html += '<p><strong>Total for Order ' + (orderIndex + 1) + ': ' + formatCurrency(orderTotal) + '</strong></p>';

      // Update aggregates
      totalUntaxedPrice += order.totalUntaxedPrice || 0;
      totalTax += order.totalTax || 0;
      totalTaxedPrice += order.totalTaxedPrice || 0;
  });

  // Add Footer with the divider below the entire bill
  html += '<br><p><strong>Total Untaxed: ' + formatCurrency(totalUntaxedPrice) + '</strong></p>';
  html += '<p><strong>Total Tax: ' + formatCurrency(totalTax) + '</strong></p>';
  html += '<p><strong>Total Amount Due: ' + formatCurrency(totalTaxedPrice) + '</strong></p>';
  html += '<br><p>Thank you for your purchase!</p>';
  html += '<div class="divider"></div>';
  html += '</body></html>';
  
  return html;
};

export const handlePrint = (printData,companyProfile,status) => {

  const printWindow = window.open('', '', 'height=600,width=400'); // Adjust width as needed
  const receiptHTML = generateReceiptHTML(printData,companyProfile,status);
  printWindow.document.write(receiptHTML);
  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
};

export const generateQuotationHtml = (quotation) => {
  const productRows = quotation.products.map((product, index) => (
    `<tr>
        <td>${index + 1}</td>
        <td>${product.name || 'N/A'}</td>
        <td>${formatNumber(product.price).toFixed(2)}</td>
        <td>${formatNumber(product.qty).toFixed(2)}</td>
        <td>${formatNumber(product.discount).toFixed(2)}</td>
        <td class="total">${formatNumber(product.total).toFixed(2)}</td>
    </tr>`
  )).join('');

  const totalAmount = formatNumber(quotation.products.reduce((total, product) => total + product.total, 0)).toFixed(2);

  return `
    <html>
    <head>
    <style>
    /* Include the styles you have for the HTML content */
    body {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin: 0;
      padding: 0;
      color: #333;
      background-color: #f4f4f4;
    }
    .container {
      width: 800px;
      margin: 0 auto;
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
    /* Print Styles */
    @media print {
      body {
        background-color: #fff;
      }
      .container {
        width: 100%;
        margin: 0;
        padding: 10px;
        box-shadow: none;
      }
      table {
        width: 100%;
        font-size: 10px;
      }
      .footer {
        page-break-before: always;
        text-align: center;
        font-size: 10px;
      }
      .no-print {
        display: none;
      }
    }
    /* General print styles */
    .invoice-header, .invoice-info, .payment-and-totals {
      margin-bottom: 20px;
    }
    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #4CAF50;
      color: white;
      border-radius: 8px;
    }
    .invoice-info {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
    .customer-info {
      text-align: right;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    table th, table td {
      border: 1px solid #ddd;
      padding: 8px;
      font-size: 10px;
    }
    table th {
      background-color: #4CAF50;
      color: white;
      text-align: left;
    }
    table td.total {
      text-align: right;
    }
    .payment-and-totals {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .payment-info, .labels-section, .totals-section {
      font-size: 12px;
    }
    .footer {
      margin-top: 20px;
    }
    </style>
    </head>
    <body>
    <div class="container">
      <!-- Invoice Header with Logo and Info -->
      <div class="invoice-header">
        <div class="company-info">
          <img src="${quotation.companyProfile.photoUrl}" alt="Company Logo">
          <ul>
            <li><strong>${quotation.companyProfile.name}</strong></li>
            <li>${quotation.companyProfile.physical_address}</li>
            <li>${quotation.companyProfile.city} ${quotation.companyProfile.country}</li>
            <li>Email: ${quotation.companyProfile.email}</li>
            <li>Telephone: ${quotation.companyProfile.phone_number}</li>
          </ul>
        </div>
        <div>
          <h1>INVOICE</h1>
        </div>
      </div>

      <!-- Invoice and Customer Information -->
      <div class="invoice-info">
        <div>
          <p><strong>INVOICE NO: ${quotation.invoiceNumber}</strong></p>
          <p>Date: ${quotation.date}</p>
        </div>
        <div class="customer-info">
          <p><strong>Invoice To:</strong></p>
          <p id="customerName">${quotation.customer.name}</p>
          <p>${quotation.customer.physical_address}</p>
          <p>${quotation.customer.city}, ${quotation?.customer?.countries?.name}</p>
          <p>${quotation.customer.phone_number}</p>
        </div>
      </div>

      <!-- Products Table -->
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Discount (%)</th>
            <th class="total">Total</th>
          </tr>
        </thead>
        <tbody>
        ${productRows}
        </tbody>
      </table>

      <!-- Payment Info and Totals -->
      <div class="payment-and-totals">
        <div class="payment-info">
          <p><strong>Payment Info:</strong></p>
          <p>Bank: XYZ Bank</p>
          <p>Account Name: ABC Corp</p>
          <p>Account Number: 987654321</p>
        </div>

        <div class="labels-section">
          <p><strong>SUB TOTAL</strong></p>
          <p><strong>TAX (10%):</strong> </p>
          <p><strong>GRAND TOTAL:</strong> </p>
        </div>

        <div class="totals-section">
          <p><strong>MK${totalAmount}</strong></p>
          <p><strong>MK${(totalAmount * 0.1).toFixed(2)}</strong></p>
          <p><strong>MK${(totalAmount * 1.1).toFixed(2)}</strong></p>
        </div>
      </div>

      <div class="payment-and-totals">
        <div class="terms-info">
          <p><strong>Terms and Conditions:</strong></p>
          <p>Payment is due within 30 days of the invoice date.</p>
          <p>Late payments will incur a 5% fee.</p>
        </div>

        <div>
          <div class="terms-info">
            <p><strong>Thank you for your business!</strong></p>
            <p>For any inquiries, contact us at <a href="mailto:${quotation?.companyProfile?.email}">${quotation?.companyProfile?.email}</a></p>
          </div>
        </div>
      </div>
      
      <!-- Footer -->
      <div class="footer">
        <!-- Footer content here -->
      </div>
    </div>
    </body>
    </html>
  `;
};

 export const printQuotation = (quotation) => {


  const printWindow = window.open('', '', 'height=800,width=1200');
  if (!printWindow) {
      console.error('Failed to open print window.');
      return;
  }

  const productRows = quotation.products.map((product, index) => (
      `<tr>
          <td>${index + 1}</td>
          <td>${product.name || 'N/A'}</td>
          <td>${formatNumber(product.price).toFixed(2)}</td>
          <td>${formatNumber(product.qty).toFixed(2)}</td>
          <td>${formatNumber(product.discount).toFixed(2)}</td>
          <td class="total">${formatNumber(product.total).toFixed(2)}</td>
      </tr>`
  )).join('');

  const totalAmount = formatNumber(quotation.products.reduce((total, product) => total + product.total, 0)).toFixed(2);

  printWindow.document.write(`
      <html>
      <head>
      <style>
/* General Styles */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    background-color: #f4f4f4;
}

.container {
  width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* Print Styles */
@media print {
    body {
        background-color: #fff;
    }

    .container {
        width: 100%;
        margin: 0;
        padding: 10px;
        box-shadow: none;
    }

    /* Prevent page breaks inside container */
    .container, .invoice-header, .invoice-info, .payment-and-totals {
        page-break-inside: avoid;
    }

    /* Force table to fit */
    table {
        width: 100%;
        font-size: 10px; /* Reduce font size for better fit */
    }

    /* Ensure headers and footers are not cut off */
    .footer {
        page-break-before: always;
        text-align: center;
        font-size: 10px;
    }

    /* Hide elements that are not needed in print */
    .no-print {
        display: none;
    }
}

/* General print styles */
.invoice-header, .invoice-info, .payment-and-totals {
    margin-bottom: 20px;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background-color: #4CAF50;
    color: white;
    border-radius: 8px;
}

.invoice-info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.customer-info {
    text-align: right;
}

table {
    border-collapse: collapse;
    width: 100%;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 10px; /* Smaller font size for better fit */
}

table th {
    background-color: #4CAF50;
    color: white;
    text-align: left;
}

table td.total {
    text-align: right;
}

.payment-and-totals {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.payment-info, .labels-section, .totals-section {
    font-size: 12px;
}

.footer {
    margin-top: 20px;
}
</style>

      </head>
      <body>
      <div class="container">
        <!-- Invoice Header with Logo and Info -->
        <div class="invoice-header">
          <div class="company-info">
              <img src="${quotation.companyProfile.photoUrl}" alt="Company Logo">
              <ul>
                  <li><strong>${quotation.companyProfile.name}</strong></li>
                  <li>${quotation.companyProfile.physical_address}</li>
                  <li>${quotation.companyProfile.city} ${quotation.companyProfile.country}</li>
                  <li>Email: ${quotation.companyProfile.email}</li>
                  <li>Telephone: ${quotation.companyProfile.phone_number}</li>
              </ul>
          </div>
        <div>
              <h1>INVOICE</h1>
          </div>
      </div>

      <!-- Invoice and Customer Information -->
      <div class="invoice-info">
          <div>
              <p><strong>INVOICE NO: ${quotation.invoiceNumber}</strong></p>
              <p>Date: ${quotation.date}</p>
          </div>
          <div class="customer-info">
              <p><strong>Invoice To:</strong></p>
              <p id="customerName">${quotation.customer.name}</p>
              <p>${quotation.customer.physical_address}</p>
              <p>${quotation.customer.city}, ${quotation?.customer?.countries?.name}</p>
              <p>${quotation.customer.phone_number}</p>
          </div>
      </div>

      <!-- Products Table -->
      <table>
          <thead>
              <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Discount (%)</th>
                  <th class="total">Total</th> <!-- Ensure the class is applied here -->
              </tr>
          </thead>
          <tbody>
          ${productRows}
          </tbody>
      </table>

      <!-- Payment Info and Totals -->
      <div class="payment-and-totals">
          <div class="payment-info">
              <p><strong>Payment Info:</strong></p>
              <p>Bank: XYZ Bank</p>
              <p>Account Name: ABC Corp</p>
              <p>Account Number: 987654321</p>
          </div>

          <div class="labels-section">
              <p><strong>SUB TOTAL</strong></p>
              <p><strong>TAX (10%):</strong> </p>
              <p><strong>GRAND TOTAL:</strong> </p>
          </div>

          <div class="totals-section">
              <p><strong>MK${totalAmount}</strong></p>
              <p><strong>MK${(totalAmount * 0.1).toFixed(2)}</strong></p>
              <p><strong>MK${(totalAmount * 1.1).toFixed(2)}</strong></p>
          </div>
      </div>

      <div class="payment-and-totals">
          <div class="terms-info">
              <p><strong>Terms and Conditions:</strong></p>
              <p>Payment is due within 30 days of the invoice date.</p>
              <p>Late payments will incur a 5% fee.</p>
          </div>

          <div>
            <div class="terms-info">
              <p><strong>Thank you for your business!</strong></p>
              <p>For any inquiries, contact us at <a href="mailto:${quotation?.companyProfile?.email}">${quotation?.companyProfile?.email}</a></p>

            <div class="terms-info">
          </div>
      </div>
      
      <!-- Footer -->
      <div class="footer">
          <!-- Footer content here -->
      </div>
      
      </div>
  
      <script>
          window.print();
          window.onafterprint = function() { window.close(); };
      </script>
      </body>
      </html>
  `);
};




  export const handleApi = async (page, changedFields, userData, apiMethod, parameters) => { // connect to api for CRUD operations 

 if(apiMethod==='PUT'){
    //  console.log('submitted order:',page, changedFields, userData, apiMethod);
 }
    const config = {
      headers: {  Authorization: userData && userData.data && userData.data.token 
        ? `Bearer ${userData.data.token}` : '' },
      // params:{startDate:'2024-09-01',endDate:'2024-09-20'}
      // params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
      params: parameters ? { ...parameters } : {}, // Spread `changedFields` to flatten its properties
    };
    // console.log('params',config)
  
    try {
      let response;
  
      switch (apiMethod) {
        case 'PUT':
          response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);
          break;
        case 'POST':
       
          response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config);
          break;
        case 'DELETE':
          response = await axios.delete(`${API_BASE_URL}/${page}`, config);
          break;
        case 'GET': // New case for fetching data
          // Merge any additional query parameters in `changedFields` with `config.params`
         // config.params = { ...config.params, ...parameters };
          
          response = await axios.get(`${API_BASE_URL}/${page}`, config);
          break;
        default:
          throw new Error('Unsupported method');
      }
      // console.log('resonse sir',response)
      return response; // Return the full response object
    } catch (err) {
      console.error(`Error handling API request:`, err);
      throw err; // Rethrow the actual error
    }
  };
  