import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FcCancel } from "react-icons/fc";
import { CiEdit } from "react-icons/ci";
import { BsFillSendFill } from "react-icons/bs";
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { getRegions, getCities} from '../../../../data/LocationService';
import { getChangedFields, handleApi} from '../../../../utils/utils'; 

const EditCustomer = ({ data, onClose }) => {
    const { userData,actionCount,setActionCount,currentColor } = useContext(AppContext);
    const [customer, setCustomer] = useState({});
    const [originalCustomer, setOriginalCustomer] = useState({});
    const [name, setName] = useState('');
    const [contact_person, setContact_person] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [district_id, setDistrict_id] = useState('');
    const [suburb, setSuburb] = useState('');
    const [street_name, setStreet_name] = useState('');
    const [plot_number, setPlot_number] = useState('');
    const [customerSince, setCustomerSince] = useState('');
    const [is_company, setIs_company] = useState(true);
    const [countries, setCountries] = useState([]);
   
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [action, setAction] = useState('');
    const [isEditable, setIsEditable] = useState(false); // Add state to manage edit mode
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (data) {
            console.log('data', data);
            setCustomer(data);
            setOriginalCustomer(data);
            fetchCountries();
            setName(data?.name || '');
            setContact_person(data?.contact_person || '');
            setPhone_number(data?.phone_number || '');
            setEmail(data?.email || '');
            setCountry(data?.countries?.name || '');
            setRegion(data?.regions?.name || '');
            setDistrict_id(data?.districts?.name || '');
            setSuburb(data?.suburb || '');
            setStreet_name(data?.street_name || '');
            setPlot_number(data?.plot_number || '');
            setCustomerSince(data?.customerSince || '');
            //setIs_company(data?.is_company || true);
            setIs_company(data?.is_company === 1);
        
            
        }
    }, []);

   

    useEffect(() => { // to update is_company field
        setCustomer(prevCustomer => ({...prevCustomer,is_company: is_company ? 1: 0}));
    }, [is_company]);

    useEffect(() => { // to update action ie either 'edit' or 'delete' 
        if (action) {handleSubmit();}
    }, [action]);

    const fetchCountries = async () => {
        await fetchData('countries', userData, setCountries);
        setIsLoading(false);
    };

    const fetchRegions = (id) => {
        getRegions('regions', id,userData) // Fetch PO from api
        .then(data => {
          // Flatten the array of arrays into a single array
          const flattenedRegions = data.result.flat();
          console.log('Fetched regions:', flattenedRegions);
          setRegions(flattenedRegions);
        })
        .catch(error => {
            //setError('Failed to fetch data. Please try again later.');
        })
          .finally(() => {setIsLoading(false);});
    }

    const fetchCities = (id) => {
        //const id = Id <= 0 ? 133 : countryId;
        getCities('districts', id,userData) // Fetch PO from api
        .then(data => {   
          // Flatten the array of arrays into a single array
          const flattenedDistricts = data.result.flat();
          console.log('Fetched regions:', flattenedDistricts);
          setCities(flattenedDistricts);
        })
        .catch(error => {
            // setError('Failed to fetch data. Please try again later.');
        })
        .finally(() => {setIsLoading(false);});
      }

    
    const resetForm = () => {
        const updatedCustomer = {};
        setCustomer(updatedCustomer);
        setName('');
        setContact_person('');
        setEmail('');
        setPhone_number('');
        setCustomerSince('');
        setCountry('');
        setRegion('');
        setDistrict_id('');
        setSuburb('');
        setStreet_name('');
        setPlot_number('');
 
       

    };

    // Handling input changes
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'country':
                setCountry(value);
                const selectedCountry = countries.find(country=> country.name === value);
                if (selectedCountry) {
                    setCustomer(prevCustomer => ({...prevCustomer,country_id: selectedCountry.id}));
                    fetchRegions(selectedCountry.id)
                }
                break;
            case 'region':
                setRegion(value);
                const selectedRegion = regions.find(region => region.name === value);
                if (selectedRegion) {
                    setCustomer(prevCustomer => ({...prevCustomer,region_id: selectedRegion.id}));
                    fetchCities(selectedRegion.id)
                }
                break;
        
            case 'district_id':
                setDistrict_id(value);
                const selectedDistrict_id = cities.find(district_id => district_id.name === value);
                if (selectedDistrict_id) {setCustomer(prevCustomer => ({...prevCustomer,district_id_id: selectedDistrict_id.id}));
                    fetchCities(selectedDistrict_id.id)
                }
                break;
            case 'name':
                setName(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'contact_person':
                setContact_person(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'phone_number':
                setPhone_number(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'email':
                setEmail(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'suburb':
                setSuburb(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'street_name':
                setStreet_name(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'plot_number':
                setPlot_number(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'customerSince':
                setCustomerSince(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
            case 'is_compaany':
                setIs_company(value);
                setCustomer(prevCustomer => ({...prevCustomer,[name]: value}));
                break;
        }
    };
    

    
    
    
    const handleCancel = () => {resetForm();};
    
    const handleDelete = async () => {
        const confirmed = window.confirm('Are you sure you want to delete this customer?');
        if (confirmed) {setAction('delete');}
    };
    const handleEdit = () => {setAction('put');};
    
    const handleSubmit = async (e) => {
        if (e) e.preventDefault(); // Only prevent default if an event is provided
    
        // Validation
        if (!customer?.name) { toast.error('Please enter customer name'); return; }
        if (!customer?.phone_number) { toast.error('Please enter telephone number'); return; }
    
        // Extract only changed fields
        const changedFields = getChangedFields(originalCustomer, customer);
        console.log('cleaned customer', changedFields);
    
        const apiMethod = action === 'delete' ? 'DELETE' : 'PUT';
        console.log('apiMethod', apiMethod);
        setIsLoading(true);
    
        try {
            const response = await handleApi('customers', changedFields, userData, apiMethod);
            console.log('API Response:', response);
    
            if (response.status === 200) {
                toast.success(action === 'delete' ? 'Customer deleted successfully.' : 'Customer updated successfully.');
                setActionCount(actionCount + 1);
                if (action === 'delete') {
                    onClose(); // Close the component if delete was successful
                }
            } else if (response.status === 409) {
                toast.error('Customer already exists.');
            } else {
                toast.error('Error saving Customer.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving Customer: ' + error.message);
        } finally {
            
            setActionCount(actionCount + 1); // Trigger reload of products list
            setIsLoading(false);
        }
    };
    
    
        

     console.log('is compny at this poin',customer.is_company)
    const isFormValid = (name || '').trim() !== '' && (phone_number || '').trim() !== '';

    const isUserAuthorized = 
    (userData?.data.employee?.department?.name?.toLowerCase() === 'sales' ||
    userData?.data.role?.role?.toLowerCase() === 'executive' ||
    userData?.data.role?.role?.toLowerCase() === 'admin');
    return (
        <div className="">
          <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Customer</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opadistrict_id-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
               <div className="w-full min-w-[400px]">
                    {/* menu icon */}
                    <div className="w-full sm:w-auto sm: px-4 py-2 flex flex-row justify-end">
                        {isUserAuthorized && (
                            <>
                                <button
                                    className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? 'hidden' : ''}`}
                                    title="Edit"
                                    onClick={() => setIsEditable(true)}
                                >
                                    <CiEdit size={20} color="blue" />
                                </button>
                                <button
                                    className={`bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                    title="Delete"
                                    onClick={handleDelete}
                                >
                                    <FcCancel size={20} color="blue" />
                                        </button>
                                    </>
                                )}
                                <button
                                    className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                    title="Save"
                                    onClick={handleEdit}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            </div>
                            {/* end menu icons */}

                            <form onSubmit={handleSubmit} className="shadow-md rounded px-8 pt-4 mb-2">
                                {/* Customer type */}
                                <div className="flex justify-end mb-4">
                                    <label htmlFor="is_company" className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-800">
                                        <span className="text-gray-500">Individual</span>
                                        <span className="relative">
                                            <input
                                                id="is_company"
                                                name="is_company"
                                                type="checkbox"
                                                className="hidden peer"
                                                checked={is_company}  // Bind checkbox state with is_company
                                                onChange={(event) => setIs_company(event.target.checked)} // Update state on change
                                                disabled={!isEditable} // Conditionally disable input
                                            />
                                            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-600 peer-checked:bg-violet-600 dark:bg-gray-600 dark:peer-checked:dark:bg-violet-600"></div>
                                            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-gray-100 dark:bg-gray-100"></div>
                                </span>
                                <span className="text-gray-500">Company</span>
                            </label>
                        </div>
                        {/* Customer type */}

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="name"
                                onChange={(event) => handleTextChange(event)}
                                value={name}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            <label htmlFor="name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Customer Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="contact_person"
                                onChange={(event) => handleTextChange(event)}
                                value={contact_person}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Contact Person
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="phone_number"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                               // key={product?.category_id} // Assuming 'id' is a unique identifier
                                value={phone_number}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                            Telephone
                                </label>
                                
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                type="text"
                                name="email"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={email}
                                disabled={!isEditable} // Conditionally disable input
                                />
                            
                                <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                            Email
                                </label>
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                    type="text"
                                    name="country"
                                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    list="countryList" // Link the input to the datalist
                                    onChange={(event) => handleTextChange(event)}
                                    value={country}
                                    disabled={!isEditable} // Conditionally disable input
                                />
                                {/* Use the datalist element for autocomplete */}
                                <datalist id="countryList">{countries && countries.map((country) => (
                                    <option value={country.name} key={country.id || country.name} />
                                     ))}
                                </datalist>

                                <label
                                htmlFor="country"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Country
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="region"
                                    onChange={(event) => handleTextChange(event)}
                                    value={region}
                                    disabled={!isEditable} // Conditionally disable input
                                    list="regionList" // Link the input to the datalist
                                />
                                {/* Use the datalist element for autocomplete */}
                                <datalist id="regionList">{regions && regions.map((region) => (<option value={region.name} />))}</datalist> 
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Region
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="district_id"
                                    onChange={(event) => handleTextChange(event)}
                                    value={district_id}
                                    disabled={!isEditable} // Conditionally disable input
                                    list="citiesList"
                                />
                                {/* Use the datalist element for autocomplete */}
                                <datalist id="citiesList">{cities && cities.map((district_id) => (<option value={district_id.name} />))}</datalist> 
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">District
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="suburb"
                                    onChange={(event) => handleTextChange(event)}
                                    value={suburb}
                                    disabled={!isEditable} // Conditionally disable input
                                />
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Suburb
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="street_name"
                                    onChange={(event) => handleTextChange(event)}
                                    value={street_name}
                                    disabled={!isEditable} // Conditionally disable input
                                />
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Street
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="plot_number"
                                    onChange={(event) => handleTextChange(event)}
                                    value={plot_number}
                                    disabled={!isEditable} // Conditionally disable input
                                />
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Plot Number
                                </label>
                            </div>

                            

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="customerSince"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                //key={} // Assuming 'id' is a unique identifier
                                value={customerSince}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="customerSince"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Customer Since
                            </label>
                        </div>

                        <div className="text-center">
                        {isFormValid && isEditable && (
                            <button
                                type="submit"
                                className="px-4 mb-2 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            
                            >
                                Save
                            </button>
                        )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditCustomer;
