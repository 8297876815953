// ProductService.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';



export const getProductsByCategory = async (page, categoryId, userData) => {

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { category_id:categoryId, with_photos:1}, // query parameters ,  with_photos:1
  };
  try {
    //console.log(page, productId, userData);
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
    //console.log('API Response sir :', data); // Log the data
    console.log('from api response sir ',response.data)
    return {
      data: response.data 
    };

  } catch (error) {
    console.error('Error fetching products :', error);
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch product details. Please try again later.',
    };
  }
};
  export const getProduct = async (page, productId, userData) => {
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id:productId, with_photos:1}, // Send productId as a query parameter
    };
    try {
      //console.log(page, productId, userData);
      const response = await axios.get(`${API_BASE_URL}/${page}`, config);
      console.log('from api response sir ',response.data)
    return {
      data: response.data 
    };

    } catch (error) {
      console.error('Error fetching product details:', error);
      return {
        count: 0,
        result: null,
        error: 'Failed to fetch product details. Please try again later.',
      };
    }
  }; 
  
  export const addProduct = async (page, userData, product) => {
    console.log('product in api',product);
    try {
      const response = await fetch(`${API_BASE_URL}/${page}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.data.token}`
        },
        body: JSON.stringify({
          name:product.name,
          barcode:product.barcode,
          category_id:product.category_id,
          reorder_quantity:product.reorder_qty,
          prices:{buy_price_new:product.cost_price,buy_uom_id:product.buy_uom_id,
            sell_uom_id:product.sell_uom_id,sell_price_new:product.sell_price
          },
          photo: {data:product.displayImg,contentType:product.imgType},
        })
        
        });

        console.log('from api response sir ',response)
    return {
      data: response 
    };


        } catch (err) {
            console.error('Error adding product:', err);
            throw new Error('Failed to add product category'); // Rethrow a custom error message to be handled by the calling code
        }
};

export const handleProductApi = async (page, changedFields, userData, apiMethod) => {
  console.log('product in product api ', changedFields);
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
  };
  try {
    let response;
    switch (apiMethod) { //// determine which end point to use
      case 'PUT': response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);break;
      case 'POST': response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config); break;
      case 'DELETE': response = await axios.delete(`${API_BASE_URL}/${page}`, config); break;
      default:throw new Error('Unsupported method');
    }
    console.log('from api response sir ', response);
    return {data: response};// Return response data
  } catch (err) {
    console.error(`Error handling company profile:`, err);
    throw new Error('Failed to handle company profile'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateProduct = async (page, userData,product) => {
  console.log('product in api',product.buy_price_new)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: product.id }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:product.id,
      name: product.name,
      barcode: product.barcode,
      category_id: product.category_id,
      reorder_quantity: product.reorder_quantity,
      prices:{id:product.prices.id,buy_price_new:product.buy_price,buy_uom_id:product.buy_uom_id,
        sell_uom_id:product.sell_uom_id,sell_price_new:product.sell_price,
      },
      photo: { data: product.displayImg, contentType: product.imgType },
    }, config);
   // console.log('from api response sir ',prices)
    return {
      data: response
    };


    } 
    catch (err) {
        console.error('Error adding product:', err);
       // throw new Error('Failed to add product '); // Rethrow a custom error message to be handled by the calling code
    }
};




export const updateProductQty = async (page, productData, userData) => {

  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
     params: { id: productData.id }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productData.id,
      quantity_instock: productData.quantity_instock,
    }, config);
    console.log('from api response sir ',response.data)
    return {
      data: response.data 
    };

    // const data = await response.json();
  } catch (err) {
    console.error('Error updating product:', err);
    throw new Error('Failed to update product Quantity'); // Rethrow a custom error message to be handled by the calling code
  }
};





export const deleteProduct= async (page, userData, product) => {
  console.log('deleteing ',userData.data.token)
  const config = {
  headers: { Authorization: `Bearer ${userData.data.token}` },
  params: { id: product.id }, // Send productId as a query parameter
};

try {
  const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
  // Check if the response indicates success (you may adjust this based on your API's response structure)
  console.log('from api response sir ',response.data)
    return {
      data: response
    };

} catch (err) {
  console.error('Error deleting product:', err);
  throw new Error('Failed to delete product');
}
};




