import React, { useState, useEffect, useContext } from 'react';
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { Layout, Header, Button, Modal, NewSupplier, EditSupplier } from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  getSuppliers, deleteSupplier } from '../utils/api';

const Suppliers= () => {
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor } = useContext(AppContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

    // Filter suppliers based on search term
    const filteredSuppliers = suppliers.filter(supplier =>
      supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSuppliers.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    fetchSuppliers();
  }, []);

  useEffect(() => {
    fetchSuppliers();
}, []); 

const fetchSuppliers = async () => {
  await fetchData('suppliers', userData, setSuppliers);
  setIsLoading(false);
};



  const handleAction = (action,supplier) => {
    setActionType(action);
    setSupplier(supplier);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-8 max-w-lg mx-auto">
          <Header supplier="" title=" Suppliers" color={currentColor} />
        </div>
        
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewSupplier data={suppliers} onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditSupplier rowData={supplier}  onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col ">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (

          <>
          <div className="max-w-lg mx-auto">
        <button
          className="rounded-lg mb-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
          style={{ backgroundColor: currentColor }}
          onClick={() => handleAction('new', {})}
          type="button"
        >
          Add Supplier
        </button>
        </div>
        {/* Search Input */}
        <div className="max-w-lg mx-auto">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
        </div>
        
        {/* Pagination */}
        <div className="mt-3 flex mb-4 max-w-lg mx-auto">
          {Array.from({ length: Math.ceil(filteredSuppliers.length / itemsPerPage) }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
              style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
            >
              {index + 1}
            </button>
          ))}
        </div>
        
        <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg">
          {/* Column Header */}
          <div className="flex justify-between p-3 border-b">
            <div className="flex items-center">
              <span className="text-sm text-gray-600"> Supplier</span>
              <span className="ml-2 text-xs text-gray-600"></span>
            </div>
           
              <span className="text-sm text-gray-600"> City</span>
              <span className="text-sm text-gray-600"> Telephone</span>
            
            <span className="text-sm text-blue-400">Actions</span>
          </div>
  
          {/* List of Items */}
          {currentItems.map(supplier => (
            <div key={supplier.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
           
                <div className="ml-2 flex flex-col">
                  <div className="leading-snug text-sm text-gray-900 font-bold">{supplier?.name}</div>
                  <div className="leading-snug text-xs text-gray-600">
                    {supplier?.is_company === 1 ? 'Company' : 'Individual'}
                  </div>
                </div>
           
              <div className="leading-snug text-sm text-gray-900 font-bold">{supplier?.districts?.name}</div>
              <div className="leading-snug text-sm text-gray-900 font-bold">{supplier?.phone_number}</div>
              
            
            
              <button 
                className="h-6 px-2 text-sm font-bold text-blue-400 rounded-full hover:bg-blue-100"
                onClick={() => handleAction('edit', supplier)}
              >
                <CiEdit size={20}/>
              </button>
            </div>
          ))}
        </div>
        
        {/* Pagination */}
        <div className="mt-3 flex mb-4 max-w-lg mx-auto">
          {Array.from({ length: Math.ceil(filteredSuppliers.length / itemsPerPage) }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
              style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
            >
              {index + 1}
            </button>
          ))}
        </div>
          
          </>
          
        )}

        
      </div>
    </Layout>
  );
  };

export default Suppliers;
