import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { cleanObject, handleApi, printQuotation, generatePOHtml,formatCurrency} from '../../../../utils/utils'; 

const NewPurchaseOrder = ({ onClose }) => {
    const [PO, setPO] = useState({ transactionLines: [] });
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState({});
    const [supplierName, setSupplierName] = useState('');
    const [ContactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [price, setPrice] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSuppliers();
        fetchProducts();
    }, []);

    useEffect(() => {
        fetchSuppliers();
        fetchProducts();
    }, []);


    const initialPOState = {
        transactionLines: [],
        date: '',
        partner_id: null,
        supplierName:'',
        // add other fields as necessary
    };

    const fetchSuppliers = async () => {
        const apiEndpoint = 'suppliers';
        const apiMethod = 'GET';
        const cleanedData = {};
        const parameters = {};
        setIsLoading(true);
        try {
          const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
          if (response.error) {
            toast.error(`Error fetching report: ${response.errorMessage}`);
            return;
          }
    
          const { status, statusText, data } = response;
         
          if (status === 200) {
            setSuppliers(data);
          } else {
            toast.error(`Error ${status}: ${statusText}`);
          }
        } catch (error) {
          toast.error(error.message || 'An unexpected error occurred.');
        } finally {
          setIsLoading(false);
        }
      };
   

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {
            console.error('userData or token is undefined');
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setPO(prevPO => {
            const updatedPO = { ...prevPO };
            switch (name) {
                case 'supplierName':
                    setSupplierName(value);
                    const selectedSupplier = suppliers.find((supplier) => supplier.name === value);
                    if (selectedSupplier) {
                        // updatedPO.supplier = selectedSupplier;  // Add the entire supplier object
                        updatedPO.partner_id = selectedSupplier.id;
                        setSupplier(selectedSupplier);
                        setPhone(selectedSupplier.phone_number);
                        setContactPerson(selectedSupplier.contact_person);
                        if (!updatedPO.transactionLines) {
                            updatedPO.transactionLines = [];
                        }
                        if (!updatedPO.transactionLines.length && updatedPO.date) {
                            updatedPO.transactionLines.push({ 
                                product_id: 0, 
                                unit_price: 0, 
                                quantity: 1, 
                                discount_percentage: 0, 
                                total: 0 
                            });
                        }
                    } else {
                        toast.error('Supplier does not exist');
                        setSupplierName('');
                        setPhone('');
                        setContactPerson('');
                    }
                    break;

                case 'date':
                    updatedPO[name] = value;

                    if (updatedPO.partner_id && !updatedPO.transactionLines.length) {
                        updatedPO.transactionLines.push({ product_id:0, unit_price: 0,  quantity: 1, discount_percentage: 0, total: 0  });
                    }
                    break;

                default:
                    break;
            }
            return updatedPO;
        });
    };

    const handleProductChange = (index, field, value) => {
        setPO(prevPO => {
            const updatedProducts = prevPO.transactionLines.map((product, i) => {
                if (i === index) {
                    const updatedProduct = { ...product, [field]: value };
                    // Update the product's price and discount if the name is changed
                    if (field === 'name') {
                        const selectedProduct = products.find(p => p.name === value);
                        if (selectedProduct) {
                            updatedProduct.product_id= selectedProduct.id;
                            updatedProduct.unit_price = selectedProduct.prices.buy_price_new;
                            updatedProduct.discount_percentage = selectedProduct.discount_percentage || 0;
                        } else {
                            updatedProduct.unit_price = 0;
                            updatedProduct.discount_percentage = 0;
                        }
                    } else if (field === 'unit_price') {
                        updatedProduct.unit_price = parseFloat(value) || 0;
                    }
    
                    // Ensure quantity is parsed as well
                    const qty = parseFloat(updatedProduct.quantity) || 1;
                    const discountPercentage = parseFloat(updatedProduct.discount_percentage) || 0;
    
                    // Calculate the discount amount and total
                    const discountAmount = (updatedProduct.unit_price * discountPercentage) / 100;
                    updatedProduct.total = (updatedProduct.unit_price * qty) - discountAmount;
    
                    return updatedProduct;
                }
                return product;
            });
    
            // Check for duplicate product
            const isProductDuplicate = updatedProducts.some((prod, i) => 
                prod.product_id === updatedProducts[index].product_id && i !== index
            );
    
            if (!isProductDuplicate) {
                // Add a new product line if the current product line is filled and total is greater than 0
                const lastProduct = updatedProducts[updatedProducts.length - 1];
                if (lastProduct && lastProduct.product_id && lastProduct.total > 0) {
                    updatedProducts.push({ product_id:0, unit_price: 0, discount_percentage: 0, quantity: 1,  });
                }
            } else {  toast.error('Product is already in PO.');  }
    
            return { ...prevPO, transactionLines: updatedProducts };
        });
    };
    
    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your PO');
            formData.append('text', 'Please find the attached PO.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };
    
    
    
   /* const printPO = (PO) => {
        const printWindow = window.open('', '', 'height=800,width=1200');
        if (!printWindow) {
          console.error('Failed to open print window.');
          return;
        }
      
        const htmlContent = generatePOHtml(PO);
        printWindow.document.write(htmlContent);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
      }; */
      

    const handlePrint =  () => {
        const updatedPO = { ...PO, companyProfile};
        printQuotation(updatedPO)
       
    }

    const handleDownload = async () => {
        //const PO = getPOData(); // Replace with your actual data-fetching logic
        // Generate the PDF document
        const blob = await pdf(<MyDocument PO={PO} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'PO.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument PO={PO} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'PO.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };
    // const handleSaveBtnClick =()=>{

    // }


    const handleSubmit = async (e) => {
        e.preventDefault();    
        const cleaned = cleanObject(PO);
        // Create a new cleanedPO without total and name fields
            const cleanedPO = {
                ...cleaned,
                transactionLines: cleaned.transactionLines.map(({ total, name, ...rest }) => rest) // Exclude total and name
            };

        console.log('Cleaned PO:', cleanedPO);
   
        setPO(initialPOState);
        console.log(cleanedPO,'cleanedPO')
        setIsLoading(true);
        const apiMethod = 'POST';
        const parameters = {};
        try {
            const response = await handleApi('purchase/orders', cleanedPO, userData, apiMethod,parameters);
            console.log('API Response:', response); // Log the response
    
            if (response.status === 200) {
                toast.success('PO added  successfully.');
                setActionCount(actionCount + 1);
               //resetForm();
            } else if (response.status === 409) {
                toast.error('Purchase Order already exists.');
            } else {
                toast.error('Error saving Purchase Order.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving PO: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
       // resetForm()
   ;
    };

    const handleClose = () => {
        navigate(-1);
    };

    const shouldShowProductLinesHeader = PO.date && PO.partner_id;
    const isTotalGreaterThanZero = PO.transactionLines.reduce((total, product) => total + product.total, 0) > 0;
    // Determine if the role is not Officer, Admin, or Public
    // Normalize the role to lowercase for comparison
    const userRole = userData?.data?.role?.role?.toLowerCase();
    const allowedRoles = ['officer', 'admin', 'public'];  // Use lowercase for comparison

    // Determine if the role is not one of the allowed roles
    const isRoleAllowed = userRole && !allowedRoles.includes(userRole);

    const grandTotal=PO.transactionLines.reduce((total, product) => total + product.total, 0)

    return (
        <>
            <div className="">
                <div className="flex">
                    <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Purchase Order</h2>
                </div>
                <div>
                    {isLoading ? (
                        <div className="flex items-center flex-col">
                            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                            <p className="mt-2">Processing. Please wait...</p>
                        </div>
                    ) : (
                        <>
                            {/* menu icons  */}
                            <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:px-4 py-2 flex flex-row justify-end">
                            {/* Conditionally render the Cancel button */}
                            {PO.transactionLines.length > 0 && isTotalGreaterThanZero && (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Cancel"
                                    onClick={handleCancel}
                                >
                                    <FcCancel size={20} color={currentColor} />
                                </button>
                            )}

                            

                            {PO.transactionLines.length > 0 && isTotalGreaterThanZero && isRoleAllowed && (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Approve"
                                >
                                    <FcApproval size={20} color={currentColor} />
                                </button>
                            )}
                            {PO.transactionLines.length > 0 && isTotalGreaterThanZero &&  (
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Print"
                                    onClick={handlePrint}
                                >
                                    <MdPayment size={20} color={currentColor} />
                                </button>
                            )}

                    <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Email"
                                    onClick={handleDownload}    
                                    
   
                                >
                                    <IoMdDownload  size={20} color={currentColor} />
                                </button>

                           
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Email"
                                    onClick={handleEmail}    
                                    
   
                                >
                                    <MdAttachEmail size={20} color={currentColor} />
                                </button>

                            {PO.transactionLines.length > 0 && isTotalGreaterThanZero && (
                            
                                <button
                                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                    title="Submit"
                                    onClick={handleSubmit}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            )}
                            </div>


                            <div className="mb-2 mx-2 md:mx-1 py-2 md:py-2 bg-gray-100 rounded-3xl">
                                <div className="w-full px-4 flex flex-col">
                                    <div className="flex items-center mb-2">
                                        <p className="whitespace-nowrap text-slate-500 w-24">Date</p>
                                        <div className="flex-1 p-1 px-4 rounded-3xl">
                                            <input
                                                type="date"
                                                name="date"
                                                value={PO.date || ''}
                                                onChange={handleTextChange}
                                                className="mt-1 px-4 py-1 w-full rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center mb-2">
                                        <p className="whitespace-nowrap text-slate-500 w-24">Supplier</p>
                                        <div className="flex-1 p-1 px-4 rounded-2xl">
                                            <input
                                                type="text"
                                                name="supplierName"
                                                value={supplierName}
                                                onChange={handleTextChange}
                                                className="px-2 py-1 w-full rounded-lg border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                list="suppliersList"
                                            />
                                            <datalist id="suppliersList">
                                                {suppliers && suppliers.map((supplier, index) => (
                                                    <option key={index} value={supplier.name} />
                                                ))}
                                            </datalist>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>

                            {shouldShowProductLinesHeader && (
                                <>
                                    <div className="flex justify-center items-center">
                                        <p className="whitespace-nowrap mb-1 text-slate-500 text-center">Product Lines</p>
                                    </div>

                                    <div className="mx-2 md:mx-1 py-0 md:py-0 bg-blue-500 rounded-3xl">
                                        <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                                            {/* Table Header */}
                                            <div className="flex items-center font-semibold mb-2 text-white">
                                                <div className="w-1/12 text-center"></div>
                                                <div className="w-1/3">Product Name</div>
                                                <div className="w-1/6 text-right mx-2">Price</div>
                                                <div className="w-1/6 mx-2">Quantity</div>
                                                <div className="w-1/6 mx-2">Discount</div>
                                                <div className="w-1/6 text-right">Total</div>
                                            </div>

                                            <div className="space-y-2">
                                                {PO.transactionLines.map((product, index) => (
                                                    <div key={index} className="flex items-center mb-2 text-white">
                                                        <div className="w-1/12 text-center font-semibold">{index + 1}</div> 
                                                        <input
                                                            type="text"
                                                            value={product.name}
                                                            onChange={(e) => handleProductChange(index, 'name', e.target.value)}
                                                            className="px-4 py-1 w-1/3 rounded-lg border  font-semibold text-black border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            list="productList"
                                                            placeholder="Product Name"
                                                        />
                                                        <datalist id="productList">
                                                            {products && products.map((product, idx) => (
                                                                <option key={idx} value={product.name} />
                                                            ))}
                                                        </datalist>
                                                        

                                                        <input
                                                            type="number"
                                                            value={product?.unit_price || 0}
                                                            onChange={(e) => handleProductChange(index, 'unit_price', e.target.value)}
                                                            className="px-4 mx-2 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            placeholder="Price"
                                                        />
                                                        <input
                                                            type="number"
                                                            value={product?.quantity || 1}
                                                            onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                                                            className="px-4 mx-2 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            placeholder="Quantity"
                                                        />
                                                        <input
                                                            type="number"
                                                            value={product?.discount_percentage || 0}
                                                            onChange={(e) => handleProductChange(index, 'discount_percentage', e.target.value)}
                                                            className="px-4 mx-2 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                                            placeholder="Discount (%)"
                                                        />
                                                        <div className="w-1/6 text-right font-semibold">
                                                            {formatCurrency(product.total)}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* Grand Total */}
                                            <div className="flex items-center font-semibold mt-4 text-white">
                                                <div className="w-1/12 text-center"></div>
                                                <div className="w-1/3"></div>
                                                <div className="w-1/6"></div>
                                                <div className="w-1/6"></div>
                                                <div className="w-1/6 text-right">Total:</div>
                                                <div className="w-1/6 text-right font-semibold">
                                                    MK {PO.transactionLines.reduce((total, product) => total + product.total, 0)}
                                                  {formatCurrency(grandTotal)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {PO.transactionLines.length > 0 && isTotalGreaterThanZero && (
                                        <div className="flex justify-end p-2">
                                            <button
                                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                                onClick={handleSubmit}
                                            >
                                                Save
                                            </button>
                                    </div>
                                    
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default NewPurchaseOrder;
