import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AppContext from '../../../../contexts/AppContext';
import { departmentTypeProperty } from "@syncfusion/ej2/documenteditor";
import { handleDepartmentApi} from '../utils/api';
import { getEmployees, addUser } from '../../../authentication/users/utils/api';

const NewDepartment = ({onClose }) => {
    const { userData ,actionCount,setActionCount} = useContext(AppContext);
    const [department, setDepartment] = useState({});
    const [name, setName] = useState('');
    const [departmentHead, setDepartmentHead] = useState('');
    const [employees, setEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchEmployees();
    }, []);
    
    const fetchEmployees = async () => {
        setIsLoading(true);
        try {
            const employeesData = await getEmployees('employees', userData);
            console.log('employees data',employeesData.data)
            const updatedEmployees = employeesData.data.map(employee => ({
                ...employee,
                full_name: `${employee.first_name} ${employee.last_name}`
            }));
            setEmployees(updatedEmployees);
        } catch (error) {
            console.error('Error fetching employees:', error);
            toast.error('Failed to fetch employees');
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        const updatedDepartment = {};
        setDepartment(updatedDepartment);
        
    };

    const cleanObject = (obj) => { // remove empty values in customer object
        if (obj === null || obj === undefined) {return null;}
    
        if (typeof obj === 'object') {
            if (Array.isArray(obj)) {
                return obj
                    .map(cleanObject)
                    .filter(item => item !== null && item !== undefined && item !== '');
            } else {
                const cleanedObj = Object.fromEntries(
                    Object.entries(obj)
                        .map(([key, value]) => [key, cleanObject(value)])
                        .filter(([key, value]) => value !== null && value !== undefined && value !== '')
                );
                return Object.keys(cleanedObj).length > 0 ? cleanedObj : null;
            }
        }
        
        return obj;
    };

     // Handling input changes
     const handleTextChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'departmentHead':
                setDepartmentHead(value);
                const selectedEmployee = employees.find(employee=> employee.full_name === value);
                if (selectedEmployee) {
                    setDepartment(prevDepartment => ({...prevDepartment,hod_id: selectedEmployee.id}));
                }
                break;
            case 'name':
                setName(value);
                setDepartment(prevDepartment=> ({...prevDepartment,[name]: value}));
                break;   
        }       
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!department?.name) {toast.error('Please enter department name ');return;  }
          // Clean the product object
        const cleanedDepartment = cleanObject(department);
     
        console.log('cleaned custokt',cleanedDepartment)
        const apiMethod = 'POST'
        setIsLoading(true);
        //apiCall
        handleDepartmentApi('departments', cleanedDepartment, userData, apiMethod)
        //updateProduct('products', userData,product)
        .then(async (data) => {
            if (data.data.status === 200) {//console.log('data from product api',data.data.statusText)
              toast.success('Department updated Successfully.',data.data.statusText );  
              setActionCount(actionCount+1); // to trigger reload of products list
            }else if (data.data.status === 409) {
              toast.error('Department already exist',data.data.statusText); // Display the error message to the use
              return
            } else { 
              toast.error('Error saving Department.',data.data.statusText,);  
              return
            }
        })
        .catch(error => {
          toast.error(error.message); // Display the error message to the user
          // navigate('/dashboard');
        })
        .finally(() => { 
            resetForm(); // Reset form fields
            setActionCount(actionCount+1); // to trigger reload of products list
            setIsLoading(false); 
        });
      }
    
    console.log(' department at this point',department)
    return (
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Department</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="name"
                                onChange={(event) => handleTextChange(event)}
                                value={name}
                            />
                            <label htmlFor="name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Department Name
                            </label>
                        </div>
              
                        

                        <div className="relative z-0 mt-18 group">
                            <div className="flex">
                            <input
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="departmentsList"
                                name="departmentHead"
                                onChange={(event) => handleTextChange(event)}
                                value={departmentHead}
                            />
                            {department.photo_url && (
                                <img
                                    className="rounded-full h-24 w-24"
                                    src={department.photo_url} alt="department"
                                />
                            )}
                            </div>
                            <datalist id="departmentsList">
                                {employees.map((employee) => (
                                    <option key={employee.id} value={`${employee.first_name} ${employee.last_name}`} />
                                ))}
                            </datalist>
                            <label
                                htmlFor="head"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                Head of Department
                            </label>
                        </div>

                     

                        

                        

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewDepartment;
