import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { format } from 'date-fns';
import { Audio } from 'react-loader-spinner';
import CreatableSelect from "react-select/creatable";
import { BsFillSendFill } from "react-icons/bs";
import { FaTrashAlt  } from 'react-icons/fa';
import { IoSearchSharp } from "react-icons/io5";
import { MdOutlineCancel } from 'react-icons/md';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import { Layout, Header } from '../../../../components';
import fetchData from '../../../../contexts/Api';

const SelectedProductsPrices = () => {
    const [Orders, setOrders] = useState([]);
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [reason, setReason] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [products, setProducts] = useState([]);
    const [purchaseId, setPurchaseId] = useState('');
    const [deliveredOrders, setDeliveredOrders] = useState([]);
    const [invoiceId, setInvoiceId] = useState('');
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [productList, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(new Set());
    const { isLoggedIn, userData, currentColor } = useContext(AppContext);
    const navigate = useNavigate(); // Get navigate function from React Router

    const filteredProducts = products.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = (productList || []).slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        setIsLoading(true);
        fetchProducts();
    }, []);

    const reasons = [
        { value: 'initial_stock', label: 'Initial Stock' },
        { value: 'quality_control', label: 'Quality Control' },
        { value: 'return_or_exchange', label: 'Return or Exchange' },
        { value: 'damaged_goods', label: 'Damaged Goods' },
        { value: 'expiry', label: 'Expiry' },
        { value: 'product_recall', label: 'Product Recall' },
        { value: 'obsolete_products', label: 'Obsolete Products' },
        { value: 'error_correction', label: 'Error Correction' },
        { value: 'competitive_pricing', label: 'Competitive Pricing' },
        { value: 'seasonal_discounts', label: 'Seasonal Discounts' },
        { value: 'economic_factors', label: 'Economic Factors' },
        { value: 'pricing_strategy_update', label: 'Pricing Strategy Update' },
    ];
    
    const fetchProducts = async () => {
        await fetchData('products', userData, setProducts);
        setIsLoading(false);
    };

    const handleReasonChange = (newValue, actionMeta, item) => {
        item.adjust_reason = newValue ? newValue.value : '';
    };

    const handleAction = (action, po) => {
        setActionType(action);
        setPurchaseOrder(po);
        setIsModalOpen(true);
    };

    
    

    const handleSearch = () => {
        const searchedProducts = filteredProducts.filter(product => !productList.some(p => p.id === product.id));
        setProductList(prevProductList => [...prevProductList, ...searchedProducts]);
        setSearchTerm(''); // Clear the search input after search
    };

    const handleRemoveProduct = (id) => {
        setProductList(prevList => prevList.filter(product => product.id !== id));
    };

    const handleCheckboxChange = (id) => {
        setSelectedProducts(prevSelectedProducts => {
            const newSelectedProducts = new Set(prevSelectedProducts);
            if (newSelectedProducts.has(id)) {
                newSelectedProducts.delete(id);
            } else {
                newSelectedProducts.add(id);
            }
            return newSelectedProducts;
        });
    };
        
    const handleClose = () => {
        navigate(-1); // Navigate back to the previous page
    };
        console.log(currentItems)
    return (
        <>
            <Layout>
                <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl">
                    <ToastContainer position="top-center" autoClose={2000} />
                    <div className="flex ml-4 pt-4 text-center">
                        <Header category="" title="Adjust Prices" color={currentColor} />
                        {/* Close button */}
                    <div className="w-full sm:w-auto sm:flex-1  sm: px-4 py-2 flex flex-row justify-end">
                        <button
                            className="bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
                            title="Close"
                            onClick={handleClose} // Call handleClose on click
                        >
                            < MdOutlineCancel  size={24} color={currentColor}/>
                        </button>
                    </div>
                    </div>

                    <div>
                        {isLoading ? (
                            <div className="flex items-center flex-col">
                                <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                                <p className="mt-2">Loading data. Please wait...</p>
                            </div>
                        ) : (
                            <>
                                <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
                                    <button 
                                        className="bg-${currentColor} mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                                        title="Create Invoice"
                                    >
                                        <BsFillSendFill size={24} 
                                  
                                        /> 
                                    </button>
                                </div>

                                <div id="search" className="p-4 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
                                    <div className="flex flex-wrap"> 
                                        <div className="w-full flex">
                                            <input
                                                type="text"
                                                placeholder="Search Product..."
                                                value={searchTerm}
                                                list="productsList"
                                                onChange={e => setSearchTerm(e.target.value)}
                                                className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:border-indigo-500"
                                            />
                                            <button
                                                onClick={handleSearch}
                                                className="px-4 py-2 bg-blue-500 text-white rounded-r-md"
                                            >
                                                <IoSearchSharp/>
                                            </button>

                                            <datalist id="productsList">
                                                {products.map((product, index) => (
                                                    <option key={index} value={product.name} />
                                                ))}
                                            </datalist>
                                        </div>            
                                    </div>
                                </div>
                                
                                <div className="mt-4 flex  max-w-lg">
                                        {Array.from({ length: Math.ceil((productList || []).length / itemsPerPage) }).map((_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => paginate(index + 1)}
                                                className={`mx-1 px-1 py-0.5 rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                                                style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                    </div>
                                    <div id="result" className="mx-2 p-2 mt-4 md:my-4 p-2 md:p-2 bg-gray-50 rounded-3xl">
    <div className="mx-auto bg-white rounded-lg shadow-lg">
        <div className="flex justify-between p-3 border-b">
            <div style={{ width: '2%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">.</span>
            </div>
            <div style={{ width: '20%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">Name</span>
            </div>
            <div style={{ width: '10%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">Package</span>
            </div>
            <div style={{ width: '10%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">Old Price</span>
            </div>
            <div style={{ width: '10%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">New Price</span>
            </div>
            <div style={{ width: '25%', textAlign: 'left' }}> {/* Adjusted width */}
                <span className="text-sm text-slate-400">Reason</span>
            </div>
        </div>

        {currentItems.map(item => (
            <div key={item.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
                <div style={{ width: '2%', textAlign: 'left' }}> {/* Adjusted width */}
                    <button
                        onClick={() => handleRemoveProduct(item.id)}
                        className="text-red-500 hover:text-red-700"
                    >
                        <FaTrashAlt />
                    </button>
                </div>
                <div style={{ width: '20%' }}> {/* Adjusted width */}
                    <div className="leading-snug text-sm text-gray-900 font-bold">{item?.name}</div>
                    <div className="leading-snug text-xs text-slate-400">{item?.product_categories?.name}</div>
                </div>
                <div style={{ width: '10%', textAlign: 'left' }}> {/* Adjusted width */}
                    <div className="leading-snug text-sm text-gray-900">{item?.prices?.buy_uom_name}</div>
                </div>
                <div style={{ width: '10%', textAlign: 'left' }}> {/* Adjusted width */}
                    <div className="leading-snug text-sm text-gray-900">{item?.prices.sell_price_new}</div>
                </div>
                <div style={{ width: '10%' }}> {/* Adjusted width */}
                    <input
                        type="text"
                        name="new_qty"
                        className="text-sm text-gray-900 bg-transparent border border-gray-300 rounded-md px-2 py-1 w-full"
                        placeholder="New Qty"
                        value={item?.new_qty}
                    />
                </div>
                <div style={{ width: '25%' }}> {/* Adjusted width */}
                    <CreatableSelect
                        options={reasons}
                        name="adjust_reason"
                        onChange={(newValue, actionMeta) => handleReasonChange(newValue, actionMeta, item)}
                        placeholder="Select reason"
                        styles={{
                            container: (provided) => ({
                                ...provided,
                                width: '100%'
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: 'rgba(0, 0, 0, 0.5)',
                            }),
                        }}
                    />
                </div>
                
            </div>
        ))}
    </div>
    <div className="flex justify-end m-2">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
                                        style={{ backgroundColor: currentColor }}
                                        //   onClick={handleSubmit}
                                        >
                                        Save
                                        </button>
                                    </div>
</div>


                            </>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
};


export default SelectedProductsPrices;
