import React, { useState } from 'react';
import './upload.css';

function Upload() {
  const [filebase64, setFileBase64] = useState("");
  function formSubmit(e) {
    e.preventDefault();
    // Submit your form with the filebase64 as 
    // one of your fields
    console.log('filebase:',{ filebase64 });
    alert("here you'd submit the form using\n the filebase64 like any other field");
  }

  // The Magic all happens here.
  function convertFile(files) {
    if (files) {
      const fileRef = files[0] || "";
      const fileType = fileRef.type || "";
      console.log("This file upload is of type:", fileType);
      const reader = new FileReader();
      reader.readAsBinaryString(fileRef);
      reader.onload = (ev) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`);
      };
    }
  }

  return (
    <div className="App text-center">
      <header className="text-xs text-center content-center">
        <form onSubmit={formSubmit}>
        <input className="w-full px-5 py-3 mb-3 border rounded
         focus:border-blue-500 focus:outline-none text-xs"
          type="email" placeholder="Category Name"
        />
        <input className="w-full px-5 py-3 mb-3 border rounded
         focus:border-blue-500 focus:outline-none"
          type="email" placeholder="Description"
        />
          <input className="w-full px-5 py-3 mb-3 border rounded
          focus:border-blue-500 focus:outline-none"
          type="email" placeholder="Email address"
        />
        Choose an image to upload
        <input className="w-full px-5 py-2 mt-1 mb-3 border rounded
          focus:border-blue-500 focus:outline-none text-center"
          type="file" onChange={(e) => convertFile(e.target.files)} />
          <hr />
          {filebase64 && (
            <>
            {/*    It's ready to be submitted!<br />
            Simply include the 'filebase64' variable<br /> 
            as one of the things you submit</p> */} 

            
              {/* if it's an image */}
              {(filebase64.indexOf("image/") > -1) && 
                <img src={filebase64} width={75} className="mx-auto" />
              }
              
              {/* if it's a video */}
              {(filebase64.indexOf("video/") > -1) && 
                <video controls>
                  <source src={filebase64} />
                </video>
              }

              {/* if it's an audio (music, sound) */}
              {(filebase64.indexOf("audio/") > -1) && 
                <audio controls>
                  <source src={filebase64} />
                </audio>
              }

              {/* if it's a PDF */}
              {(filebase64.indexOf("application/pdf") > -1) && 
                <embed src={filebase64} width="800px" height="2100px" />
              }
              
              <hr />
              <button onClick={formSubmit}>Submit and check the console</button>
            </>
          )}
        </form>
      </header>
    </div>
  );
}

export default Upload;
