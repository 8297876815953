// PurchaseOrderService.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';

 
//add supplier
export const addSupplier = async (page, supplierData, userData) => {
  try {
    //console.log('supplierData',supplierData)
    //console.log('in api', supplierData)
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
          name: supplierData.name,
          tax_number:supplierData.tax_number,
          contact_person: supplierData.contact_person,
          contact_person_position: supplierData.contact_person_title,
          country_id: supplierData.country_id,
          district_id: supplierData.district_id,
          email: supplierData.email,
          gps_link: supplierData.gps_link,
          is_customer: supplierData.is_customer,
          is_supplier: supplierData.is_supplier,
          phone_number: supplierData.phone_number,
          region_id: supplierData.region_id,
          suburb: supplierData.suburb,
      })
      
     
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
      const data = await response.json();
      //console.log(data)
      return {
        data
        // count: data.length,
        // result: data,
        // error: null, // Include this line to ensure consistency
      };

  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};
// update supplier

export const updateSupplier = async (page, supplierId, supplierData, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: supplierId }, // Send supplierId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      supplierData,
    }, config);
    //console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error updating product:', err);
    throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const deleteSupplier = async (page, supplierId,  userData) => {
 // console.log(userData)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:supplierId,
      active: 0,
    }, config);
   // console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error deleting  product:', err);
    throw new Error('Failed to delete product'); // Rethrow a custom error message to be handled by the calling code
  }
};

  

export const getSupplier= async (page, purchaseId, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id:purchaseId}, // Send purchaseId as a query parameter
  };
  try {
    // console.log('in api sir ',page, purchaseId, userData);
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
    const data = response.data;
    console.table('purchase order sir  API Response:', data); // Log the data


    return {
      count: data ? 1 : 0, // Assuming data is the product details
      result: data ? [data] : null,
      error: null,
    };
  } catch (error) {
    console.error('Error fetching purchase order:', error);
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch purchase order details. Please try again later.',
    };
  }
}; 




export const createPayment = async (page, payment, userData) => {
  console.log('invoice in api ',payment)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
  };

  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json','Authorization': `Bearer ${userData.data.token}`},
      body: JSON.stringify({
        due_date:payment.due_date,
        invoice_no:payment.invoice_no,
        state:payment.payment_state,
        order_id:payment.id,
        session_id: 1,
      })
    });
 
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    //const responseData = await response.json(); // Parse response body as JSON
   // const message = responseData.message; // Extract the message
   // console.log('message from api',message)
   
     const data = await response.json();
    return {
      data
      //message: 'Operation was completely successful.' data,
    };
  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const updateInvoice = async (page, invoice ,userData) => {
  console.log('update invoice api',invoice)
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userData.data.token}`
      },
      body: JSON.stringify({
        id:invoice.id,
        due_date:invoice.due_date,
        invoice_no:invoice.invoice_no,
        state:invoice.state,
        session_id: 1,
      })
    });
      if (!response.ok) {throw new Error(`HTTP error! Status: ${response.status}`);}
   

      const data = await response.json();
      console.log('Response invoiuce from API:', data); // Log the response from the API

   
      return {
        data
        // count: data.length,
        // result: data,
        // error: null, // Include this line to ensure consistency
      };

  } catch (err) {
      console.error('Error adding order:', err);
      throw new Error('Failed to add order'); // Rethrow a custom error message to be handled by the calling code
  }
};



export const deleteInvoice = async (page, invoiceId,  userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    // params: { id: productId }, // Send productId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:invoiceId,
      active: 0,
    }, config);
  
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error deleting  Invoice:', err);
    throw new Error('Failed to delete Invoice'); // Rethrow a custom error message to be handled by the calling code
  }
};




