// QtyAdjustment Service.js
import axios from 'axios';
import API_BASE_URL from '../../../../config';

// get all productLists

export const handleQtyAdjustmentApi = async (page, changedFields, userData, apiMethod) => {
  console.log('quantity adjustments  in qtyAdjustment  api ', changedFields);
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
  };
  console.log('apimethod',JSON.stringify(changedFields),apiMethod)
  try {
    let response;

    switch (apiMethod) { //// determine which end point to use
      case 'PUT': response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);break;
      case 'POST': response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config); break;
      case 'DELETE': response = await axios.delete(`${API_BASE_URL}/${page}`, config); break;
      default:throw new Error('Unsupported method');
    }
    console.log('from api response sir ', response);
    return {data: response};// Return response data
  } catch (err) {
    console.error(`Error handling company profile:`, err);
    throw new Error('Failed to handle company profile'); // Rethrow a custom error message to be handled by the calling code
  }
};

export const newQtyAdjustment = async (page, userData, qtyAdjustment) => {
  console.log('adjustedQty in api sir',qtyAdjustment);
  try {
    const response = await fetch(`${API_BASE_URL}/${page}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${userData.data.token}`},
      body: JSON.stringify(qtyAdjustment)
    });
    console.log('from api response sir ',response)
    return {data: response };
  } catch (err) {
    console.error('Error adding productList:', err);
    throw new Error('Failed to add productList category'); // Rethrow a custom error message to be handled by the calling code
  }
};



export const updateQty = async (page, productListData, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
     params: { id: productListData.id }, // Send productListId as a query parameter
  };

  try {
    const response = await axios.put(`${API_BASE_URL}/${page}`, {
      id:productListData.id,
      quantity_instock: productListData.quantity_instock,
    }, config);
    console.log('msg from server',response)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
  } catch (err) {
    console.error('Error updating productList:', err);
    throw new Error('Failed to update productList Quantity'); // Rethrow a custom error message to be handled by the calling code
  }
};

// get all productLists
export const getReasons = async (page,userData) => {
  console.log('page',page,'userdat',userData)
  const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
   
  };
  try {
    const response = await axios.get(`${API_BASE_URL}/${page}`, config);
   
    return {
      data: response
    };

  } catch (error) {
    console.error('Error fetching data:', error);
    // Ensure that the returned object has the expected structure
    return {
      count: 0,
      result: null,
      error: 'Failed to fetch data. Please try again later.',
    };
  }
};


