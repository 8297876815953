import React, { useState, useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppContext from '../../../../contexts/AppContext';
import { updateRole } from '../utils/api';

const EditRole = ({ rowData,onClose }) => {
    const { userData, actionCount,setActionCount } = useContext(AppContext);
    const [role, setRole] = useState({name: '',description: ''});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {setRole(rowData)}, [rowData]);

    const handleTextChange = (name, value) => {
        setRole(prevRole => ({...prevRole,[name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!role.name) { toast.error('Please enter role name'); return; }
        if (!role.description) { toast.error('Please enter description'); return; }
        updateRole('roles', role, userData)
        .then(async (data) => {
          console.log('data from role api',data.data.statusText)
          if (data.data.status === 200) {
            toast.success('Role updated  Successfully.',data.data.statusText );  
            setActionCount(actionCount+1); // to trigger reload of products list
            //setUserData(userInfo);
          }else if (data.data.status === 409) {
            toast.error('Category already exist',data.data.statusText); // Display the error message to the use
            return
          } else { 
            toast.error('Error saving category.',data.data.statusText,);  
            return
          }
        })
        .catch(error => {
          toast.error(error.message); // Display the error message to the user
          // navigate('/dashboard');
        })
        .finally(() => {
          setActionCount(actionCount+1); // to trigger reload of products list
          setIsLoading(false); });
    }

console.log('role at this time',role)
    return (
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Role</h2>
            </div>
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                id="title"
                                className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none
                                focus:ring-0 focus:border-blue-600 peer"
                                placeholder=""
                                name="name"
                                onChange={(event) => handleTextChange(event.target.name, event.target.value)}
                                value={role.name}
                            />
                            <label
                                htmlFor="name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Role Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                        <label
                                htmlFor="description"
                                className="mb-4 text-gray-500"
                            >
                                Description
                            </label>
                            <ReactQuill
                                className="react-quill"
                                theme="snow"
                                style={{ height: "100px" ,marginTop: "10px" }}
                                value={role.description}
                                onChange={(value) => handleTextChange('description', value)}
                            />
                            
                        </div>

                        <div className="text-center mt-4 py-4">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditRole;
