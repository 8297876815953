import React, { useContext, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import AppContext from '../contexts/AppContext';
import { links } from '../data/links';

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, userData, screenSize, companyProfile } = useContext(AppContext);
  
  const [openSection, setOpenSection] = useState(null);

  const userDepartment = (userData?.data?.employees?.departments?.name || '').toLowerCase();
  const userRole = (userData?.data?.user_roles?.name || '').toLowerCase();

  const filteredSections = links
    .map(section => {
      const hasDepartmentAccess = (section.department || []).map(dep => dep.toLowerCase()).includes(userDepartment);
      const hasRoleAccess = (section.role || []).map(role => role.toLowerCase()).includes(userRole);
      const hasSectionAccess = hasDepartmentAccess || hasRoleAccess;

      const filteredLinks = section.links.filter(link => {
        const linkRoles = (link.role || []).map(role => role.toLowerCase());
        return linkRoles.length === 0 || linkRoles.includes(userRole);
      });

      return (hasSectionAccess || filteredLinks.length > 0)
        ? { ...section, links: filteredLinks }
        : null;
    })
    .filter(section => section !== null);

  const handleToggleSection = (title) => {
    setOpenSection(prev => (prev === title ? null : title));
  };

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={handleCloseSideBar} className="items-center mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
              {companyProfile && companyProfile.photoUrl && (
                <img className="rounded-full h-24 w-24" src={companyProfile.photoUrl} alt="logo" />
              )}
              <span>{companyProfile.name}</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {filteredSections.map((section) => (
              <div key={section.title}>
                <div className="flex items-center cursor-pointer" onClick={() => handleToggleSection(section.title)}>
                  {section.icon} {/* Display the section icon */}
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">{section.title}</p>
                </div>
                {openSection === section.title && section.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : '',
                    })}
                    className={({ isActive }) => (isActive ? activeLink : normalLink)}
                  >
                    {link.icon}
                    <span className="capitalize">{link.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
