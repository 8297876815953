import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import { categoryTypeProperty } from "@syncfusion/ej2/documenteditor";
import { addCategory} from '../utils/api';

const NewProductCategory = ({ data, onClose }) => {
    const { userData,actionCount,setActionCount, } = useContext(AppContext);
    const [category, setCategory] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {setCategories(data) }, [data]);

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        if (name === 'parentCategory.name') {
            setCategory(prevCategory => ({
                ...prevCategory,
                parentCategory: {
                    ...prevCategory.parentCategory,
                    name: value
                }
            }));
        } else {
            // Handle other fields
            setCategory(prevCategory => ({
                ...prevCategory,
                [name]: value
            }));
        }
    };
    

    

    const convertFile = (files) => {
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileName = fileRef.name; // Get the file name
            console.log('this is file ref',fileRef )
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
                const base64Data = `data:${fileType};base64,${btoa(ev.target.result)}`;
                setCategory(prevCategory => ({
                    ...prevCategory,
                    filename:fileName,
                    img: base64Data,
                    displayImg: btoa(ev.target.result),
                    imgType: fileType,
                }));
            };
        }
    };

    const handleImgChange = (event) => {
        const { name, files } = event.target;
        if (name === "img") {convertFile(files);}
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!category.name) {toast.error('Please enter name ');return;  }
        //if (!category.photo_url) {toast.error('Please enter photo');return;  }
    
        addCategory('product_categories', userData,category)
        .then(async (data) => {
          console.log('data from category api',data.data.statusText)
          if (data.data.status === 200) {
            toast.success('Category  added Successfully.',data.data.statusText );  
            setActionCount(actionCount+1); // to trigger reload of products list
            //setUserData(userInfo);
          }else if (data.data.status === 409) {
            toast.error('Category already exist',data.data.statusText); // Display the error message to the use
            return
          } else { 
            toast.error('Error saving category.',data.data.statusText,);  
            return
          }
        })
        .catch(error => {
          toast.error(data.data.statusText); // Display the error message to the user
          // navigate('/dashboard');
        })
        .finally(() => { setIsLoading(false); });
      }
    
    

   /* const handleSubmittt = async () => {
        console.log('just before submit',category)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!category.name) {toast.error('Please enter name ');return;  }
        //if (!category.photo_url) {toast.error('Please enter photo');return;  }
        try {
            const response = await ( addCategory('product_categories', userData,category))

            console.log('data from authentication',data.data)
        if (data.data.message && data.data.message.toLowerCase().includes('failed')) {
            toast.error(data.data.message); // Display the error message to the user
            setIsLoggedIn(false);
            return
        } else {   
            const userInfo = data;
            setUserData(userInfo);
            setIsLoggedIn(true);
            navigate('/dashboard');
        }


            toast.success('category  Updated  Successfully.');
          } catch (err) {toast.error('Error submitting form'); }
    }; */

    return (
        <div className="">
          <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Product Categories</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    {/* <form className="shadow-md rounded px-8 pt-4 mb-2"> */}
                    <div className="relative z-0 mb-8  group">
                  <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                      text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                      dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                      focus:ring-0 focus:border-blue-600 peer" 
                      placeholder=" "  
                      name="name"
                      onChange={(event) => handleTextChange(event)}
                      value={category?.name}
                  />
                  <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                      transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                      peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                      peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Product Category
                  </label>
              </div>
              <div className="relative z-0 mb-8 group">
                <input
                  type="text"
                  name="parentCategory.name"
                  className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  list="parentsList" // Link the input to the datalist
                  onChange={(event) => handleTextChange(event)}
                  key={category?.parentCategory?.id} // Assuming 'id' is a unique identifier
                  value={category?.parentCategory?.name}
                />
               {/* Use the datalist element for autocomplete */}
                <datalist id="parentsList">{categories.map((parent) => (<option value={parent.name} />))}
                </datalist> 
                <label
                  htmlFor="page"
                  className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Parent Category
                </label>
              </div>
              <div className="relative z-0 mb-3 w-full group flex items-center">
                  <input
                  className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                  type="file"
                  accept="image/*"
                  name='img'
                  onChange={(event) => handleImgChange(event)}
                  />
  
                  <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload picture</label>
                  {category.img && (
                  <img
                      className="rounded-full h-24 w-24"
                      src={`data:${category.imgType};base64,${category.displayImg}`}
                      alt="Page image"
                  />
                  )}
              </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
};

export default NewProductCategory;
