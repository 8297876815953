import React, { useEffect,useState, useContext } from 'react';

import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';
//import { stackedCustomSeries, stackedPrimaryXAxis, stackedPrimaryYAxis } from '../../data/dummy';
import AppContext from '../../contexts/AppContext';

import { UseStateContext } from '../../contexts/ContextProvider';
import {stackedPrimaryYAxis, stackedPrimaryXAxis,  } from '../../utils/chartsLayout'; 

const Stacked = ({ width, height,stackedChartData, isMonthlyView }) => {
  const { currentMode } = UseStateContext();
  const [chartData, setChartData] = useState(stackedChartData);

  // UseEffect to ensure stackedChartData is persisted
  useEffect(() => {
    if (stackedChartData && stackedChartData.length > 1) {
      setChartData(stackedChartData);
    } else {
      setChartData([]);
     // console.error('Invalid stackedChartData format or missing data.');
    }
  }, [stackedChartData]); // This hook will run whenever stackedChartData changes

  // Fallback UI or null if chartData is invalid
  if (!chartData || chartData.length < 2) {
    return <p>No data available</p>; // You can provide a better fallback UI here
  }

 
  
  const maxValue= isMonthlyView?20000000:1000000;
  const interval= maxValue/5;
  const stackedPrimaryYAxis = {
    lineStyle: { width: 0 },
    minimum: 0,
    maximum: maxValue,
    interval: interval,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
  };



  const stackedCustomSeries = [
    { dataSource: chartData[0],
      xName: 'x',
      yName: 'y',
      name: 'Paid',
      type: 'StackingColumn',
      background: 'blue',
    },
  
    { dataSource: chartData[1],
      xName: 'x',
      yName: 'y',
      name: 'Ordered',
      type: 'StackingColumn',
      background: 'red',
  
    },
  
  ]; 

  

  

  return (
    <ChartComponent
      id="charts"
      primaryXAxis={stackedPrimaryXAxis}
      primaryYAxis={stackedPrimaryYAxis}
      width={width}
      height={height}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      background={currentMode === 'Dark' ? '#33373E' : '#fff'}
      legendSettings={{ background: 'white' }}
    >
      <Inject services={[StackingColumnSeries, Category, Legend, Tooltip]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {stackedCustomSeries.map((item, index) => <SeriesDirective key={index} {...item} />)}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Stacked;
