import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { Audio } from 'react-loader-spinner';
import { CiEdit } from "react-icons/ci";
import { IoMdTrash } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Layout, Header, Button, Modal, NewUser, EditUser, DeleteUser } from '../../../../components';
import fetchData from '../../../../contexts/Api'; 
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const { userData, currentColor, actionCount,setActionCount } = useContext(AppContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate(); // Get navigate function from React Router


    // Filter users based on search term
    const filteredUsers = users && users.filter(user => {
        // Check if user object and user name are both defined
        if (user && user.username) {
        // Convert user name and search term to lowercase for case-insensitive comparison
        return user.username.toLowerCase().includes(searchTerm.toLowerCase());
        }
        // If user object or user name is undefined, return false to filter it out
        return false;
    });
  
  
    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [actionCount]);

  const fetchUsers = async () => {
    await fetchData('users', userData, setUsers);
    setIsLoading(false);
  };

  const handleAction = (action,user) => {
    setActionType(action);
    setUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
};

console.log('users at this  point',currentItems)
  return (
    <Layout>
      <div className="m-2 md:m-10 mt-4 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-8  justify-center text-center ">
          <div className="w-full sm:w-auto sm:flex-1  sm: px-4 py-2 flex flex-row justify-end">
            <button
                className="bg-transparent hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
                title="Close"
                onClick={handleClose} // Call handleClose on click
            >
              <IoClose size={24}/>
            </button>
          </div>
          <Header category="" title="User Accounts"  />
        </div>
        
        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && <NewUser  users={users}onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'edit' && <EditUser  users={users} rowData={user}  onClose={handleCloseModal} />}
          {isModalOpen && actionType === 'delete' && <DeleteUser rowData={user}  onClose={handleCloseModal} />}
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col ">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading data. Please wait...</p>
          </div>
        ) : (

          <>
            <div className='bg-gray-50 rounded-lg  mx-auto'>
              <div className="max-w-lg mx-auto">
                <button
                  className="rounded-lg my-3 p-1 transition-colors duration-300 ease-in-out hover:shadow-xl text-white"
                  style={{ backgroundColor: currentColor }}
                  onClick={() => handleAction('new', {})}
                  type="button"
                >
                  Add New
                </button>
              </div>
              {/* Search Input */}
              <div className="max-w-lg mx-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
                />
              </div>
        
              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
        
              <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg">
  {/* Column Header */}
  <div className="flex p-3  ml-12 border-b">
    <div className="flex-1">
      <span className="text-sm text-slate-400">User</span>
    </div>
    <div className="flex-1">
      <span className="text-sm text-slate-400">Role</span>
    </div>
    <div className="flex-1">
      <span className="text-sm text-slate-400"></span>
    </div>
  </div>

  {/* List of Items */}
  {currentItems && currentItems.map(user => (
    <div key={user.id} className="p-3 flex items-center justify-between border-t cursor-pointer hover:bg-gray-200">
      <div className="flex items-center flex-1">
        <img className="rounded-full h-10 w-10" src={user?.employees?.photo_url} alt="" />
        <div className="ml-2 flex flex-col">
          <div className="leading-snug text-sm text-gray-900">{`${user?.username}`}</div>
        </div>
      </div>
      <div className="flex-1">
        <span className="text-sm text-gray-900">{user?.user_roles.name}</span>
      </div>
      <div className="flex items-center">
        <button 
          className="h-6 px-2 text-sm rounded-full hover:bg-blue-100"
          style={{ color: currentColor, transition: 'background-color 0.3s ease-in-out' }}
          onClick={() => handleAction('edit', user)}
          title='Edit'
        >
          <CiEdit size={20}/>
        </button>
        <button 
          className="h-6 px-2 ml-2 text-sm text-blue-400 rounded-full hover:bg-blue-100"
          style={{ color: currentColor, transition: 'background-color 0.3s ease-in-out' }}
          onClick={() => handleAction('delete', user)}
          title='Delete'
        >
          <IoMdTrash size={20}/>
        </button>
      </div>
    </div>
  ))}
</div>


          {/* Pagination */}
          <div className="mt-3 flex mb-4 max-w-lg mx-auto">
            {Array.from({ length: Math.ceil(filteredUsers.length / itemsPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`mx-1 px-1 py-0.5  rounded-full text-xs text-white focus:outline-none ${currentPage === index + 1 ? 'bg-current' : ''}`}
                style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
              >s
                {index + 1}
              </button>
            ))}
          </div>
          </div>
          </>
          
        )}

        
      </div>
    </Layout>
  );
  };

export default Users;
