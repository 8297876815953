
  // DepartmentService.js
  import axios from 'axios';
  import API_BASE_URL from '../../../../config';


  export const handleDepartmentApi = async (page, changedFields, userData, apiMethod) => {
    console.log('department in department api ', changedFields);
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
    };
    try {
      let response;
      switch (apiMethod) { //// determine which end point to use
        case 'PUT': response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);break;
        case 'POST': response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config); break;
        case 'DELETE': response = await axios.delete(`${API_BASE_URL}/${page}`, config); break;
        default:throw new Error('Unsupported method');
      }
      console.log('from api response sir ', response);
      return {data: response};// Return response data
    } catch (err) {
      console.error(`Error handling department:`, err);
      throw new Error('Failed to handle department'); // Rethrow a custom error message to be handled by the calling code
    }
  };

export const updateDepartment = async (page, department, userData) => {
  console.log('department in api',department)
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id: department.id }, // Send productId as a query parameter
    };
  
    try {
    
      const response = await axios.put(`${API_BASE_URL}/${page}`, {
        id:department.id,
        name:department.name,
        hod_id:department.employee_id,
        session_id:1
      }, config);
      console.log('from api response sir ',response)
      return {
        data: response
      };
    } catch (err) {
      console.error('Error updating product:', err);
      throw new Error('Failed to update product'); // Rethrow a custom error message to be handled by the calling code
    }
  };
  

  export const deleteDepartment= async (page, userData, department) => {
    console.log('deleteing ',department)
    const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: department.id }, // Send productId as a query parameter
  };
  
  try {
    const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
    // Check if the response indicates success (you may adjust this based on your API's response structure)
    console.log('from api response sir ',response)
      return {
        data: response
      };
  
  } catch (err) {
    console.error('Error deleting product:', err);
    throw new Error('Failed to delete product');
  }
  };
  


