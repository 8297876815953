
    // DepartmentService.js
    import axios from 'axios';
    import API_BASE_URL from '../../../../config';


    

    export const handleCompanyProfileApi = async (page, changedFields, userData, apiMethod) => {
      console.log('profile in handleCompany api ', changedFields);
      const config = {
        headers: { Authorization: `Bearer ${userData.data.token}` },
        params: changedFields.id ? { id: changedFields.id } : {}, // Send profile.id as a query parameter if present
      };
      try {
        let response;
        switch (apiMethod) { //// determine which end point to use
          case 'PUT': response = await axios.put(`${API_BASE_URL}/${page}`, changedFields, config);break;
          case 'POST': response = await axios.post(`${API_BASE_URL}/${page}`, changedFields, config); break;
          case 'DELETE': response = await axios.delete(`${API_BASE_URL}/${page}`, config); break;
          default:throw new Error('Unsupported method');
        }
        console.log('from api response sir ', response);
        return {data: response};// Return response data
      } catch (err) {
        console.error(`Error handling company profile:`, err);
        throw new Error('Failed to handle company profile'); // Rethrow a custom error message to be handled by the calling code
      }
    };
    

    export const updateCompanyProfile = async (page, profile, userData) => {
      console.log('profile in api', profile);
      
      const config = {
        headers: { Authorization: `Bearer ${userData.data.token}` },
        params: profile.id ? { id: profile.id } : {}, // Send productId as a query parameter only if profile.id is present
      };
    
      try {
        let response;
        if (profile.id) {
          // Use axios.put if profile.id is present
          response = await axios.put(`${API_BASE_URL}/${page}`, profile, config);
        } else {
          // Use axios.post if profile.id is not present
          response = await axios.post(`${API_BASE_URL}/${page}`, profile, config);
        }
    
        console.log('from api response sir ', response);
        return {
          data: response.data // Return response data
        };
      } catch (err) {
        console.error('Error updating company profile:', err);
        throw new Error('Failed to update company profile'); // Rethrow a custom error message to be handled by the calling code
      }
    };
    


export const updateCompanyProfiles = async (page, profile, userData) => {
  console.log('profile in api',profile)
    const config = {
      headers: { Authorization: `Bearer ${userData.data.token}` },
      params: { id: profile.id }, // Send productId as a query parameter
    };
  
    try {
      const response = await axios.put(`${API_BASE_URL}/${page}`, profile, config);
      console.log('from api response sir ',response)
      return {
        data: response
      };
    } catch (err) {
      console.error('Error updating company profile:', err);
      throw new Error('Failed to update company profile'); // Rethrow a custom error message to be handled by the calling code
    }
  };
  

  export const deleteDepartment= async (page, userData, profile) => {
    console.log('deleteing ',profile)
    const config = {
    headers: { Authorization: `Bearer ${userData.data.token}` },
    params: { id: profile.id }, // Send productId as a query parameter
  };
  
  try {
    const response = await axios.delete(`${API_BASE_URL}/${page}`,config);
    // Check if the response indicates success (you may adjust this based on your API's response structure)
    console.log('from api response sir ',response)
      return {
        data: response
      };
  
  } catch (err) {
    console.error('Error deleting product:', err);
    throw new Error('Failed to delete product');
  }
  };
  


