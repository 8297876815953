import React, { useState, useEffect, useContext , useRef} from "react";
import { BsFillSendFill } from "react-icons/bs";
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api';
import { handleApi, cleanObject } from '../../../../utils/utils';

const InputField = ({ label, name, value, onChange, type = "text", list }) => (
    <div className="relative z-0 mb-8 group">
        <input
            type={type}
            name={name}
            className="block py-2.5 px-0 w-full text-gray-900 bg-transparent dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
            placeholder=" "
            onChange={onChange}
            value={value}
            list={list}
        />
        <label className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
            {label}
        </label>
    </div>
);

const NewEmployee = ({ onClose }) => {
    const getDefaultDateOfBirth = () => {
        const today = new Date();
        const year = today.getFullYear() - 18; // 18 years ago
        return new Date(year, 0, 1).toISOString().split('T')[0]; // January 1st
    };

    const defaultImage = "https://media.istockphoto.com/id/2176696315/photo/3d-minimal-person-icon-employee-icon-avatar-icon-3d-rendering-illustration.webp?a=1&b=1&s=612x612&w=0&k=20&c=5XGUpbsWrGhxmXTF8pRtod6oLPmaYvpPGQVNDdI09NM="; //  // Default image URL

    const [employee, setEmployee] = useState({
        date_of_birth: getDefaultDateOfBirth(),
        first_name: '',
        last_name: '',
        gender: '',
        phone_number: '',
        email: '',
        job_title: '',
        department_id: '',
        hire_date: new Date().toISOString().split('T')[0],
        photo: {},
        
    });

    const fileInputRef = useRef(null); // Create a ref for the file input

    const [departments, setDepartments] = useState([]);
    const [department_name, setDepartment_name] = useState([]);
    const [imagePreview, setImagePreview] = useState(defaultImage);   // state for the image URL
    const [isLoading, setIsLoading] = useState(false);
    const { userData, currentColor,actionCount, setActionCount } = useContext(AppContext);
 
    const resetForm = () => {
        setEmployee({
            date_of_birth: getDefaultDateOfBirth(),
            first_name: '',
            last_name: '',
            gender: '',
            phone_number: '',
            email: '',
            job_title: '',
            department_id: '',
            hire_date: new Date().toISOString().split('T')[0],
            photo: {},
        });
        setImagePreview(defaultImage); // Reset image preview to default
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Clear the file input
        }
        setDepartment_name('');
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        const apiEndpoint = 'departments';
        const apiMethod = 'GET';
        setIsLoading(true);
        try {
            const response = await handleApi(apiEndpoint, {}, userData, apiMethod);
            if (response.error) {
                console.error('Error fetching departments:', response.errorMessage);
                return;
            }
            if (response.status === 200) {
                setDepartments(response.data);
            } else {
                console.error(`Error ${response.status}: ${response.statusText}`);
            }
        } catch (error) {
            console.error('API Call Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
    
        // Handle department_name separately
        if (name === 'department_name') {
            setDepartment_name(value);
            // Find department id here as well
            const department = departments.find(department => department.name === value);
            setEmployee(prevEmployee => ({
                ...prevEmployee,
                department_id: department ? department.id : '', // Update department_id based on department_name
            }));
        } else {
            setEmployee(prevEmployee => ({
                ...prevEmployee,
                [name]: value, // Update other fields
            }));
        }
    };
    
    

    const handleImgChange = (event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onload = (ev) => {
                const base64Data = ev.target.result.split(',')[1]; // Split to remove the prefix
                setImagePreview(ev.target.result); // Keep the preview with the full data URL
                setEmployee(prevEmployee => ({
                    ...prevEmployee,
                    photo: { data: base64Data, contentType: file.type }, // Save only the raw base64
                }));
            };
            
            reader.readAsDataURL(file); // Keep reading as data URL for the preview
        }
    };
    
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const { first_name, last_name, phone_number, department_id, hire_date } = employee;
        if (!first_name || !last_name || !phone_number || !department_id || !hire_date) {
            toast.error('Please fill in all required fields.');
            return;
        }

        const cleanedEmployee = cleanObject(employee);
        console.log('cleanedEmployee',cleanedEmployee);
        debugger;
        setIsLoading(true);
        const apiMethod = 'POST';

        try {
            const response = await handleApi('employees', cleanedEmployee, userData, apiMethod);
            if (response.status === 200) {
                toast.success('Employee added successfully.');
                setActionCount(actionCount + 1);
                resetForm();
            } else if (response.status === 409) {
                toast.error('Employee already exists.');
            } else {
                toast.error('Error saving employee: ' + response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving Employee: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
             {/* meneu icon */}
             <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
             
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Cancel"
                    onClick={() => resetForm()}

                  >
                    <FcCancel size={20} color={currentColor} />
                  </button>
         
             
             

                

              

              
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Submit"
                 
                    onClick={(e) => {
                        e.preventDefault(); // Prevent default behavior here
                        handleSubmit(e); // Pass the event to your handler
                    }}

                  >
                    <BsFillSendFill size={20} color="blue" />
                  </button>
     

               


               
              </div>
              {/* end menu icons */}
         {/* Image Preview Section */}
            <div className="flex flex-col items-center justify-center mb-4 bg-gray-100 w-full ">
                <img
                    src={imagePreview}
                    alt="Profile Preview"
                    className="w-32 h-32 object-cover rounded-full border-2 border-gray-300"
                />
                <h2 className="text-blue-700 mb-3 text-xl font-bold text-center pb-2">New Employee</h2>
            </div>



            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <InputField label="First Name" name="first_name" value={employee.first_name} onChange={handleTextChange} />
                        <InputField label="Last Name" name="last_name" value={employee.last_name} onChange={handleTextChange} />
                        <InputField label="Date of Birth" name="date_of_birth" value={employee.date_of_birth} type="date" onChange={handleTextChange} />
                        <InputField label="Phone Number" name="phone_number" value={employee.phone_number} onChange={handleTextChange} />
                        <InputField label="Email" name="email" value={employee.email} onChange={handleTextChange} />
                        <InputField label="Job Title" name="job_title" value={employee.job_title} onChange={handleTextChange} />
                        <InputField label="Hired Date" name="hire_date" value={employee.hire_date} type="date" onChange={handleTextChange} />
                        
                        <div className="relative z-0 mb-8 group">
                            <select
                                name="gender"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none"
                                onChange={handleTextChange}
                                value={employee.gender}
                            >
                                <option value="" disabled>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <label className="absolute text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10">Gender</label>
                        </div>

                        <InputField label="Department" name="department_name" value={department_name} onChange={handleTextChange} list="departmentList" />
                        <datalist id="departmentList">
                            {departments.map((department) => (
                                <option key={department.id} value={department.name} />
                            ))}
                        </datalist>

                        <div className="relative z-0 mb-3 w-full group flex items-center">
                <input
                    className="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none"
                    ref={fileInputRef} // Attach the ref here
                    type="file"
                    accept="image/*"
                    name="img"
                    onChange={handleImgChange}
                />
                <label className="absolute text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10">Profile Picture</label>
            </div>

            

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewEmployee;
