import React, { useState, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import { Audio } from 'react-loader-spinner';
import CreatableSelect from "react-select/creatable";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { BsFillSendFill } from "react-icons/bs";
import { MdOutlineCancel } from 'react-icons/md';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import { Layout, Header } from '../../../../components';
import fetchData from '../../../../contexts/Api';

const AllProductsPrices = () => {
    const [price, setPrice] = useState({});
    const [reason, setReason] = useState('');
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [actionType, setActionType] = useState('');
    const { isLoggedIn, userData, currentColor } = useContext(AppContext);
    useEffect(() => {setIsLoading(true);}, []);
    const navigate = useNavigate(); // Get navigate function from React Router

    const reasons = [
        { value: 'initial_stock', label: 'Initial Stock' },
        { value: 'quality_control', label: 'Quality Control' },
        { value: 'return_or_exchange', label: 'Return or Exchange' },
        { value: 'damaged_goods', label: 'Damaged Goods' },
        { value: 'expiry', label: 'Expiry' },
        { value: 'price_recall', label: 'Product Recall' },
        { value: 'obsolete_prices', label: 'Obsolete Products' },
        { value: 'sample_requests', label: 'Sample Requests' },
        { value: 'error_correction', label: 'Error Correction' },
        { value: 'count_mismatch', label: 'Count Mismatch' },

    ];

    

    const handleReasonChange = (newValue, actionMeta, item) => {
        item.adjust_reason = newValue ? newValue.value : '';
    };

    const handleChange = (action, po) => {
        // setActionType(action);
        // setPurchaseOrder(po);
        // setIsModalOpen(true);
    };

    
    

   
    const handleClose = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <>
            <Layout>
                <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl">
                    <ToastContainer position="top-center" autoClose={2000} />
                    <div className="p-4 justify-center text-center">
                    <Header category="" title="Adjust Product Prices" color={currentColor} />
                    {/* Close button */}
                    <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end">
                        <button
                            className="bg-transparent mt-3 hover: hover:py-1 px-1 rounded mr-2 transition-all duration-300"
                            title="Close"
                            onClick={handleClose} // Call handleClose on click
                        >
                            < MdOutlineCancel  size={24} color={currentColor}/>
                        </button>
                    </div>
                </div>

                    <div>
                        
                            <>
                                

                                <div className="w-1/3  mx-auto">
                                    <form className="shadow-md rounded px-8 pt-4 mb-2">
                                        <div className="relative z-0 mb-8  group">
                                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                                focus:ring-0 focus:border-blue-600 peer" 
                                                placeholder=" "  
                                                name="name"
                                                onChange={(event) => handleChange(event)}
                                                value={price?.name}
                                            />
                                            <label htmlFor="pricename" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Adjustment Rate
                                            </label>
                                        </div>

                                        <div className="relative z-0 mb-8  group">
                                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                                focus:ring-0 focus:border-blue-600 peer" 
                                                placeholder=" "  
                                                name="barcode"
                                                onChange={(event) => handleChange(event)}
                                                value={price?.barcode}
                                            />
                                            <label htmlFor="pricename" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Reason for adjustment
                                            </label>
                                        </div>

                                        <div className="relative z-0 mb-8 group">
                                                                                      <input
                                                type="date"
                                                name="category_id"
                                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                list="parentsList" // Link the input to the datalist
                                                onChange={(event) => handleChange(event)}
                                                key={price?.category_id} // Assuming 'id' is a unique identifier
                                                value={price?.category_name}
                                            />
                                                {/* Use the datalist element for autocomplete */}
                                                
                                            <label
                                                htmlFor="category_id"
                                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                Effective Date
                                            </label>
                                        </div>
                                    </form>
                                    <div className="flex justify-center">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
                                        style={{ backgroundColor: currentColor }}
                                        //   onClick={handleSubmit}
                                        >
                                        Receive
                                        </button>
                                    </div>
                                </div>                
                            </>
                        </div>
                    </div>
            </Layout>
        </>
    );
};

export default AllProductsPrices;
