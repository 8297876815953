import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import { MdOutlineCancel } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { FaEdit, FaTrash} from 'react-icons/fa';
import { IoMdMore } from "react-icons/io";

import { Layout, Header, Modal, NewQuotation, EditQuotation, DeleteQuotation} from '../../../../components';
import { handleApi, formatCurrency,formatFriendlyDate, formatMediumDate } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Quotations = () => {
  const [quotations, setQuotations] = useState([]); // purchase orders
  const [quotation, setQuotation] = useState({}); // one purchase order
//   const [quantityAdjustment, setQuantityAdjustment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [menuOpen, setMenuOpen] = useState(null);
  const { userData, currentColor, actionCount } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;


  const menuRef = useRef(null);
  
  useEffect(() => {
    fetchQuotations();
  }, [actionCount]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when searchTerm changes
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the menu and the button
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(null); // Close the menu
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchQuotations = async () => {
    const apiEndpoint = 'sales/orders';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};
    setIsLoading(true);
    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
      if (response.error) {
        toast.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
     
      if (status === 200) {
        setQuotations(data);
      } else {
        toast.error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };


  const filteredQuotations = quotations && quotations.filter(quote => {
    // Check if quotation, first_name, and last_name are defined
    if (quote && quote.reference && quote.partners.name) {
        // Convert both names and search term to lowercase for case-insensitive comparison
        const reference = quote.reference.toLowerCase().includes(searchTerm.toLowerCase());
        const customer = quote.partners.name.toLowerCase().includes(searchTerm.toLowerCase());
        
        // Return true if either first_name or last_name contains the search term
        return reference || customer;
    }
    // If any of the required fields are undefined, filter it out
    return false;
});

  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredQuotations.slice(indexOfFirstItem, indexOfLastItem);



  const totalPages = Math.ceil(filteredQuotations.length / itemsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  // const handleAction = (action, po) => {

  //  setActionType(action)
  //  setPO(po)
  //   setIsModalOpen(true);
  // };

  const handleMenuToggle = (id) => {
  
    setMenuOpen(menuOpen === id ? null : id); // Toggle menu visibility
  };

  const handleMenuItemClick = (action, quote) => {
    setQuotation(quote);
    setActionType(action);
    setIsModalOpen(true);
    setMenuOpen(null); // Close the menu after selecting an action
  };

  const handleAction = (action,quotation) => {
    setActionType(action);
    setQuotation(quotation);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page
  };
console.log('quotations',quotations)
  return (
    <Layout>
      <div className="m-1 md:m-2 mt-1 p-2 md:p-2 bg-white rounded-3xl">
        <ToastContainer position="top-center" autoClose={2000} />
        <div className="mt-1 justify-center text-center">
          <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
            <button
              className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
              title="Close"
              onClick={handleClose}
            >
              <MdOutlineCancel size={24} color={currentColor} />
            </button>
          </div>
          <Header category="" title="Quotations" />
        </div>

        <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
          {isModalOpen && actionType === 'new' && (
            <NewQuotation  onClose={handleCloseModal} showCloseButton={true} />
          )}
          {isModalOpen  && actionType === 'edit' && (
            <EditQuotation quote={quotation} onClose={handleCloseModal} />
          )}
         
        </Modal>

        {isLoading ? (
          <div className="flex items-center flex-col">
            <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
            <p className="mt-2">Loading. Please wait...</p>
          </div>
        ) : (
          <>
            <div className="bg-gray-50 rounded-lg max-w-4xl  mx-auto">
              <div className="bg-gray-50 rounded-lg max-w-4xl mx-auto">

   

   

<div className="max-w-lg flex justify-end items-center mb-2 mx-auto">

              {/* Pagination */}
              <div className="mt-3 flex mb-4 max-w-lg mx-auto">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`mx-1 px-2 py-1 rounded-full text-xs text-white focus:outline-none ${
                      currentPage === index + 1 ? 'bg-current' : ''
                    }`}
                    style={{ backgroundColor: currentColor, transition: 'background-color 0.3s ease-in-out' }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
               {/* Search Input */}
            <div id="search" className="p-1 md:m-1 px-4 md:bg-gray-50 rounded-3xl">
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
            />
            </div>

     {/* New Quotation Button */}
     <button
      className="ml-4 px-3 py-2 text-xs font-medium rounded-lg text-white focus:outline-none flex items-center space-x-1"
      style={{  background: currentColor }}
      onClick={() => handleMenuItemClick('new', {})}

    >
      <IoMdAdd />
      <span className="ml-1">New Quotation</span>
    </button>
  </div>
   {/*end  New Quotation Button */}


              </div>

           {/* Quotation List */}
<div className="w-full bg-white rounded-lg shadow-lg">
  <div className="grid grid-cols-6 gap-4 p-3 border-b">
    <div className="col-span-1"> {/* Name column */}
      <span className="text-sm text-slate-400 whitespace-nowrap">Reference #</span>
    </div>
    <div className="col-span-1"> {/* Reduced Job Title column */}
      <span className="text-sm text-slate-400 whitespace-nowrap">Date</span>
    </div>
    <div className="col-span-2"> {/* Reduced Department column */}
      <span className="text-sm text-slate-400 whitespace-nowrap">Customer</span>
    </div>

    <div className="col-span-1"> {/* Reduced Department column */}
      <span className="text-sm text-slate-400 whitespace-nowrap">Amount</span>
    </div>

    <div className="col-span-1 text-right"> {/* Actions column */}
      <span className="text-sm text-slate-400 whitespace-nowrap"></span>
    </div>
  </div>

  {currentItems.length > 0 ? (
    currentItems.map((quote, index) => (
      <div
    
        className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200"
      >
        <div className="col-span-1 ">
            <div className="leading-snug text-sm text-gray-900">{quote.reference?quote.reference:''}</div>
        </div>
        
        <div className="col-span-1"> {/* Adjusted width for Job Title */}
          <span className="text-sm text-gray-900">{quote.date ? formatMediumDate(quote.date): 'N/A'}</span>
        </div>

        <div className="col-span-2"> {/* Adjusted width for Department */}
          <span className="text-sm text-gray-900">{quote.partners.name ? quote.partners.name  : 'N/A'}</span>
        </div>

        <div className="col-span-1"> {/* Adjusted width for Department */}
          <span className="text-sm text-gray-900">{quote.totalTaxedPrice? quote.totalTaxedPrice: 0}</span>
        </div>
        

        <div className="col-span-1 text-right"> {/* Actions column */}
              <button
                className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                onClick={() => handleMenuToggle(quote.id)} // Toggle menu on click
                title="More detail"
              >
                <IoMdMore size={20} color={currentColor} />
              </button>
              
              {menuOpen === quote.id && ( // Show menu only for the open item
                <div className="inline-block relative" ref={menuRef}>
                  <div className="mt-1 w-32 bg-white border border-gray-200 rounded shadow-lg z-10">
                    <ul className="py-1">
                      <li>
                        <button
                          onClick={() => handleMenuItemClick('edit', quote)} // Handle edit action
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        >
                          <FaEdit className="mr-2" /> Edit
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            handleMenuItemClick('delete', quote); // Handle delete action
                          }}
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        >
                          <FaTrash className="mr-2" /> Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>

      </div>
    ))
  ) : (
    <p className="p-3 text-center">No quotation available.</p>
  )}
</div>


            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Quotations;
