import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { FcCancel } from "react-icons/fc";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import {  updateProduct, handleProductApi } from '../utils/api';

import { getChangedFields, handleApi} from '../../../../utils/utils'; 
const EditProduct = ({ data, onClose }) => {
    const { userData, setActionCount,actionCount } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [originalProduct, setOriginalProduct] = useState({});
    const [originalPrices, setOriginalPrices] = useState({});
    const [categories, setCategories] = useState([]);
    const [category_name, setCategory_name] = useState('');
    const [uoms, setUoms] = useState([]);
    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [quantity_instock, SetQuantity_instock] = useState('');
    const [reorder_quantity, SetReorder_quantity] = useState('');
    const [buy_uom_name, setBuy_uom_name] = useState('');
    const [sell_uom_name, setSell_uom_name] = useState('');
    const [sell_price_new, setSell_price_new] = useState('');
    const [buy_price_new, setBuy_price_new] = useState('');
    const [imgType, setImgType] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [action, setAction] = useState('');
    const [isEditable, setIsEditable] = useState(false); // to manage edit mode
   

    
    // console.log('data',data)
    console.log(data);
    useEffect(() => {
        setProduct(data);
        setOriginalProduct(data); // Store the initial profile
        setName(data?.name);
        setBarcode(data?.barcode);
        SetQuantity_instock(data?.quantity_instock);
        SetReorder_quantity(data?.reorder_quantity);
        setCategory_name(data?.product_categories?.name);
        setBuy_uom_name(data?.prices?.buy_uom_name);
        setSell_uom_name(data?.prices?.sell_uom_name);
        setBuy_price_new(data?.prices?.buy_price_new);
        setSell_price_new(data?.prices?.sell_price_new);
        //photoUrl

        setIsLoading(false);
      }, [data]);


      useEffect(() => {
        setIsLoading(true);
        fetchProductCategories();
        fetchUoms();
        const updatedProduct = { 
            ...data, 
            // category_name: data?.product_categories?.name || '',
            // buy_uom_name: data?.prices?.buy_uom_name || '',
            // sell_uom_name: data?.prices?.sell_uom_name || '',
            // buy_price_new: data?.prices?.buy_price_new || '',
            // sell_price_new: data?.prices?.sell_price_new || '',
            prices: {
                buy_uom_id: data?.prices?.buy_uom_id || '',
                sell_uom_id: data?.prices?.sell_uom_id || '',
                buy_price_new: data?.prices?.buy_price_new || '',
                sell_price_new: data?.prices?.sell_price_new || '',
            }
        };
        
        setProduct(updatedProduct);
        setCategory_name(data?.product_categories?.name || '');
        setBuy_uom_name(data?.prices?.buy_uom_name || '');
        setSell_uom_name(data?.prices?.sell_uom_name || '');
        setBuy_price_new(data?.prices?.buy_price_new || '');
        setSell_price_new(data?.prices?.sell_price_new || '');
        setOriginalProduct(data);
        setIsLoading(false);
        
    }, [data]);

    useEffect(() => { // to update action ie either 'edit' or 'delete' 
        if (action) {handleSubmit();}
    }, [action]);

    
    
    const fetchProductCategories = async () => {
        const apiEndpoint = 'product_categories';
        const apiMethod = 'GET';
        const parameters = {};
        setIsLoading(true);
        const cleanedData={};
        try {
          const response = await handleApi(apiEndpoint, cleanedData, userData, apiMethod,parameters);
          if (response.error) {
            console.error('Error fetching  report ', response.errorMessage);// Handle specific API error
            return; // Exit function to avoid further processing
          }
          const { status, statusText } = response;
          if (status === 200) {
            const fetchedCategories = response.data; // fetched departments
            setCategories(fetchedCategories);
          } else { console.error(`Error ${status}: ${statusText}`); } // Handle non-200 responses
         
        } catch (error){ console.error(error.message || 'An unexpected error occurred.');// Handle unexpected errors
        } finally {setIsLoading(false);}
    };

    const fetchUoms = async () => {
        const apiEndpoint = 'uoms';
        const apiMethod = 'GET';
        const parameters = {};
        setIsLoading(true);
        const cleanedData={};
        try {
          const response = await handleApi(apiEndpoint, cleanedData, userData, apiMethod,parameters);
          if (response.error) {
            console.error('Error fetching  report ', response.errorMessage);// Handle specific API error
            return; // Exit function to avoid further processing
          }
          const { status, statusText } = response;
          if (status === 200) {
            const fetchedUoms = response.data; // fetched departments
            setUoms(fetchedUoms);
          } else { console.error(`Error ${status}: ${statusText}`); } // Handle non-200 responses
         
        } catch (error){ console.error(error.message || 'An unexpected error occurred.');// Handle unexpected errors
        } finally {setIsLoading(false);}
    };
    


    const convertFile = (files) => {
        console.log('conviert images')
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
            const  photo= {data:btoa(ev.target.result),contentType:fileType};
            setDisplayImg(btoa(ev.target.result))
            setImgType(fileType)
            setProduct(prevProduct => ({...prevProduct,photo:photo}));
        };
      }
    };

   
    const handleImgChange = (event) => {
        const { name, files } = event.target;if (name === "img") {convertFile(files);}
    };

    // Handling input changes
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setProduct(prevProduct => {const updatedProduct = { ...prevProduct };
            switch (name) {
                case 'name':
                    setName(value);
                    updatedProduct[name] = value;
                    break;
                case 'barcode':
                    setBarcode(value);
                    updatedProduct[name] = value;
                    break;
                case 'category_name':
                   
                    setCategory_name(value);
                    const selectedCategory = categories.find((category) => category.name === value);
                    if (selectedCategory) {updatedProduct.category_id= selectedCategory.id;} 
                    break;
                case 'buy_uom_name':
                    setBuy_uom_name(value);
                    const selectedBuyUom = uoms.find(uom => uom.name === value);
                    if (selectedBuyUom) {updatedProduct.prices.buy_uom_id= selectedBuyUom.id;} 
                    break;
                case 'sell_uom_name':
                    setSell_uom_name(value);
                    const selectedSellUom = uoms.find(uom => uom.name === value);
                    if (selectedSellUom) {updatedProduct.prices.sell_uom_id= selectedSellUom.id;} 

                    break;
                case 'buy_price_new':
                    setBuy_price_new(value);
                    updatedProduct.prices.buy_price_new= value;
                    break;
                case 'sell_price_new':
                    setSell_price_new(value);
                    updatedProduct.prices.sell_price_new= value;
                    break;
        
                case 'reorder_quantity':
                    SetReorder_quantity(value);
                    updatedProduct[name] = value;
                    break;
                    
                default:
                    break;
            }
            return updatedProduct;
        });
    };

    

    const handleDeleteBtnClick = async () => {
        const confirmed = window.confirm('Are you sure you want to delete this customer?');
        if (confirmed) {setAction('delete');}
    };
    const handleEditBtnClick = () => {setAction('put');};
   
    
    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        // e.preventDefault();
        console.log('products at this point',product)
        if (!product.name) {toast.error('Please enter name ');return;  }
        if (!product.prices.sell_uom_id) {toast.error('Please enter Sell Unit of measure ');return;  }
        if (!product.category_id) {toast.error('Please enter product category ');return;  }
        //if (!category.photo_url) {toast.error('Please enter photo');return;  }
        
        const changedFields = getChangedFields(originalProduct, product); 
        const apiMethod = action === 'delete' ? 'DELETE' : 'PUT';
        console.log('apiMethod', apiMethod);
        const parameters={};
        setIsLoading(true);
    
        try {
            const response = await handleApi('products', changedFields, userData, apiMethod,parameters);
            console.log('API Response:', response);
    
            if (response.status === 200) {
                toast.success(action === 'delete' ? 'product deleted successfully.' : 'product updated successfully.');
                setActionCount(actionCount + 1);
                if (action === 'delete') {
                    onClose(); // Close the component if delete was successful
                }
            } else if (response.status === 409) {
                toast.error('Product already exists.');
            } else {
                toast.error('Error saving product.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving product: ' + error.message);
        } finally {
            
            setActionCount(actionCount + 1); // Trigger reload of products list
            setIsLoading(false);
        }
    };
    
    
    
    

    // const photoUrl = (product && product.displayImg)
    // ? `data:${product.imgType};base64,${product.displayImg}`
    // : product.photoUrl;


    const photo = (displayImg)? `data:${imgType};base64,${displayImg}`: product.photoUrl;
    const photo_url = (photo) ? photo : product.photoUrl;
    console.log('usere',userData?.data)
    const isUserAuthorized = 
    (userData?.data.employee?.department?.name?.toLowerCase() === 'procurement' ||
    userData?.data.user_roles?.name?.toLowerCase() === 'executive'|| 
      userData?.data.user_roles?.name?.toLowerCase() === 'admin');
  
     console.log('product at this point',product)
    return (
        <div className="">
          <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Product</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <>
            
                {/* menu icon */}
                <div className="w-full sm:w-auto sm: px-4 py-2 flex flex-row justify-end">
                                {isUserAuthorized && (
                                    <>
                                        <button
                                            className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? 'hidden' : ''}`}
                                            title="Edit"
                                            onClick={() => setIsEditable(true)}
                                        >
                                            <CiEdit size={20} color="blue" />
                                        </button>
                                        <button
                                            className={`bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                            title="Delete"
                                            onClick={handleDeleteBtnClick}
                                        >
                                            <FcCancel size={20} color="blue" />
                                        </button>
                                    </>
                                )}
                                <button
                                    className={`bg-transparent mt-3 hover:bg-blue-400 hover:text-white text-blue-700 py-1 px-1 rounded mr-2 transition-all duration-300 ${isEditable ? '' : 'hidden'}`}
                                    title="Save"
                                    onClick={handleEditBtnClick}
                                >
                                    <BsFillSendFill size={20} color="blue" />
                                </button>
                            </div>
                            {/* end menu icons */}
                <div className="w-full">
                    <form className="shadow-md rounded px-8 pt-4 mb-2">
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="name"
                                onChange={(event) => handleTextChange(event)}
                                value={name}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Product Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="barcode"
                                onChange={(event) => handleTextChange(event)}
                                value={barcode}
                                disabled={!isEditable} // Conditionally disable input
                            />
                            <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Barcode
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="category_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="categoriesList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                value={category_name}
                                disabled={!isEditable} // Conditionally disable input
                            />
                                 {/* Use the datalist element for autocomplete */}
                                <datalist id="categoriesList">{categories.map((cat) => (<option value={cat.name} />))}
                                </datalist> 
                                <label
                                htmlFor="category_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Product Category
                                </label>
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                type="text"
                                name="buy_uom_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="uomsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                // key={product?.buy_uom_id} // Assuming 'id' is a unique identifier
                                value={buy_uom_name}
                                disabled={!isEditable} // Conditionally disable input
                                />
                            {/* Use the datalist element for autocomplete */}
                                <datalist id="uomsList">{uoms.map((uom) => (<option value={uom.name} />))}
                                </datalist> 
                                <label
                                htmlFor="buy_uom_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Buy Unit of Measure 
                                </label>
                            </div>

                            <div className="relative z-0 mb-8  group">
                                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                    focus:ring-0 focus:border-blue-600 peer" 
                                    placeholder=" "  
                                    name="buy_price_new"
                                    onChange={(event) => handleTextChange(event)}
                                    value={buy_price_new}
                                    disabled={!isEditable} // Conditionally disable input
                                        
                                />
                                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Cost Price
                                </label>
                            </div>

                            <div className="relative z-0 mb-8 group">
                                <input
                                type="text"
                                name="sell_uom_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="uomsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                // key={product?.sell_uom_name} // Assuming 'id' is a unique identifier
                                value={sell_uom_name}
                                disabled={!isEditable} // Conditionally disable input
                                />
                            {/* Use the datalist element for autocomplete */}
                                <datalist id="uomsList">{uoms.map((uom) => (<option value={uom.name} />))}
                                </datalist> 
                                <label
                                htmlFor="sell_uom_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                Sell unit of measure
                                </label>
                            </div>

              

            <div className="relative z-0 mb-8  group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="sell_price_new" 
                    onChange={(event) => handleTextChange(event)}
                    value={sell_price_new}
                    disabled={!isEditable} // Conditionally disable input
                />
                <label htmlFor="productname" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sell Price
                </label>
            </div>

            <div className="relative z-0 mb-8  group">
                <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                    text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                    dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                    focus:ring-0 focus:border-blue-600 peer" 
                    placeholder=" "  
                    name="reorder_quantity"
                    onChange={(event) => handleTextChange(event)}
                    value={reorder_quantity}
                    disabled={!isEditable} // Conditionally disable input
                />
                <label htmlFor="reorder_quantity" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                    transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                    peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Re-order Qty
                </label>
            </div>
              

              <div className="relative z-0 mb-3 w-full group flex items-center">
                  <input
                  className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                  type="file"
                  accept="image/*"
                  name='img'
                  onChange={(event) => handleImgChange(event)}
                  disabled={!isEditable} // Conditionally disable input
                  />
  
                  <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Upload product picture</label>
                 
                 
                  {photo_url && (
                    <img
                        className="rounded-full h-24 w-24"
                        src={photo_url}
                        alt="product"
                    />
                    )}
                  
                  
                 
                
              </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
                </>
            </div>
        </div>
       
    );
};

export default EditProduct;
