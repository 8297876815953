
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CreatableSelect from "react-select/creatable";
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { CiEdit } from 'react-icons/ci';
import { FaSackDollar} from "react-icons/fa6";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { BsFillSendArrowUpFill } from "react-icons/bs";
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { getChangedFields,formatCurrency,handleApi, printQuotation, inputDate,formatMediumDate,cleanObject} from '../../../../utils/utils'; 
import { Pie } from '../../../../components';

const EditPayment = ({ pay, actionType,onClose }) => {
  const [enablePaymentInput, setEnablePaymentInput] = useState(actionType === 'edit'); // Initialize based on actionTyp
  const [payment, setPayment] = useState({
    date: inputDate(pay?.date) || new Date().toISOString().split('T')[0],
    id :pay?.id || '',
    invoice_id :pay?.invoice_id || '',
    account_no: pay?.account_no || '',
    account_name: pay?.account_name || '',
    bank_name:  pay?.bank_name || '',
    bank_branch:  pay?.bank_branch || '',
    cheque_no:  pay?.cheque_no || '',
    amount: pay?.amount || '',
    reference: pay?.reference || '',
    method_id: pay?.method_id || '',
    state:pay?.state || '',
  });
  const [originalPayment, setOriginalPayment] = useState({ ...payment });// duplicate payment 
  const [paymentMethods, setPaymentMethods] = useState({ ...payment });// duplicate payment 
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
  const navigate = useNavigate();
  console.log('payment',pay)
  
  useEffect(() => {
    fetchPaymentMethods();
  }, []);


  

  // const paymentMethods = [
  //   { value: 0, label: "..." },
  //   { value: "check", label: "Cheque" },
  //   { value: "bank_transfer", label: "Bank Transfer" },
  //   { value: "airtel_money", label: "Airtel Money" },
  //   { value: "tnm_mpamba", label: "TNM Mpamba" },
  // ];

  
  const fetchPaymentMethods = async () => {
    const apiEndpoint = 'payment_methods';
    const apiMethod = 'GET';

    try {
      const response = await handleApi(apiEndpoint, {}, userData, apiMethod);
      if (response.error) {
        toast.error(`Error fetching payment methods: ${response.errorMessage}`);
        return;
      }

      const { status, data } = response;

      if (status === 200) {
        const formattedMethods = data.map(method => ({
          value: method.id, // Assuming each method has an id
          label: method.name, // Assuming each method has a name
        }));
        setPaymentMethods(formattedMethods);
      } else {
        toast.error(`Error ${status}: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred.');
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value, // Update the specific field in the payment object
    }));
  };

  
  const handleBtnClick = (action) => {
    const updatedPayment = { ...payment, state: action}; 
    handleSubmit(updatedPayment); // Call the submit function with updatedPayment
  };
  
  const handleSubmit = async (updatedPayment) => {
    const apiEndpoint = 'purchase/payments';
    const parameters = {id:payment.id};
    const apiMethod = 'PUT';
    const requiredFields = ['date','method_id', 'amount',];  // Define required fields
    if (actionType==='edit'){ // Check if all required fields are filled edit action is edit 
      for (const field of requiredFields) {
        if (!payment[field]) {
          toast.error(`Please fill in the required field: ${field}`);
          return; // Exit the function if a required field is missing
        }
      }
    }
    const cleanedPayment=getChangedFields(originalPayment,updatedPayment)
    console.log('cleaned payment',cleanedPayment)
    debugger
    try {
      const response = await handleApi(apiEndpoint, cleanedPayment, userData, apiMethod, parameters);
      if (response.status === 200) {
        toast.success('Payment updated successfully.');
        // onClose(); // Close the modal if the response is successful
      } else {
        toast.error('Error saving pay,ent.', response.statusText);
      }
      return response; // Return the response for checking
    } catch (error) {
      toast.error('Error saving payment: ' + error.message);
      return null; // Return null in case of error
    } finally {
      setActionCount(actionCount + 1);
      setIsLoading(false);
    }
  };
  




    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your invoice');
            formData.append('text', 'Please find the attached invoice.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'invoiceST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };

   

    
    const handlePrint = () => {
        const updatedinvoice = { ...payment, companyProfile };
        printQuotation(updatedinvoice);
    };

    const handleDownload = async () => {
        // Generate the PDF document
        const blob = await pdf(<MyDocument invoice={payment} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'invoice.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
             const blob = await pdf(<MyDocument pay={payment} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'invoice.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };

    
  

    const handleClose = () => {
        navigate(-1);
    };


    // const total = (invoice.transactionLines?.reduce((total, product) => total + product.total, 0) || 0).toFixed(2);
   // const total = grandTotal || 0;

    const userRole = userData?.data?.user_roles?.name?.toLowerCase();
    const userDepartment = (userData?.data?.employees?.departments?.name || '').toLowerCase();
    
    const allowedRoles = ['officer', 'admin', 'executive', 'supervisor', 'manager']; // roles allowed to edit 
    const approvalRoles = ['admin', 'executive', 'supervisor', 'manager']; // roles allowed to edit 
    const allowedDepartments = ['procurement']; // departments allowed to edit 
     const isRoleAllowed = userRole && allowedRoles.includes(userRole);
    const isDepartmentAllowed = userDepartment && allowedDepartments.includes(userDepartment);
    
    // Updated isUserAllowed logic
    const isUserAllowed = (isRoleAllowed && isDepartmentAllowed) || ['admin', 'executive'].includes(userRole);
    const isOfficerAllowed= (isDepartmentAllowed && userRole.toLowerCase()  ==='officer');
    console.log('user role',userRole)
    const canApprove = 
    (isDepartmentAllowed && approvalRoles.includes(userRole)) || 
    ['admin', 'executive'].includes(userRole);

  console.log('canApprove',canApprove)
    const handlePaymentMethodChange = (selectedOption) => {
      console.log('selected method sir',selectedOption)
      setSelectedPaymentMethod(selectedOption.label.toLowerCase())
      setPayment((prevPayment) => ({
        ...prevPayment,
        method_id: selectedOption.value, // Update method_id with the selected value
      }));
    };
  
    console.log('selected method',selectedPaymentMethod)
    return (
      <>
        <div className="px-4">
          <div className="flex flex-col items-center">
            <h2 className="text-blue-700 text-xl font-bold border-b-2 border-blue-200 pb-2 uppercase">
              {actionType ? `${actionType} Payment` : 'New Payment'}
            </h2>
          </div>
          <div>
            {isLoading ? (
              <div className="flex items-center flex-col">
                <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                <p className="mt-2">Processing data. Please wait...</p>
              </div>
            ) : (
              <>
                {/* meneu icon */}
                <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
              {payment.state!=='paid' &&( // show to office if order is in draft
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Cancel"
                    onClick={() => handleBtnClick('cancelled')}
                  >
                    <FcCancel size={20} color={currentColor} />
                  </button>
              )}
             
             {canApprove && (payment.state !== 'paid' && payment.state !== 'cancelled') && (


                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Approve"
                  onClick={() => handleBtnClick('approved')}
                >
                  <FcApproval size={20} color={currentColor} />
                </button>
                   )}

                {/* {pi.state ==='approved' && !invoiceMode &&(
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Generate Invoice"
                    onClick={handleI}
                  >
                    <LiaFileInvoiceDollarSolid size={20} color={currentColor} />
                  </button>
                )} */}
             
                {/* {isUserAllowed &&  pi.state!=='invoiced'&& !enableInvoiceInput && pi.state!=='cancelled'&&(
                        
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Edit"
                  onClick={() => handleInvoiceBtnClick()}
                >
                  <CiEdit size={20} color={currentColor} />
                </button>
              
                )}
                 */}

              

                {actionType==='edit' &&(
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Submit Payment"
                    onClick={() => handleBtnClick(payment.state)} // pass state value

                  >
                    <BsFillSendFill size={20} color="blue" />
                  </button>
                )}

                {actionType==='edit' && payment.state==='approved' || payment.state==='cancelled' && (
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Pay"
                    onClick={() => handleBtnClick()} // pass apiMethod and state value
                  >
                    <FaSackDollar size={20} color="blue" />
                  </button>
                )}


               
              </div>
              {/* end menu icons */}
                <div className="flex flex-col md:flex-row mb-4">
                  <div id="invoice-preamble" className="mb-4 border border-gray-100 py-2 bg-gray-50 rounded-3xl md:w-1/3">
                    <p className="text-gray-800 text-sm mb-2 font-semibold text-center uppercase">Supplier Info</p>
                    <div className="flex flex-col gap-2 px-4">
                      {[
                        { label: "Name", value: pay?.invoice.partners?.name || 'NA' },
                        { label: "Telephone", value: pay?.invoice.partners?.phone_number || 'NA' },
                        { label: "Contact Person", value: pay?.invoice.partners?.contact_person || 'NA' },
                      ].map((item, index) => (
                        <div key={index} className="flex-1 min-w-[150px] mb-2">
                          <p className="text-blue-500 text-xs">{item.label}</p>
                          <p className="text-slate-500 text-xs font-semibold uppercase">{item.value}</p>
                        </div>
                      ))}
                    </div>
                  </div>
  
                  <div id="invoice-info" className="md:w-2/3 border border-gray-100 py-2 bg-gray-50 rounded-3xl md:ml-4">
                    <p className="text-gray-800 text-sm font-semibold text-center uppercase">Invoice Info</p>
                    <div className="px-4 flex flex-col">
                      {[
                        { label: "Reference #.", value: pay?.invoice.reference || 'NA' },
                        { label: "Invoice #.", value: pay?.invoice?.invoice_no || 'NA' },
                        { label: "Purchase Order #.", value: pay?.invoice?.p_orders?.reference || 'NA' },
                        { label: "Date #.", value: pay?.invoice?.date ? formatMediumDate(pay.invoice.date) : 'N/A' },
                        { label: "Total Bill #", value: pay?.invoice?.totalUntaxedPrice || 'NA' },
                        { label: "Discount", value: pay?.invoice?.totalDiscount || 'NA' },
                        { label: "Tax", value: pay?.invoice?.totalTax || 'NA' },
                        { label: "Total", value: formatCurrency(pay?.invoice?.totalTaxedPrice) || 'NA' },
                        { 
                          label: "Goods Delivered ?", 
                          value: (
                            <span className={`${pay?.invoice?.p_orders?.delivered === 1 ? 'text-green-500' : 'text-red-500'} font-bold`}>
                              {pay?.invoice?.p_orders?.delivered === 1 ? 'Yes' : 'No'}
                            </span>
                          )
                        },
                      ].map((item, index) => (
                        <div key={index} className="flex justify-between mb-2">
                          <p className="text-blue-500 text-xs">{item.label}</p>
                          <p className="text-slate-500 text-xs font-semibold uppercase">{item.value}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
  
                {/* Payment Info */}
                <div id="payment-info" className="md:w-full border border-gray-100 py-2 bg-gray-50 rounded-3xl">
                  <p className="text-gray-800 text-sm font-semibold text-center uppercase">Payment Info</p>
  
                  <div className="flex flex-col">
                    {/* Date Input */}
                    <div className="mb-2 mx-2">
                      <p className="text-blue-500 text-xs">Date</p>
                      <input
                        name="date"
                        type="date"
                        value={payment.date}
                        onChange={handleChange}
                        className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                      />
                    </div>
  
                    {/* Payment Method Select */}
                    <div className="mb-2 mx-2">
                      <p className="text-blue-500 text-xs">Payment Method</p>
                      <CreatableSelect
                        options={paymentMethods}
                        name="paymentMethod"
                        onChange={handlePaymentMethodChange}
                        styles={{
                          placeholder: (provided) => ({
                            ...provided,
                            color: 'rgba(0, 0, 0, 0.5)',
                          }),
                        }}
                      />
                    </div>
  
                    {/* Account Number */}
                    {(selectedPaymentMethod !== 'cash') || (selectedPaymentMethod === 'mobile money') && (

                      <div className="mb-2 mx-2">
                        <p className="text-blue-500 text-xs">Account Number</p>
                        <input
                          name="account_no"
                          type="text"
                          value={payment.account_no}
                          onChange={handleChange}
                          className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                        />
                      </div>
                    )}
  
                    {/* Account Name */}
                    {(selectedPaymentMethod !== 'cash') || (selectedPaymentMethod === 'mobile money') && (
                      <div className="mb-2 mx-2">
                        <p className="text-blue-500 text-xs">Account Name</p>
                        <input
                          name="account_name"
                          type="text"
                          value={payment.account_name}
                          onChange={handleChange}
                          className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                        />
                      </div>
                    )}
  
                    {/* Bank Name */}
                    {(selectedPaymentMethod !== 'cash') || (selectedPaymentMethod === 'mobile money') && (
                      <div className="mb-2 mx-2">
                        <p className="text-blue-500 text-xs">Bank</p>
                        <input
                          name="bank_name"
                          type="text"
                          value={payment.bank_name}
                          onChange={handleChange}
                          className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                        />
                      </div>
                    )}
                    {/* Bank Branch */}
                    {selectedPaymentMethod && selectedPaymentMethod !== 'cash' && (
                      <div className="mb-2 mx-2">
                        <p className="text-blue-500 text-xs">Branch</p>
                        <input
                          name="bank_branch"
                          type="text"
                          value={payment.bank_branch}
                          onChange={handleChange}
                          className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                        />
                      </div>
                    )}
                    {/* Bank Branch */}
                    {selectedPaymentMethod ==='cheque' &&(
                      <div className="mb-2 mx-2">
                        <p className="text-blue-500 text-xs">Cheque #</p>
                        <input
                          name="cheque_no"
                          type="text"
                          value={payment.cheque_no}
                          onChange={handleChange}
                          className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                        />
                      </div>
                    )}
                    {/* Amount */}
                    <div className="mb-2 mx-2">
                      <p className="text-blue-500 text-xs">Amount</p>
                      <input
                        name="amount"
                        type="text"
                        value={payment.amount}
                        onChange={handleChange}
                        className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                      />
                    </div>
  
                    {/* Reference */}
                    <div className="mb-2 mx-2">
                      <p className="text-blue-500 text-xs">Reference</p>
                      <input
                        name="reference"
                        type="text"
                        value={payment.reference}
                        onChange={handleChange}
                        className="px-2 py-1 text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300 w-full"
                      />
                    </div>
  
                    {/* Submit Button */}
                    <div className="flex justify-end mt-4">
                      <button
                        className="bg-blue-500 text-white py-2 px-4 rounded-xl flex items-center"
                        onClick={() => handleBtnClick(payment.state)} // pass state value
                      >
                        <span className="mr-2">Submit</span>
                        <svg viewBox="0 0 16 16" className="bi bi-cart2" fill="currentColor" height="16" width="16">
                          <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                {/* End Payment Info */}
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </>
    );
    };

export default EditPayment;
