import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';
import { Layout, Header } from '../../../../components';

const Prices = () => {
    const { currentColor } = useContext(AppContext);
    const navigate = useNavigate();

    const [selectedOption, setSelectedOption] = useState(null); // No option selected by default
    const [hoveredOption, setHoveredOption] = useState(null);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setHoveredOption(null); // Clear hovered option on select
        switch (option) {
            case 'option1':
                navigate('/prices/adjust-all-products-prices');
                break;
            case 'option2':
                navigate('/prices/adjust-product-categories-prices');
                break;
            case 'option3':
                navigate('/prices/adjust-selected-products-prices');
                break;
            default:
                break;
        }
    };

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Layout>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-2 bg-white rounded-3xl">
                <ToastContainer position="top-center" autoClose={2000} />
                <div className="mt-8 justify-center text-center">
                    <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
                        <button
                            className="bg-transparent px-1 rounded mr-2 transition-all duration-300"
                            title="Close"
                            onClick={handleClose}
                        >
                            <MdOutlineCancel  size={24} color={currentColor}/>
                        </button>
                    </div>
                    <Header category="" title="Adjust Product Prices" />
                </div>
                <div>
                    <div id="search" className="flex items-center justify-center p-4 md:mx-1 my-12 px-4 md:bg-gray-50 rounded-3xl">
                        <div id="options" className="flex space-x-4 py-4">
                            {['option1', 'option2', 'option3'].map((option, index) => (
                                <button
                                    key={option}
                                    onClick={() => handleOptionSelect(option)}
                                    className={`relative border ${
                                        selectedOption === option || hoveredOption === option ? 'text-white' : 'text-gray-500'
                                    } px-4 py-2 rounded-md border-blue-100 transition-all duration-300`}
                                    style={{
                                        backgroundColor: selectedOption === option || hoveredOption === option ? currentColor : 'white',
                                    }}
                                    onMouseEnter={() => setHoveredOption(option)}
                                    onMouseLeave={() => setHoveredOption(null)}
                                >
                                    <h1 className='font-bold'>{['All Products', 'Product Categories', 'Selected Products'][index]}</h1>
                                    <div className="flex items-center space-x-2 mt-4">
                                        <TiTick />
                                        <p className="text-sm">{['All products will be affected', 'Selected categories will be affected', 'Selected products will be affected'][index]}</p>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <TiTick />
                                        <p className="text-sm">{['Standard factor will be applied', 'Standard factor will be applied', 'Varied factor for each product'][index]}</p>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Prices;
