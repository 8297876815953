const NewBank = ({ bankDetails, onChange, onSubmit }) => (
  <div className="w-full">
    <form className="rounded px-8 py-6 mb-4 bg-white shadow-md" onSubmit={onSubmit}>
    <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="bank_name"
                onChange={onChange}
                value={bankDetails.bank_name || ''}
            />
            <label htmlFor="gps_link" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Bank Name
            </label>
            </div>

            <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="bank_branch"
                onChange={onChange}
                value={bankDetails.bank_branch || ''}
            />
            <label htmlFor="gps_link" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Branch
            </label>
            </div>

            <div className="relative z-0 mb-8  group">
            <select
          id="account_type"
          name="account_type"
          value={bankDetails.account_type || ''}
          onChange={onChange}
          className="block py-2.5 px-0 w-full text-sm
          text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
          dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
          focus:ring-0 focus:border-blue-600 peer"
        >
          <option value="">Select Account Type</option>
          <option value="checking">Checking</option>
          <option value="savings">Savings</option>
        </select>
            
            <label htmlFor="gps_link" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Account Number
            </label>
            </div>

            <div className="relative z-0 mb-8  group">
            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                focus:ring-0 focus:border-blue-600 peer" 
                placeholder=" "  
                name="routing_number"
                onChange={onChange}
                value={bankDetails.routing_number || ''}
            />
            <label htmlFor="routing_number" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Routing Number
            </label>
            </div>
      

         
     
      <div className="text-center">
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  </div>
);
export default NewBank;

