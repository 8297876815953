import React from 'react';
import {Font, Page, Text, View, Document, StyleSheet, Image,Link  } from '@react-pdf/renderer';
import { formatNumber } from '../utils/utils'; 

// Register the Roboto font



const styles = StyleSheet.create({
  page: {
    margin: 0,
    padding: 0,
    color: '#333',
    fontFamily: 'Helvetica',
    backgroundColor: '#f4f4f4',
  },




  container: {
    width: '100%',
    margin: 0,
    padding: 10,
    boxShadow: 'none',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    backgroundColor: '#4CAF50',
    color: '#fff',
    padding: 10,
    borderRadius: 8,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerImage: {
    borderRadius: 8,
    width: 100,
    height: 100,
  },
  companyInfo: {
    marginLeft: 10,
    fontSize: 12,
    color: '#fff',
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
    color: '#fff',
  },
  boldText: {
    fontWeight: 'bold', // Style for bold text
  },
  boldAndUppercase: {
    fontWeight: 'bold', // Style for bold text
    textTransform: 'uppercase', // Uppercase text
    fontSize:10,
  },

  highlighted: {
    fontWeight: 'bold', // Bold text
    textTransform: 'uppercase', // Uppercase text
    color: '#4CAF50', // Text color
  },
  paymentSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  paymentInfo: {
    fontSize: 12,
    width: '50%', // Ensure it has width to align text right
  },
  paymentInfoTextRight: {
    textAlign: 'right',
  },
  customerInfo: {
    fontSize: 12,
    width: '50%', // Ensure this also takes up space for correct alignment
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    margin: 10,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 20,
    marginBottom: 20,
    fontSize: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  tableCell: {
    borderRightWidth: 1,
    borderRightColor: '#ddd',
    padding: 5,
    flex: 1,
  },
  lastCell: {
    borderRightWidth: 0,
  },
  totalCell: {
    textAlign: 'right',
  },
  tableHeader: {
    backgroundColor: '#4CAF50',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 5,
  },
  summaryRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
  },
  summaryLabelCell: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: '#ddd',
    fontWeight: 'bold', // Bold text
    textTransform: 'uppercase', // Uppercase text
    color: '#4CAF50', // Text color
  },
  summaryValueCell: {
    flex: 1,
    padding: 5,
  },

  footerSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  contactInfo: {
    fontSize: 10,
    width: '50%', // Ensure it has width to align text right
  },
  
  termsInfo: {
    fontSize: 10,
    width: '50%', // Ensure this also takes up space for correct alignment
  },


  
  noPrint: {
    display: 'none',
  },
});

const MyDocument = ({ quotation, companyProfile }) => {
  const productRows = quotation.products.map((product, index) => (
    <View style={styles.tableRow} key={index}>
      <Text style={styles.tableCell}>{index + 1}</Text>
      <Text style={styles.tableCell}>{product.name || 'N/A'}</Text>
      <Text style={styles.tableCell}>{formatNumber(product.price).toFixed(2)}</Text>
      <Text style={styles.tableCell}>{formatNumber(product.qty).toFixed(2)}</Text>
      <Text style={styles.tableCell}>{formatNumber(product.discount).toFixed(2)}</Text>
      <Text style={[styles.tableCell, styles.lastCell, styles.totalCell]}>{formatNumber(product.total).toFixed(2)}</Text>
    </View>
  ));

  const totalAmount = formatNumber(quotation.products.reduce((total, product) => total + product.total, 0)).toFixed(2);
  const taxAmount = (totalAmount * 0.1).toFixed(2);
  const grandTotal = (totalAmount * 1.1).toFixed(2);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image src={companyProfile.photoUrl} style={styles.headerImage} />
              <View style={styles.companyInfo}>
                <Text>{companyProfile.name}</Text> 
                <Text>{companyProfile.physical_address}</Text>
                <Text>{companyProfile.city}, {companyProfile.country}</Text>
                <Text>Email: {companyProfile.email}</Text>
                <Text>Telephone: {companyProfile.phone_number}</Text>
              </View>
            </View>

            <View style={styles.invoiceInfo}>
              <Text style={styles.h1}>INVOICE</Text>
              <Text style={styles.boldAndUppercase}>INVOICE NO: {quotation.invoiceNumber}</Text>
              <Text style={styles.boldAndUppercase}>Date: {quotation.date}</Text>
            </View>
          </View>

          <View style={styles.paymentSection}>
            <View style={styles.customerInfo}>
              <Text style={styles.boldAndUppercase}>Invoice To:</Text>
              <Text style={[styles.highlighted]}>{quotation.customer.name}</Text>
            
              <Text>{quotation.customer.physical_address}</Text>
              <Text>{quotation.customer.city}, {quotation.customer.countries?.name}</Text>
              <Text>{quotation.customer.phone_number}</Text>
            </View>
            <View style={styles.paymentInfo}>
              <Text style={[styles.highlighted, styles.paymentInfoTextRight]}>Payment Info:</Text>
              <Text style={styles.paymentInfoTextRight}>Bank: XYZ Bank</Text>
              <Text style={styles.paymentInfoTextRight}>Account Name: ABC Corp</Text>
              <Text style={styles.paymentInfoTextRight}>Account Number: 987654321</Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={styles.tableCell}>#</Text>
              <Text style={styles.tableCell}>Product Name</Text>
              <Text style={styles.tableCell}>Price</Text>
              <Text style={styles.tableCell}>Quantity</Text>
              <Text style={styles.tableCell}>Discount (%)</Text>
              <Text style={[styles.tableCell, styles.lastCell]}>Total</Text>
            </View>
            {productRows}
            
            {/* Summary Rows */}
            <View style={styles.summaryRow}>
              <Text style={styles.summaryLabelCell}>SUB TOTAL</Text>
              <Text style={[styles.summaryValueCell, styles.totalCell]}>MK{totalAmount}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.summaryLabelCell}>TAX (10%)</Text>
              <Text style={[styles.summaryValueCell, styles.totalCell]}>MK{taxAmount}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={styles.summaryLabelCell}>GRAND TOTAL</Text>
              <Text style={[styles.summaryValueCell, styles.totalCell]}>MK{grandTotal}</Text>
            </View>
          </View>

          <View style={styles.footerSection}>
            <View style={styles.termsInfo}>
              <Text style={styles.highlighted}>Terms and Conditions:</Text>
              <Text>Payment is due within 30 days of the invoice date</Text>
              <Text>Late payments will incur a 5% fee.</Text>
            </View>
            <View style={styles.contactInfo}>
              <Text>Thank you for your business!</Text>
              <Text>For any inquiries, contact us at{' '}
                <Link src={`mailto:${companyProfile?.email}`} style={styles.emailLink}>
                  {companyProfile?.email}
                </Link>
              </Text>
            </View>
          </View>


          
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
