import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { FcCancel, FcApproval } from "react-icons/fc";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { employeeTypeProperty } from "@syncfusion/ej2/documenteditor";
import { getChangedFields,formatCurrency,handleApi, inputDate,formatMediumDate,cleanObject} from '../../../../utils/utils'; 


const EditEmployee = ({emp,onClose }) => {
    const getDefaultDateOfBirth = () => {
        const today = new Date();
        const year = today.getFullYear() - 18; // 18 years ago
        return new Date(year, 0, 1).toISOString().split('T')[0]; // January 1st
    };

    const { userData,defaultImage,currentColor,actionCount,setActionCount } = useContext(AppContext);
    const [employee, setEmployee] = useState({
        date_of_birth: emp?.date_of_birth || getDefaultDateOfBirth(),
        id :emp?.id || '',
        first_name:emp?.first_name || '',
        last_name:emp?.last_name || '',
        gender:emp?.gender || '',
        phone_number:emp?.phone_number || '',
        email:emp?.email || '',
        job_title:emp?.job_title || '',
        department_id:emp?. department_id || '',
        hire_date:emp?. hire_date || '',
        
        
    });
    const [originalEmployee, setOriginalEmployee] = useState({ ...employee });// duplicate employee 
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [imgType, setImgType] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [photo_url, setPhoto_url] = useState(emp.photo_url || defaultImage,);




   
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {fetchDepartments();},[]);

 
    const fetchDepartments = async () => {
        const apiEndpoint = 'departments';
        const apiMethod = 'GET';
        const cleanedData = {};
        const parameters = {};
        setIsLoading(true);
        try {
          const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
          if (response.error) {
            toast.error(`Error fetching report: ${response.errorMessage}`);
            return;
          }
    
          const { status, statusText, data } = response;
         
          if (status === 200) {
            console.log('data sir',data)
            setDepartments(Array.isArray(data) ? data : []);
          } else {
            toast.error(`Error ${status}: ${statusText}`);
          }
        } catch (error) {
          toast.error(error.message || 'An unexpected error occurred.');
        } finally {
          setIsLoading(false);
        }
      };

    
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setEmployee(prevEmployee => {const updatedEmployee = { ...prevEmployee };
        if (name === "department") {
            setDepartment = value; 
            const selectedDepartment = departments.find((department) => department.name === value);
            if (selectedDepartment) {updatedEmployee.department_id= selectedDepartment.id;} 
        }else{updatedEmployee[name] = value } // Handle other fields
        return updatedEmployee;
        });
    };
    
    const handleImgChange = (event) => {
      const { name, files } = event.target;
      if (name === "img") {convertFile(files);}
    };

    const convertFile = (files) => {
        console.log('conviert images')
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
            const  photo= {data:btoa(ev.target.result),contentType:fileType};
            setDisplayImg(btoa(ev.target.result))
            setImgType(fileType)
            setEmployee(prevEmployee => ({...prevEmployee,photo:photo}));
        };
      }
    };

    

   

  

    const handleSubmit = async () => {
        const apiEndpoint = 'employees';
        const parameters = {id:employee.id};
        const apiMethod = 'PUT';
        const requiredFields = ['first_name','last_name', 'phone_number','phone_number','email','department_id'];  // Define required fields

        for (const field of requiredFields) {
            if (!employee[field]) {
                toast.error(`Please fill in the required field: ${field}`);
                return; // Exit the function if a required field is missing
            }
        }
 
        const cleanedEmployee=getChangedFields(originalEmployee,employee)
        console.log('cleaned employee',cleanedEmployee)
        debugger
        try {
          const response = await handleApi(apiEndpoint, cleanedEmployee, userData, apiMethod, parameters);
          if (response.status === 200) {
            toast.success('Employee updated successfully.');
            // onClose(); // Close the modal if the response is successful
          } else {
            toast.error('Error saving employee.', response.statusText);
          }
          return response; // Return the response for checking
        } catch (error) {
          toast.error('Error saving employee: ' + error.message);
          return null; // Return null in case of error
        } finally {
          setActionCount(actionCount + 1);
          setIsLoading(false);
        }
      };
      

        

  
 
    const photoUrl = (displayImg)? `data:${imgType};base64,${displayImg}`: photo_url;

    return (
        <div className="">
            {/* meneu icon */}
            <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
             
            
        
        

           

             <button
               className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
               title="Submit"
            
               onClick={(e) => {
                   e.preventDefault(); // Prevent default behavior here
                   handleSubmit(e); // Pass the event to your handler
               }}

             >
               <BsFillSendFill size={20} color="blue" />
             </button>


          


          
         </div>
         {/* end menu icons */}
         {/* Image Preview Section */}
         <div className="flex flex-col items-center justify-center mb-4 bg-gray-100 w-full ">
                <img
                    src={photoUrl}
                    alt="Profile Preview"
                    className="w-32 h-32 object-cover rounded-full  m-2 border-2 border-gray-300"
                />
                <h2 className="text-blue-700 mb-3 text-xl font-bold text-center pb-2 uppercase ">{emp.first_name} {emp.last_name}</h2>
            </div>




          {/* <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Edit Employee</h2>
            </div>  */}
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2"> 
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="first_name"
                                onChange={(event) => handleTextChange(event)}
                                value={emp?.first_name}
                            />
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First Name
                            </label>
                        </div>
              
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="last_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={emp?.last_name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="page"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Last Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="date_of_birth"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                key={emp?.id} // Assuming 'id' is a unique identifier
                                value={emp?.date_of_birth}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="date_of_birth"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                               Date of Birth
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="gender"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                key={emp?.id} // Assuming 'id' is a unique identifier
                                value={emp?.gender}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="gender"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Gender
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="phone_number"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={emp?.id} // Assuming 'id' is a unique identifier
                                value={emp?.phone_number}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="phone_number"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Telephone
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="email"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={emp?.id} // Assuming 'id' is a unique identifier
                                value={emp?.email}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="department"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="departmentList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={emp?.departments.name}
                            />
                           
                                <datalist id="departmentList">
                                    {departments && departments.map((department) => (
                                        <option key={department.id} value={department.name} />
                                    ))}
                                </datalist>
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="department" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Department
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="job_title"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={emp?.id} // Assuming 'id' is a unique identifier
                                value={emp?.job_title}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="job_title" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Job Title
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="hire_date"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={inputDate(emp?.hire_date)}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="hire_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Hired Date
                            </label>
                        </div>

                        <div className="relative z-0 mb-3 w-full group flex items-center">
                            <input
                                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent bgray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                                type="file"
                                accept="image/*"
                                name='img'
                                onChange={(event) => handleImgChange(event)}
                            />
            
                            <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Profile picture</label>
                          
                           
                          
                        </div>

                    <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEmployee;
