// Modal.jsx

import React, { useContext } from "react";
import { MdOutlineCancel } from 'react-icons/md';
import AppContext from '../contexts/AppContext';

const Modal = ({ open, onClose, children, resetPos, minWidth,maxWidth, showCloseButton = true}) => {
  const { currentColor } = useContext(AppContext);
  const handleCloseModal = () => {
    onClose();
    if (resetPos) { resetPos(); }
  };

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center transition-opacity ${
        open ? "visible bg-black/20" : "invisible"
      }`}
      style={{
        display: open ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 99999,
      }}
    >
      <div
        className="bg-white rounded-xl shadow p-6"
        style={{
          //minWidth: minWidth || "10%", // Set a default minWidth or use the provided minWidth prop
          //maxWidth: maxWidth || "10%", // Set a default maxWidth or use the provided minWidth prop
          overflow: "auto",
          position: "relative",
        }}
      >
      {showCloseButton !== false && (
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
        >
          <MdOutlineCancel color={currentColor} size={30} />
        </button>
      )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
