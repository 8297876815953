import React from "react";
import { createRoot } from "react-dom/client";
import { registerLicense } from '@syncfusion/ej2-base';
import "./index.css";
import App from "./App";
import { ContextProvider } from './contexts/ContextProvider';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5feXZXRmVYU0Z+Wks=')
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  
  </React.StrictMode>
);

