//UserProfile.jsx

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { MdOutlineCancel } from 'react-icons/md';
import { Button } from './';

// import avatar from '../data/avatar.jpg';
import AppContext from '../contexts/AppContext';


const UserProfile = () => {
  const navigate = useNavigate();  // Initialize useNavigate hook
  //const [isOpen, setIsOpen] = useState(false); // State to control whether the component is rendered
  const { setIsLoggedIn, isLoggedIn ,setIsClicked, initialState, currentColor, currentMode,userData,} = useContext(AppContext);
  
  const handleLogout = () => {
    console.log('here sir in handlelogout')
    setIsLoggedIn(false);
    setIsClicked(initialState)
    navigate('/');
  };

  
  // if (!isOpen && component !== 'userprofile') {
  //   return null; // Don't render the UserProfile component if isOpen is false
  // }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 shadow-lg">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          padding="8px"
          borderRadius="50%"
          // onClick={handleClose} // Call handleClose when the cancel button is clicked
      
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
      
          <img
            className="rounded-full h-24 w-24"
            src={userData?.data?.employees?.photo_url}
            alt="user-profile"
          />
       
        <div>
       
            <p className="font-semibold text-xl dark:text-gray-200">{`${userData?.data?.employees?.first_name} ${userData?.data?.employees?.last_name}`}</p>
         
          {/* {userData?.data?.employee?.job_title ? (
            <p className="text-gray-500 text-sm dark:text-gray-400">{userData.data.employee.job_title}</p>
          ) : (
            <p className="text-gray-500 text-sm dark:text-gray-400"></p>
          )} */}
            <p className="text-gray-500 text-sm dark:text-gray-400">{userData?.data?.employees?.job_title}</p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{userData?.data?.employees?.email}</p>

  
          {/* {userData?.data?.email ? (
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{userData.data.email}</p>
          ) : (
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">Dummy Email</p>
          )} */}
        </div>
      </div>

      <div className="flex  mt-4 ">
        <button className="hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-lg"
          style={{ backgroundColor: currentColor }}
          onClick={handleLogout}
        >
          Logout 
        </button>
      </div>
  
      
       
    
    </div>
  );
  
};

export default UserProfile;
